import React, {FC, useEffect, useState} from 'react'
import {Routes, Route, Navigate, BrowserRouter} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../features/errors/ErrorsPage'
import Login from '../features/auth/Login'
import {useAuth} from 'react-oidc-context'
import {App} from '../App'
import CallbackPage from '../features/auth/CallbackPage'
import FilePreview from '../features/shared/pages/FilePreview'
import FinancierRoutes from './FinancierRoutes'
import SuperUserRoutes from './SuperUserRoutes'

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const auth = useAuth()
  const roles: string[] = auth?.user?.profile?.role as string[]

  // State to keep track of the super user's selected view
  const [selectedView, setSelectedView] = useState('investment-analyst')

  useEffect(() => {
    const savedView = localStorage.getItem('superUserSelectedView')
    if (savedView) {
      setSelectedView(savedView)
    }
  }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        {/* Pass the header as a prop to your App layout */}
        <Route element={<App />}>
          {auth.isAuthenticated ? (
            <>
              <Route path="/file-preview/:id" element={<FilePreview />} />
              <Route
                path="/*"
                element={
                  roles.includes('Super User') ? (
                    // For super users, render based on the selected view
                    selectedView === 'financier' ? (
                      <SuperUserRoutes />
                    ) : selectedView === 'investment-analyst' ? (
                      // Replace with your actual InvestmentAnalystRoutes if available
                      <PrivateRoutes />
                    ) : (
                      // 'all' view or default view for super users
                      <PrivateRoutes />
                    )
                  ) : roles.includes('Financier') ||
                    roles.includes('Grant Provider') ? (
                    <FinancierRoutes />
                  ) : (
                    <PrivateRoutes />
                  )
                }
              />
            </>
          ) : (
            <>
              <Route path="/auth" element={<Login />} />
              <Route path="/auth/callback" element={<CallbackPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}