import {createSelector, createEntityAdapter, EntityState, Draft} from '@reduxjs/toolkit'
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError, FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import {RootState} from '../../../data/redux/Store'
import {AUTH_TOKEN_KEY, env} from '../../../data/constants'
import {
  IFinancierSector,
  IFinancierContactPerson,
  IFinancierDocumentMetaData,
  IFinancierDocumentFolders,
  IFinancierCountries,
  IFinancierType,
  IFinancierCall,
  IApplicationStage,
} from './interfaces'
import {IPaginationMetadata, ISearchEntities} from '../shared/interfaces'
import {IDevelopmentPartnerDetails} from './interfaces/IDevelopmentPartnerDetails'

const financierCallsAdapter = createEntityAdapter<IFinancierCall>({
  selectId: (call) => call.id,
})
const applicationStagesAdapter = createEntityAdapter<IApplicationStage>({
  selectId: (application) => application.name,
})
const DevelopmentPartnersAdapter = createEntityAdapter<IDevelopmentPartnerDetails>({
  selectId: (developmentPartner) => developmentPartner.id,
})
const FinancierSectorsAdapter = createEntityAdapter<IFinancierSector>({
  selectId: (financingSector) => financingSector.id,
})
const FinancierTypesAdapter = createEntityAdapter<IFinancierType>({
  selectId: (financingType) => financingType.id,
})
const financierCountriesAdapter = createEntityAdapter<IFinancierCountries>({
  selectId: (financierCountry) => financierCountry.id,
})

const FinancierContactPersonsAdapter = createEntityAdapter<IFinancierContactPerson>({
  selectId: (financierContactPerson) => financierContactPerson.id,
})
const financierDocumentsAdapter = createEntityAdapter<IFinancierDocumentMetaData>({
  selectId: (financierDocuments) => financierDocuments.id,
})
const financierDocumentFoldersAdapter = createEntityAdapter<IFinancierDocumentFolders>({
  selectId: (financierDocumentFolders) => financierDocumentFolders.id,
})

// State getters

const developmentPartnersInitialState = DevelopmentPartnersAdapter.getInitialState()


const baseQuery = fetchBaseQuery({
  baseUrl: env.DevelopmentPartners,
  validateStatus: (response, result) => {
    if (result && result.isError) return false
    if (response.status >= 200 && response.status <= 300) return true
    if (response.status >= 400 && response.status <= 500) return false
    return false
  },
  prepareHeaders: (headers, {getState}) => {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY)
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
})

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error) {
    const status = result.error.status

    throw new Error(`${status}`)
  }

  return result
}

export const DevelopmentPartnersApiSlice = createApi({
  reducerPath: 'developmentPartnersApi',
  baseQuery: customBaseQuery,

  tagTypes: [
    'Financiers',
    'DevelopmentPartner',
    'DevelopmentPartnerDetails',
  ],
  endpoints: (builder) => ({
    getDevelopmentPartners: builder.query<
      {entities: EntityState<IDevelopmentPartnerDetails>; paginationMetadata: IPaginationMetadata},
      ISearchEntities
    >({
      query: (searchParams) => {
        const params = new URLSearchParams()
        if (searchParams.page) params.append('page', searchParams.page.toString())
        if (searchParams.pageSize) params.append('pageSize', searchParams.pageSize.toString())
        if (searchParams.searchTerm) params.append('searchTerm', searchParams.searchTerm)
        if (searchParams.countries) {
          searchParams.countries.forEach((country) => params.append('Countries', country))
        }
        return {
          url: '/development/partners',
          params: params,
        }
      },

      transformResponse: (response: any, meta: FetchBaseQueryMeta) => {
        console.log('response', response)
        const entities = DevelopmentPartnersAdapter.setAll(developmentPartnersInitialState, response.data)
        const paginationMetadata = response.paginationMetadata
        const pagination: IPaginationMetadata = paginationMetadata
          ? {
            totalCount: paginationMetadata.totalItems || 0,
            page: paginationMetadata.currentPage || 1,
            pageSize: paginationMetadata.pageSize || 9,
            hasNextPage: paginationMetadata.hasNextPage || false,
            hasPreviousPage: paginationMetadata.hasPreviousPage || false,
          }
          : {
            totalCount: 0,
            page: 1,
            pageSize: 9,
            hasNextPage: false,
            hasPreviousPage: false,
          }
        return {
          entities: entities,
          paginationMetadata: pagination,
        }
      },
      providesTags: (result: {entities: EntityState<IDevelopmentPartnerDetails>} | undefined) => {
        if (result?.entities.ids) {
          return [
            {type: 'DevelopmentPartner', id: 'LIST'},
            ...result.entities.ids.map((id) => ({type: 'DevelopmentPartner' as const, id})),
          ]
        } else {
          return [{type: 'DevelopmentPartner', id: 'LIST'}]
        }
      },
    }),
    getDevelopmentPartnerDetails: builder.query<EntityState<IDevelopmentPartnerDetails>, string>({
      query: (id) => `/development/partners/${id}`,
      // transformResponse: (res: any) => {
      //   return DevelopmentPartnersAdapter.setOne(developmentPartnersInitialState, res)
      // },
      providesTags: (result: EntityState<IDevelopmentPartnerDetails> | undefined) => {
        if (result?.ids) {
          return [...result.ids.map((id) => ({type: 'Financiers' as const, id}))]
        } else {
          return [{type: 'Financiers', id: 'LIST'}]
        }
      },
    }),

    addNewDevelopmentPartner: builder.mutation({
      query: (initialFinancierData) => ({
        url: '/development/partners',
        method: 'POST',
        body: initialFinancierData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'DevelopmentPartner', id: 'LIST'}],
    }),
    updateDevelopmentPartner: builder.mutation({
      query: (updatedPartnerData) => ({
        url: `/development/partners`,
        method: 'PUT',
        body: updatedPartnerData,
      }),
      invalidatesTags: (_result, _error, arg) => [{
        type: 'DevelopmentPartner',
        id: arg.id,
      }, {type: 'DevelopmentPartnerDetails', id: arg.id}],
    }),
    deleteDevelopmentPartner: builder.mutation({
      query: (id) => ({
        url: `/development/partners/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'DevelopmentPartner', id: arg.id}],
    }),
  }),
})

export const {
  useGetDevelopmentPartnersQuery,
  useAddNewDevelopmentPartnerMutation,
  useUpdateDevelopmentPartnerMutation,
  useGetDevelopmentPartnerDetailsQuery,
  useDeleteDevelopmentPartnerMutation,
} = DevelopmentPartnersApiSlice

//Financiers selectors

export const selectDevelopmentPartnersResult = (state: RootState, searchParams: ISearchEntities) =>
  DevelopmentPartnersApiSlice.endpoints.getDevelopmentPartners.select(searchParams)(state)

export const selectDevelopmentPartnersData = (searchParams: ISearchEntities) =>
  createSelector(
    (state: RootState) => selectDevelopmentPartnersResult(state, searchParams),
    (developmentPartnersResult) => developmentPartnersResult?.data?.entities ?? developmentPartnersInitialState,
  )

export const createDevelopmentPartnersSelectors = (searchParams: ISearchEntities) => {
  const selectData = selectDevelopmentPartnersData(searchParams)
  return DevelopmentPartnersAdapter.getSelectors((state: RootState) => selectData(state))
}

