import React, {useState} from 'react'
import SettingDetailsHeader from '../shared/SettingDetailsHeader'
import SettingDetailsBody from './SettingDetailsBody'
import {
  useAddNewSectorMutation,
  useDeleteSectorMutation,
  useGetSectorsQuery,
  useUpdateSectorMutation,
} from '../../companies/CompaniesApiSlice'
import Loading from '../../shared/components/Loading'
import {INameIdPair} from '../../shared/interfaces'
import {AddSettingItem} from '../Modals/AddSettingItem'
import {UpdateSettingItem} from '../Modals/UpdateSettingItem'
import {DeleteModal} from '../../shared/components/DeleteModal'

const SectorsSettings = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedSector, setSelectedSector] = useState<INameIdPair | null>(null)
  const {data: sectorsState, isLoading} = useGetSectorsQuery()
  const [addSector, {isLoading: isCreatingSector}] = useAddNewSectorMutation()
  const [updateSector, {isLoading: isUpdatingSector}] = useUpdateSectorMutation()
  const [deleteSector, {isLoading: isDeletingSector}] = useDeleteSectorMutation()
  const companySectors = Object.values(sectorsState?.entities || {}).filter(
    (sector): sector is INameIdPair => Boolean(sector)
  )

  const filteredCompanySectors = companySectors.filter((sector) =>
    sector.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const handleCreateModal = () => {
    setShowCreateModal(!showCreateModal)
  }
  const handleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal)
  }
  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const handleSave = async (name: string) => {
    try {
      await addSector({
        name,
      }).unwrap()
      handleCreateModal()
    } catch (error) {}
  }
  const handleUpdate = async (name: string) => {
    try {
      await updateSector({
        name,
        id: selectedSector?.id,
      }).unwrap()
      setSelectedSector(null)
      handleUpdateModal()
    } catch (error) {}
  }
  const handleDelete = async () => {
    try {
      if (selectedSector) {
        await deleteSector(selectedSector.id).unwrap()
      }
      setSelectedSector(null)
      handleDeleteModal()
    } catch (error) {}
  }
  return (
    <div className='card card-flush card-bordered card-60vh p-5'>
      <div className='mb-5'>
        <SettingDetailsHeader
          setSearchTerm={setSearchTerm}
          handleCreateModal={handleCreateModal}
          title={'Sectors'}
          useModal={true}
        />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <SettingDetailsBody
          items={filteredCompanySectors}
          setSelectedItem={setSelectedSector}
          handleUpdateModal={handleUpdateModal}
          handleDeleteModal={handleDeleteModal}
        />
      )}
      <AddSettingItem
        showCreateModal={showCreateModal}
        handleCreateModal={handleCreateModal}
        handleSave={handleSave}
        isLoading={isCreatingSector}
        section={'Sector'}
      />
      <UpdateSettingItem
        showUpdateModal={showUpdateModal}
        handleUpdateModal={handleUpdateModal}
        handleUpdate={handleUpdate}
        isLoading={isUpdatingSector}
        section={'Sector'}
        item={selectedSector}
      />
      <DeleteModal
        item={'Sector'}
        showDeleteModal={showDeleteModal}
        handleClose={handleDeleteModal}
        isLoading={isDeletingSector}
        handleDelete={handleDelete}
      />
    </div>
  )
}

export default SectorsSettings
