import { Field, FormikValues } from 'formik'
import { useGetFinancingTypesQuery, useGetSectorsQuery } from '../../companies/CompaniesApiSlice'
import { currencies } from '../../shared/constants'
import NumberFormatter from '../../shared/components/NumberFormatter'
import { MultiSelect } from '../../shared/components/MultiSelect'
import React from 'react'
import { INameIdPair } from '../../shared/interfaces'
import { formatNumber } from '../../../../utils/numberHelpers'

export const CreateFinancierFinancials = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  setFieldTouched,
}: FormikValues) => {
  const { data: financingTypesState } = useGetFinancingTypesQuery()

  const { data: sectorsState } = useGetSectorsQuery()

  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    Boolean,
  ) as INameIdPair[]


  const financingTypeSelection = Object.values(financingTypesState?.entities || {})


  const financierType = [
    { id: 'finance', name: 'Financier' },
    { id: 'grant', name: 'Grant Provider' },
  ]

  const sectors = [
    { id: 'sector1', name: 'Agriculture' },
    { id: 'sector2', name: 'Technology' },
    { id: 'sector3', name: 'Healthcare' },
    // Add more sectors as needed
  ]

  const investmentHorizons = [
    { id: 'short', name: 'Short Term' },
    { id: 'medium', name: 'Medium Term' },
    { id: 'long', name: 'Long Term' },
  ]

  return (
    <div data-kt-stepper-element="content">
      <div className="w-100">
        {/* Target Sectors */}
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Target Sectors</span>
            </label>
            <MultiSelect
              options={companySectorsList}
              selectedValues={values.financials?.sectors}
              setOption={(selectedOptions) =>
                setFieldValue('financials.sectors', selectedOptions)
              }
              size="lg"
            />
            {touched?.financials?.sectors && (
              <div className="text-danger mt-2">{errors?.financials?.sectors}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Currency</span>
            </label>
            <Field
              as="select"
              name="financials.currency"
              className="form-select py-lg-5"
            >
              <option value="" disabled>
                Select a currency
              </option>
              <option value="USD">USD - United States Dollar</option>
              <option value="UGX">UGX - Ugandan Shilling</option>
              <option value="EUR">EUR - Euro</option>
            </Field>
            {touched?.financials?.currency && errors?.financials?.currency && (
              <div className="text-danger mt-2">{errors.financials.currency}</div>
            )}
          </div>
        </div>

        {/* Select Financing Types */}
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Select Financing Types</span>
            </label>
            {financingTypeSelection.map((option) => (
              <label className="d-flex flex-stack cursor-pointer mb-5" key={option?.id}>
                <span className="d-flex align-items-center me-2">
                  <span className="form-check form-check-custom me-3">
                    <Field
                      className="form-check-input h-30px w-30px custom-checkbox"
                      type="checkbox"
                      name="financials.financingTypes"
                      value={option?.name}
                    />
                  </span>
                  <span className="d-flex flex-column">
                    <span className="fw-bolder f-s fs-6">{option?.name}</span>
                  </span>
                </span>
              </label>
            ))}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Investment Horizon</span>
            </label>
            {investmentHorizons.map((horizon) => (
              <label className="d-flex flex-stack cursor-pointer mb-5" key={horizon.id}>
                <span className="d-flex align-items-center me-2">
                  <span className="form-check form-check-custom me-3">
                    <Field
                      className="form-check-input h-30px w-30px custom-checkbox"
                      type="checkbox"
                      name="financials.investmentHorizon"
                      value={horizon.name}
                    />
                  </span>
                  <span className="d-flex flex-column">
                    <span className="fw-bolder f-s fs-6">{horizon.name}</span>
                  </span>
                </span>
              </label>
            ))}
            {touched?.financials?.investmentHorizon && (
              <div className="text-danger mt-2">{errors?.financials?.investmentHorizon}</div>
            )}
          </div>
        </div>

        {/* Ticket Size */}
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Minimum Ticket Size</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="financials.minimumTicketSize"
              value={formatNumber(values.financials?.minimumTicketSize || '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const rawValue = event.target.value.replace(/,/g, '') // Remove commas
                setFieldValue('financials.minimumTicketSize', rawValue)
              }}
            />
            {touched?.financials?.minimumTicketSize && (
              <div className="text-danger mt-2">{errors?.financials?.minimumTicketSize}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Maximum Ticket Size</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="financials.maximumTicketSize"
              value={formatNumber(values.financials?.maximumTicketSize || '')}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const rawValue = event.target.value.replace(/,/g, '') // Remove commas
                setFieldValue('financials.maximumTicketSize', rawValue)
              }}
            />
            {touched?.financials?.maximumTicketSize && (
              <div className="text-danger mt-2">{errors?.financials?.maximumTicketSize}</div>
            )}
          </div>
        </div>

        {/* Currency */}
        {/* <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2">
              <span>Currency</span>
            </label>
            <Field
              as="select"
              className="form-control form-control-lg py-lg-5"
              name="financials.currency"
            >
              <option value="" label="Select currency" />
              {currencies.map((currency) => (
                <option key={currency.code} value={currency.code}>
                  {currency.name}
                </option>
              ))}
            </Field>
            {touched?.financials?.currency && (
              <div className="text-danger mt-2">{errors?.financials?.currency}</div>
            )}
          </div>
        </div> */}

        {/* Investment Horizon */}
        <div className="fv-row row mb-4 p-0">
        </div>
      </div>
    </div>
  )
}
