import {Field} from 'formik'
import React from 'react'

export const CreatePartnerProgramDetails = ({touched, errors, setFieldValue, setCountryCode, values}: any) => {

  return (
    <div data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Total Budget`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="programDetails.fundingWindow.totalBudget"
              placeholder=""
            />

            {touched?.programDetails?.fundingWindow?.totalBudget && (
              <div className="text-danger mt-2">{errors?.programDetails?.fundingWindow?.totalBudget}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Timeline for Disbursement`}</span>
            </label>
            <Field
              type="date"
              className="form-control form-control-lg py-lg-5"
              name="programDetails.fundingWindow.timelineForDisbursement"
              placeholder=""
            />

            {touched?.programDetails?.fundingWindow?.timelineForDisbursement && (
              <div className="text-danger mt-2">{errors?.programDetails?.fundingWindow?.timelineForDisbursement}</div>
            )}
          </div>
        </div>
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Expected KPIs`}</span>
            </label>
            <Field
              as="textarea"
              className="form-control form-control-lg py-lg-5"
              name="programDetails.expectedKPIsAndDeliverables"
              placeholder=""
            />

            {touched?.programDetails?.expectedKPIsAndDeliverables && (
              <div className="text-danger mt-2">{errors?.programDetails?.expectedKPIsAndDeliverables}</div>
            )}
          </div>
          <div className="col-lg-6">
            <div className="py-lg-5 mt-3">
              <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span
                className="required">{`Are you open to co-funding or partnerships with investors or other organizations?`}</span>
              </label>
              <div className="d-flex">
                <label className="me-4">
                  <Field
                    type="radio"
                    name="programDetails.isOpenToPartnership"
                    value="yes"
                    className="form-check-input me-2"
                    onChange={() => setFieldValue('programDetails.isOpenToPartnership', true)} // Convert to boolean true
                    checked={values.programDetails?.isOpenToPartnership === true}
                  />
                  Yes
                </label>

                <label>
                  <Field
                    type="radio"
                    name="programDetails.isOpenToPartnership"
                    value="no"
                    className="form-check-input me-2"
                    onChange={() => setFieldValue('programDetails.isOpenToPartnership', false)} // Convert to boolean false
                    checked={values.programDetails?.isOpenToPartnership === false}
                  />
                  No
                </label>
              </div>
              {touched?.programDetails?.isOpenToPartnership && (
                <div className="text-danger mt-2">{errors?.programDetails?.isOpenToPartnership}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
