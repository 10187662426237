import React, {useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_theme/helpers'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {
  createDevelopmentPartnersSelectors,
  useGetDevelopmentPartnersQuery,
  useUpdateDevelopmentPartnerMutation,
} from '../DevelopmentPartnersApiSlice'
import LineSpan from '../../shared/components/LineSpan'

type Props = {
  showModal: boolean
  handleClose: () => void
  currentPage: number
  pageSize: number
}

export const EditDevelopmentPartnerProgramDetails: React.FC<Props> = ({
                                                                        handleClose,
                                                                        showModal,
                                                                        currentPage,
                                                                        pageSize,
                                                                      }) => {
  const validationSchema = Yup.object().shape({
    totalBudget: Yup.string().required('Total Budget is required'),
    disbursementTimeline: Yup.string().required('Disbursement Timeline is required'),
    kpis: Yup.string().nullable(),
  })

  const [updateDevelopmentPartner, {isLoading}] = useUpdateDevelopmentPartnerMutation()
  const {id} = useParams<{id: string}>() as {id: string}
  useGetDevelopmentPartnersQuery({page: currentPage, pageSize: pageSize})
  const {selectById: selectDevelopmentPartnerById} = createDevelopmentPartnersSelectors({page: currentPage, pageSize})
  const partnerDetails = useSelector((state: RootState) => selectDevelopmentPartnerById(state, id))

  const initialValues = useMemo(() => ({
    totalBudget: partnerDetails?.totalBudget || '',
    disbursementTimeline: partnerDetails?.disbursementTimeline || '',
    kpis: partnerDetails?.goals?.map(goal => goal.target).join(', ') || '',
  }), [partnerDetails])

  const handleSave = async (values: any) => {
    const updatedPartnerData = {
      ...partnerDetails,
      id: partnerDetails?.id,
      totalBudget: values.totalBudget,
      disbursementTimeline: values.disbursementTimeline,
      goals: values.kpis.split(',').map((target: any) => ({target})),
    }
    try {
      await updateDevelopmentPartner(updatedPartnerData).unwrap()
      handleClose()
    } catch (error) {
      handleClose()
    }
  }

  return (
    <Modal
      id="editPartnerProgramModal"
      aria-hidden={true}
      dialogClassName="h-auto"
      tabIndex={-1}
      show={showModal}
      onHide={handleClose}
    >
      <div className="modal-content w-700px" role="document">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
          enableReinitialize={true}
        >
          {({errors, touched, isValid, values, setFieldValue}) => (
            <Form>
              <div className="modal-header">
                <h5 className="modal-title ps-1" id="editPartnerProgramModalLabel">
                  Edit Development Partner's Program Details
                </h5>
                <div
                  className="btn btn-sm btn-icon btn-active-color-danger"
                  data-bs-dismiss="modal"
                  onClick={handleClose}
                >
                  <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                </div>
              </div>
              <div className="modal-body px-9">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
                      Total Budget
                    </label>
                    <Field
                      type="text"
                      name="totalBudget"
                      className="form-control"
                      placeholder="Enter total budget"
                    />
                    {touched.totalBudget && errors.totalBudget && (
                      <div className="text-danger mt-2">{errors.totalBudget}</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
                      Timeline for Disbursement
                    </label>
                    <Field
                      type="text"
                      name="disbursementTimeline"
                      className="form-control"
                      placeholder="Enter disbursement timeline"
                    />
                    {touched.disbursementTimeline && errors.disbursementTimeline && (
                      <div className="text-danger mt-2">{errors.disbursementTimeline}</div>
                    )}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
                      Expected KPIs
                    </label>
                    <Field
                      type="text"
                      name="kpis"
                      className="form-control"
                      placeholder="Enter expected KPIs"
                    />
                    {touched.kpis && errors.kpis && (
                      <div className="text-danger mt-2">{errors.kpis}</div>
                    )}
                    {values.kpis.length === 0 && <LineSpan />}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary text-dark"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  disabled={!isValid || isLoading}
                >
                  <span className="indicator-label">
                    {!isLoading ? 'Save' : (
                      <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                    )}
                  </span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
