import {FC} from 'react'
import {INameIdPair} from '../../shared/interfaces'

type props = {
  items: INameIdPair[]
  setSelectedItem: (item: INameIdPair | null) => void
  handleUpdateModal: () => void
  handleDeleteModal: () => void
}

const SettingDetailsBody: FC<props> = ({
  items,
  setSelectedItem,
  handleUpdateModal,
  handleDeleteModal,
}) => {
  const handleEditButtonClick = (item: INameIdPair) => {
    setSelectedItem(item)
    handleUpdateModal()
  }
  const handleDeleteButtonClick = (item: INameIdPair) => {
    setSelectedItem(item)
    handleDeleteModal()
  }

  return (
    <div className='scrollable-container'>
      {items?.map((item, index) => (
        <div
          key={item.id}
          className={`d-flex align-items-center justify-content-between border-bottom py-4 ${
            index === 0 ? 'border-top' : ''
          }`}
        >
          <span className='fw-bold'>{item.name}</span>
          <div>
            <i
              className='fas fa-pen text-dark cursor-pointer'
              onClick={() => {
                handleEditButtonClick(item)
              }}
            ></i>
            <i
              className='fas fa-trash-alt ms-5 text-danger cursor-pointer'
              onClick={() => {
                handleDeleteButtonClick(item)
              }}
            ></i>
          </div>
        </div>
      )) || (
        <div className='d-flex justify-content-right align-items-right'>
          <em>There are no Items listed yet.</em>
        </div>
      )}
    </div>
  )
}

export default SettingDetailsBody
