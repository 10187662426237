import {useParams, useNavigate} from 'react-router-dom'
import DetailsHeaderNavigationBar from '../../shared/components/DetailsHeaderNavigationBar'
import React, {FC, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import EditButton from '../../shared/components/EditButton'
import {EditFinancierLogo} from '../modals'
import {getInitials} from '../../../../utils/stringHelpers'
import {useIsGlobalAdmin,useIsSuperUser} from '../../../../_theme/utils/authUtil'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import {DeleteModal} from '../../shared/components/DeleteModal'
import {
  createDevelopmentPartnersSelectors, useDeleteDevelopmentPartnerMutation,
  useGetDevelopmentPartnersQuery,
} from '../DevelopmentPartnersApiSlice'
import CardInitials from '../../shared/components/CardInitials'

interface HeaderProps {
  selectedTab: string
  setSelectedTab: (tab: string) => void
  currentPage: number
  pageSize: number
}

const DevelopmentPartnerHeader: FC<HeaderProps> = ({selectedTab, setSelectedTab, currentPage, pageSize}) => {
  const [showModal, setShowModal] = useState(false)

  const navigationTabs = [
    {name: 'General Information'},
    {name: 'Strategic Focus'},
    {name: 'Program Details'},
    {name: 'Partnerships'},
  ]

  const {id} = useParams<{id: string}>() as {id: string}
  const navigate = useNavigate()
  const [deleteDevelopmentPartner, {isLoading}] = useDeleteDevelopmentPartnerMutation()
  useGetDevelopmentPartnersQuery({page: currentPage, pageSize: pageSize})
  const {selectById: selectFinancierById} = createDevelopmentPartnersSelectors({
    page: currentPage,
    pageSize,
  })
  const developmentPartnerDetails = useSelector((state: RootState) => selectFinancierById(state, id))

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const financierInitials = getInitials(developmentPartnerDetails?.name, 3)
  const handleClose = () => {
    setShowModal(!showModal)
  }

  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const handleDelete = async () => {
    await deleteDevelopmentPartner(id).unwrap()
    navigate('/development/partners')
  }


  const isGlobalAdmin = useIsGlobalAdmin()
  const isSuperUser = useIsSuperUser()

  return (
    <div className="card card-flush shadow-sm">
      <div className="card-body py-6 d-flex flex-column gap-5 pb-0 px-6">
        <div className="d-flex justify-content-between pb-3">
          <div className="d-flex gap-5 align-items-start">
            <div className="position-relative">
              <CardInitials
                initials={financierInitials}
                width={financierInitials.length >= 4 ? 150 : 120}
                height={120}
                fontSize={48}
              />
              <EditButton handleClose={handleClose} customClass="logo-edit-button" />
            </div>

            <div className="pt-5 d-flex flex-column gap-2">
              {/*<span>{financierCode}</span>*/}
              <span className="fw-bolder text-dark fs-5">{developmentPartnerDetails?.name}</span>
              {/*<span className="fw-light text-dark fs-5">{financierDetails?.slogan}</span>*/}
              <div className="d-flex gap-2 flex-wrap">
                {/*{financingTypes.map((type: string, index: number) => (*/}
                {/*  <CardBadge key={index} text={type} />*/}
                {/*))}*/}
              </div>
            </div>
          </div>

          <div className="d-flex gap-0 justify-content-center align-items-start">
            {(isGlobalAdmin || isSuperUser) && (
              <OptionsPopup
                handleDelete={handleDelete}
                handleDeleteModal={handleDeleteModal}
                isHeaderToggle={true}
                includeDeleteOption={true}
              />
            )}
          </div>
        </div>
        <div className="d-flex gap-5">
          {navigationTabs.map((tab, index) => (
            <DetailsHeaderNavigationBar
              key={index}
              tab={tab}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          ))}
        </div>
      </div>
      <EditFinancierLogo
        showModal={showModal}
        handleClose={handleClose}
        currentPage={currentPage}
        pageSize={pageSize}
      />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        isLoading={isLoading}
        handleDelete={handleDelete}
        item={'Development Partner'}
        handleClose={handleDeleteModal}
      />
    </div>
  )
}

export default DevelopmentPartnerHeader
