import {useEffect, useRef, useState} from 'react'
import {INameIdPair} from '../interfaces'

type MultiSelectProps = {
  selectedValues: INameIdPair[]
  options: INameIdPair[]
  isCommaSeparated?: boolean
  setOption: (option: INameIdPair[]) => void
  size?: 'lg'
}

export function MultiSelect({
                              selectedValues,
                              options,
                              isCommaSeparated = false,
                              setOption,
                              size,
                            }: MultiSelectProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const optionsRef = useRef<HTMLUListElement>(null)
  const [isItemSelected, setIsItemSelected] = useState(false)

  function selectOption(option: INameIdPair) {
    if (isOptionSelected(option)) {
      setOption(selectedValues.filter((o) => o.id !== option.id))
      setIsItemSelected(false)
    } else {
      setOption([...selectedValues, option])
      setIsItemSelected(true)
    }
  }

  function isOptionSelected(option: INameIdPair) {
    return selectedValues.some((selectedOption) => selectedOption.id === option.id)
  }

  useEffect(() => {
    if (isOpen) setHighlightedIndex(0)
  }, [isOpen])

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.target !== containerRef.current) return
      switch (e.code) {
        case 'Enter':
        case 'Space':
          setIsOpen((prev) => !prev)
          if (isOpen) selectOption(options[highlightedIndex])
          break
        case 'ArrowUp':
        case 'ArrowDown': {
          if (!isOpen) {
            setIsOpen(true)
            break
          }

          const newValue = highlightedIndex + (e.code === 'ArrowDown' ? 1 : -1)
          if (newValue >= 0 && newValue < options.length) {
            setHighlightedIndex(newValue)
          }
          break
        }
        case 'Escape':
          setIsOpen(false)
          break
      }
    }
    containerRef.current?.addEventListener('keydown', handler)

    return () => {
      containerRef.current?.removeEventListener('keydown', handler)
    }
  }, [isOpen, highlightedIndex, options])

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (optionsRef.current && optionsRef.current.contains(e.relatedTarget as Node)) {
      return
    }
    setIsOpen(false)
  }

  return (
    <div
      ref={containerRef}
      onClick={() => setIsOpen((prev) => !prev)}
      tabIndex={0}
      onBlur={handleBlur}
      className={`w-100 multi-container ${size === 'lg' ? 'multi-container-lg' : ''}`}
    >
      <span className="multi-value">
        {isCommaSeparated ? (
          <span className="px-2">{selectedValues.map((v) => v.name).join(', ')}</span>
        ) : (
          selectedValues.map((v) => (
            <button
              key={v.id}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault() // Prevent form submission
                selectOption(v)
              }}
              className="multi-option-badge"
            >
              {v.name}
              <div
                className="remove-btn"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault() // Prevent form submission
                  selectOption(v)
                }}
              >
                &times;
              </div>
            </button>
          ))
        )}
      </span>
      <div className={`multi-caret fs-1 mx-2 ${isItemSelected ? 'point-up' : ''}`}></div>
      <ul ref={optionsRef} className={`multi-options ${isOpen ? 'show' : ''}`}>
        {options.map((option, index) => (
          <li
            onClick={(e) => {
              e.stopPropagation()
              selectOption(option)
            }}
            onMouseEnter={() => setHighlightedIndex(index)}
            key={option.id}
            className={`multi-option ${isOptionSelected(option) ? 'selected' : ''} ${
              index === highlightedIndex ? 'highlighted' : ''
            }`}
          >
            {option.name}
          </li>
        ))}
      </ul>
    </div>
  )
}