import {FormattedMessage} from 'react-intl'

type props = {
  showFilters: boolean
  handleFilterButtonClick: () => void
  hasWhiteBackground?: boolean
  isPipeline?: boolean
}
const FilterButton = ({
                        showFilters,
                        handleFilterButtonClick,
                        hasWhiteBackground = false,
                        isPipeline = false,
                      }: props) => {
  return (
    <button
      className={`d-flex align-items-center justify-content-center gap-2 button-transparent text-bg-transparent w-100px h-40px ${
        showFilters ? 'text-danger text-bg-secondary' : ''
      } ${
        hasWhiteBackground ? 'bg-white' : ''
      }`}
      onClick={handleFilterButtonClick}
    >
      <i className="bi bi-filter fs-2 fw-bolder text-danger" aria-hidden="true"></i>
      {isPipeline ? (<span><FormattedMessage id={'BUTTONS.FILTERS'} defaultMessage={'Filters'} /></span>) : (
        <span>Filters</span>)}
    </button>
  )
}

export default FilterButton