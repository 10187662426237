import {FC, useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {getPaginationState} from '../../../../utils/getPaginationState'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import Loading from '../../shared/components/Loading'
import {FetchBaseQueryError} from '@reduxjs/toolkit/query'
import {createDevelopmentPartnersSelectors, useGetDevelopmentPartnerDetailsQuery} from '../DevelopmentPartnersApiSlice'
import DevelopmentPartnerGeneralInformation from '../components/DevelopmentPartnerGeneralInformation'
import DevelopmentPartnerProgramDetails from '../components/DevelopmentPartnerProgramDetails'
import DevelopmentPartnerStrategicFocus from '../components/DevelopmentPartnerStrategicFocus'
import DevelopmentPartnerEngagement from '../components/DevelopmentPartnerEngagement'
import DevelopmentPartnerHeader from '../components/DevelopmentPartnerHeader'

export const DevelopmentPartnerDetails = () => {
  const {id} = useParams<{id: string}>() as {id: string}
  const navigate = useNavigate()
  const location = useLocation()
  const {currentPage, pageSize} = getPaginationState(location)
  const [selectedTab, setSelectedTab] = useState('General Information')

  const {selectById: selectFinancierById} = createDevelopmentPartnersSelectors({
    page: currentPage,
    pageSize,
  })
  const financier = useSelector((state: RootState) => selectFinancierById(state, id || ''))
  const {
    data: financierState,
    isLoading: financierDetailsLoading,
    error,
  } = useGetDevelopmentPartnerDetailsQuery(id)

  const [showModal, setShowModal] = useState(false)

  const handleClose = () => {
    setShowModal(!showModal)
  }

  useEffect(() => {
    if (error) {
      const errorCode = (error as FetchBaseQueryError)?.status?.toString()
      navigate(`/error/${errorCode}`)
    }
  }, [error, navigate])

  if (financierDetailsLoading) {
    return <Loading />
  }

  return (
    <>
      <Toolbar page="View Development Partner Details" link="/development/partners" buttonTitle="Back"></Toolbar>

      <div className="row">
        <div className="col-lg-12">
          <DevelopmentPartnerHeader
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        </div>
        <div className="col-lg-12">
          <div className="card card-flush shadow-sm mt-5">
            <div className="card-body p-0">
              {selectedTab === 'General Information' && (
                <DevelopmentPartnerGeneralInformation
                  handleClose={handleClose}
                  showModal={showModal}
                  currentPage={currentPage}
                  pageSize={pageSize}
                />
              )}

              {selectedTab === 'Strategic Focus' && (
                <DevelopmentPartnerStrategicFocus
                  handleClose={handleClose}
                  showModal={showModal}
                  currentPage={currentPage}
                  pageSize={pageSize}
                />
              )}

              {selectedTab === 'Program Details' && (
                <DevelopmentPartnerProgramDetails
                  handleClose={handleClose}
                  showModal={showModal}
                  currentPage={currentPage}
                  pageSize={pageSize}
                />
              )}

              {selectedTab === 'Partnerships' && (<DevelopmentPartnerEngagement
                currentPage={currentPage}
                pageSize={pageSize}
              />)}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
