import React, { FC, useEffect, useRef, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_theme/helpers/components/KTSVG'
import 'flatpickr/dist/flatpickr.min.css'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../../data/redux/Store'
import { useSelector } from 'react-redux'
import { countries } from '../../../../utils/countries'
import {
  createFinancierSelectors,
  useGetFinancierCountriesQuery,
  useGetFinanciersQuery,
  useGetFinancierSectorsQuery,
  useUpdateFinancierMutation,
  useAddNewFinancierSectorsMutation,
  useDeleteFinancierSectorsMutation,
} from '../FinanciersApiSlice'
import { ratings } from '../constants/Ratings'
import { MultiSelect } from '../../shared/components/MultiSelect'
import { INameIdPair } from '../../shared/interfaces'
import { useGetSectorsQuery } from '../../companies/CompaniesApiSlice'

interface Props {
  showModal: boolean
  handleClose: () => void
  currentPage: number
  pageSize: number
}

export const UpdateFinancierGeneralInformation: FC<Props> = ({
  showModal,
  handleClose,
  currentPage,
  pageSize,
}) => {
  const FinancierGeneralInformationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').label('Name'),
    about: Yup.string().nullable().label('About'),
    financierCategory: Yup.string().nullable().label('Financier Category'),
    country: Yup.array().min(1, 'At least one country is required').label('Country'),
    region: Yup.string().nullable().label('Region'),
    address: Yup.string().nullable().label('Address'),
    officeAddress: Yup.string().nullable().label('Physical Address'),
    mailAddress: Yup.string().nullable().label('Mail Address'),
    sectors: Yup.array().nullable().label('Sectors'),
    esgPrinciples: Yup.string().nullable().label('Aligned with ESG Principles'),
    openToCoInvestment: Yup.string().nullable().label('Open to Co-Investment'),
    requireAdditionalSupport: Yup.string().nullable().label('Require Additional Support'),
    modeOfCollaboration: Yup.string().nullable().label('Mode of Collaboration'),
    investorType: Yup.array().nullable().label('Financier Type')
  })

  const [updateFinancier, { isLoading }] = useUpdateFinancierMutation()
  const [deleteFinancierSectors] = useDeleteFinancierSectorsMutation()
  const [addFinancierSectors] = useAddNewFinancierSectorsMutation()
  const { id } = useParams<{ id: string }>() as { id: string }
  useGetFinanciersQuery({ page: currentPage, pageSize: pageSize })
  const { data: financierCountriesState } = useGetFinancierCountriesQuery()
  const financierCountries = Object.values(
    financierCountriesState?.entities?.entities ?? {},
  ).filter(Boolean) as INameIdPair[]

  const { selectById: selectFinancierById } = createFinancierSelectors({
    page: currentPage,
    pageSize,
  })
  const financierDetails = useSelector((state: RootState) => selectFinancierById(state, id))

  const { data: sectorsState } = useGetSectorsQuery()

  const sectorsList = Object.values(sectorsState?.entities || {}).filter(Boolean) as INameIdPair[]
  const { data: financierSectorsState } = useGetFinancierSectorsQuery(id)

  const financierSectorsIdsList = (financierSectorsState?.ids || []) as string[]

  const [selectedFinancierCountries, setSelectedFinancierCountries] = useState<INameIdPair[]>([])
  const [selectedFinancierSectors, setSelectedFinancierSectors] = useState<INameIdPair[]>([])

  const initialLoad = useRef(true)

  const financierTypes = [
    { id: 'angel', name: 'Angel Investor' },
    { id: 'venture', name: 'Venture Capital' },
    { id: 'private', name: 'Private Equity' },
    { id: 'development', name: 'Other' }
  ]

  const mappedCountries = countries.map((country) => ({
    id: country.value,
    name: country.label
  }))

  useEffect(() => {
    if (showModal) {
      initialLoad.current = true
    }
  }, [showModal])

  useEffect(() => {
    if (financierDetails && initialLoad.current) {
      const defaultSelectedFinancierCountries = financierCountries.filter((country) =>
        financierDetails?.countries && financierDetails.countries.includes(country.id),
      )
      const defaultSelectedFinancierSectors = sectorsList.filter((sector) =>
        financierSectorsIdsList.includes(sector.id),
      )
      setSelectedFinancierCountries(defaultSelectedFinancierCountries)
      setSelectedFinancierSectors(defaultSelectedFinancierSectors)
      initialLoad.current = false
    }
  }, [financierDetails, financierCountries, sectorsList])

  const initialValues = {
    name: financierDetails?.name ?? '',
    about: financierDetails?.about ?? '',
    financierCategory: financierDetails?.classificationRating ?? '',
    country: (financierDetails?.country ?? []).map((typeId) =>
      mappedCountries.find((item) => item.name === typeId),
    ).filter((item): item is INameIdPair => !!item),
    sectors: (financierDetails?.sectors ?? []).map((typeId) =>
      sectorsList.find((item) => item.name === typeId),
    ).filter((item): item is INameIdPair => !!item),
    region: financierDetails?.region ?? '',
    address: financierDetails?.address ?? '',
    esgPrinciples: financierDetails?.esgPrinciples,
    openToCoInvestment: financierDetails?.coInvestment,
    requireAdditionalSupport: financierDetails?.additionalSupport,
    modeOfCollaboration: financierDetails?.modeCollaboration,
    impactGoals: financierDetails?.impactGoals,
    investorType: (financierDetails?.financierType ?? []).map((typeId) =>
      financierTypes.find((item) => item.name === typeId),
    ).filter((item): item is INameIdPair => !!item),
  }

  const handleSave = async (values: any) => {
    const updatedFinancierData = {
      id: financierDetails?.id,
      name: values.name,
      about: values.about,
      classification_rating: values.financierCategory,
      country: values.country.map((typeName: any) => typeName.name),
      region: values.region,
      address: values.address,
      currency: values.financials.currency,
      sectors: values.financials.sectors.map((sector: any) => sector.name),
      maximum_ticket_size: values.maximumTicketSize,
      minimum_ticket_size: values.minimumTicketSize,
      physical_address: values.officeAddress,
      mail_address: values.mailingAddress,
      investment_horizon: values.investmentHorizon,
      impact_goals: values.impactGoals,
      esg_principles: values.esgPrinciples,
      additional_support: values.additionalSupport,
      mode_collaboration: values.modeCollaboration,
      co_investment: values.coInvestments,
      financier_type: values?.financierType.map((typeName: any) => typeName.name)
    }

    // Extract IDs from selectedFinancierSectors
    const selectedSectorIds: string[] = selectedFinancierSectors.map(sector => sector.id)

    // Identify sectors to delete
    const sectorsToDelete: string[] = financierSectorsIdsList.filter(id => !selectedSectorIds.includes(id)) as string[]

    // Identify sectors to add
    const sectorsToAdd = selectedSectorIds.filter(id => !financierSectorsIdsList.includes(id))
    const financierSectorObjects = sectorsToAdd.map((sectorId) => ({
      financier_id: id,
      financing_sector_id: sectorId,
    }))

    try {
      await Promise.all([
        deleteFinancierSectors(sectorsToDelete).unwrap(),
        addFinancierSectors(financierSectorObjects).unwrap(),
        updateFinancier(updatedFinancierData).unwrap(),
      ])
      setSelectedFinancierCountries([])
      setSelectedFinancierSectors([])
      handleClose()
    } catch (error) {
      setSelectedFinancierCountries([])
      setSelectedFinancierSectors([])
      handleClose()
    }
  }

  const handleCloseModal = () => {
    setSelectedFinancierCountries([])
    setSelectedFinancierSectors([])
    handleClose()
  }

  return (
    <>
      <Modal
        id="kt_edit_business_operation"
        aria-hidden={true}
        dialogClassName="h-auto"
        show={showModal}
        onHide={handleCloseModal}
      >
        <div className="modal-content w-700px" role="document">
          <Formik
            initialValues={initialValues || {}}
            validationSchema={FinancierGeneralInformationSchema}
            onSubmit={handleSave}
          >
            {({ values, errors, touched, isValid, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title ps-1">Edit Financier General Information</h5>
                    <div
                      className="btn btn-sm btn-icon btn-active-color-danger"
                      data-bs-dismiss="modal"
                      onClick={handleCloseModal}
                    >
                      <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                    </div>
                  </div>
                  <div className="modal-body px-9">
                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="name"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark required"
                        >
                          Name
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className="form-control form-control-sm"
                          style={{ color: '#616162', fontWeight: 300 }}
                        />
                        {touched.name && <div className="text-danger mt-2">{errors.name}</div>}
                      </div>
                    </div>
                    <div className="row fv mb-4">
                      <div className="col-md-12 mb-3">
                        <label
                          htmlFor="about"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          About
                        </label>
                        <Field
                          as="textarea"
                          name="about"
                          className="form-control form-control-sm text-secondary-20"
                          style={{ color: '#616162', fontWeight: 300 }}
                        />
                        {touched.about && <div className="text-danger mt-2">{errors.about}</div>}
                      </div>
                    </div>

                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="country"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Country
                        </label>
                        <MultiSelect
                          selectedValues={values.country ?? []}
                          options={mappedCountries}
                          setOption={(selected) => setFieldValue('country', selected)}
                        />
                        {touched.country && (
                          <div className="text-danger mt-2">{errors.country}</div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="region"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Region
                        </label>
                        <Field
                          type="text"
                          name="region"
                          className="form-control form-control-sm"
                          style={{ color: '#616162', fontWeight: 300 }}
                        />
                      </div>
                    </div>
                    <div className="row fv mb-4">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="address"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Address
                        </label>
                        <Field
                          type="text"
                          name="address"
                          className="form-control form-control-sm"
                          style={{ color: '#616162', fontWeight: 300 }}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="sectorsOfInterest"
                          className="d-flex align-items-center fs-5 fw-bold mb-2"
                        >
                          Sectors of Interest
                        </label>
                        <MultiSelect
                          selectedValues={values.sectors ?? []}
                          options={sectorsList}
                          setOption={(selected) => setFieldValue('sectors', selected)}
                        />
                      </div>
                    </div>
                    <div className="row fv mb-4">

                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="financierCategory"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Investor Type
                        </label>
                        <MultiSelect
                          selectedValues={values.investorType ?? []}
                          options={financierTypes}
                          setOption={(selected) => setFieldValue('investorType', selected)}
                        />
                        {touched.investorType && (
                          <div className="text-danger mt-2">{errors.investorType}</div>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="financierCategory"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Mode of collaboration
                        </label>
                        <Field
                          type="text"
                          name="modeOfCollaboration"
                          className="form-control form-control-sm"
                        />

                      </div>
                    </div>
                    <div className="row fv mb-4">

                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="financierCategory"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Aligned with ESG Principles
                        </label>
                        <div>
                          <Field
                            type="radio"
                            name="esgPrinciples"
                            value="yes"
                            id="esgYes"
                            className="form-check-input me-2"
                          />
                          <label htmlFor="esgYes" className="me-3">
                            Yes
                          </label>
                          <Field
                            type="radio"
                            name="esgPrinciples"
                            value="no"
                            id="esgNo"
                            className="form-check-input me-2"
                          />
                          <label htmlFor="esgNo">No</label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="financierCategory"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Mode of collaboration
                        </label>
                        <Field
                          type="text"
                          name="modeOfCollaboration"
                          className="form-control form-control-sm"
                        />

                      </div>
                    </div>
                    
                    <div className="row fv mb-4">

                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="financierCategory"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Are you open to co-investment
                        </label>
                        <div>
                          <Field
                            type="radio"
                            name="co_investment"
                            value="yes"
                            id="esgYes"
                            className="form-check-input me-2"
                          />
                          <label htmlFor="esgYes" className="me-3">
                            Yes
                          </label>
                          <Field
                            type="radio"
                            name="co_investment"
                            value="no"
                            id="esgNo"
                            className="form-check-input me-2"
                          />
                          <label htmlFor="esgNo">No</label>
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="financierCategory"
                          className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                        >
                          Do you require additional support?
                        </label>
                        <div>
                          <Field
                            type="radio"
                            name="requireAdditionalSupport"
                            value="yes"
                            id="esgYes"
                            className="form-check-input me-2"
                          />
                          <label htmlFor="esgYes" className="me-3">
                            Yes
                          </label>
                          <Field
                            type="radio"
                            name="requireAdditionalSupport"
                            value="no"
                            id="esgNo"
                            className="form-check-input me-2"
                          />
                          <label htmlFor="esgNo">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary text-dark"
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => {
                        handleSave(values)
                      }}
                      disabled={!isValid || isLoading}
                    >
                      <span className="indicator-label">
                        {!isLoading && 'Save'}
                        {isLoading && (
                          <>
                            <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                          </>
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}
