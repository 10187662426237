import {FC} from 'react'
import {useParams} from 'react-router-dom'
import EditButton from '../../shared/components/EditButton'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import LineSpan from '../../shared/components/LineSpan'
import {
  createDevelopmentPartnersSelectors,
  useGetDevelopmentPartnersQuery,
} from '../DevelopmentPartnersApiSlice'
import {UpdateDevelopmentPartnerGeneralInformation} from '../modals/UpdateDevelopmentPartnerGeneralInformation'

type DevelopmentPartnerGeneralInformationProps = {
  handleClose: () => void;
  showModal: boolean;
  currentPage: number;
  pageSize: number;
};

const DevelopmentPartnerGeneralInformation: FC<DevelopmentPartnerGeneralInformationProps> = ({
                                                                                               handleClose,
                                                                                               showModal,
                                                                                               currentPage,
                                                                                               pageSize,
                                                                                             }) => {
  const {id} = useParams<{id: string}>() as {id: string}
  useGetDevelopmentPartnersQuery({page: currentPage, pageSize})

  const {selectById: selectFinancierById} = createDevelopmentPartnersSelectors({
    page: currentPage,
    pageSize,
  })

  const developmentPartnerDetails = useSelector((state: RootState) => selectFinancierById(state, id))

  const organizationTypeMap = {
    1: 'Government',
    2: 'NGO',
    3: 'Foundation',
    4: 'Multilateral Organization',
    5: 'Other',
  }

  const organizationTypeString = developmentPartnerDetails?.organizationType !== undefined
    ? organizationTypeMap[developmentPartnerDetails.organizationType as keyof typeof organizationTypeMap]
    : 'Unknown'

  return (
    <div className="position-relative">
      <div className="card card-flush mb-4">
        <div className="card-body px-6 p-0">
          <div className="row">
            <div className="col-md-6 pt-5">
              <h3 className="fw-bolder text-dark fs-8 m-0">Organisation Name</h3>
              <p className="fs-7 text-justify">{developmentPartnerDetails?.name || <LineSpan />}</p>
            </div>
            <div className="col-md-6 pt-5">
              <h3 className="fw-bolder text-dark fs-8 m-0">Primary Contact Person Name</h3>
              <p className="fs-7 text-justify">{developmentPartnerDetails?.contactPersonName || <LineSpan />}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Primary Contact Person Title</h3>
              <p className="fs-7 text-justify">{developmentPartnerDetails?.contactPersonTitle || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Primary Contact Person Email</h3>
              <p className="fs-7 text-justify">{developmentPartnerDetails?.contactPersonEmail || <LineSpan />}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Primary Contact Person Phone</h3>
              <p className="fs-7 text-justify">{developmentPartnerDetails?.primaryPhoneNumber || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Physical Address</h3>
              <p className="fs-7 text-justify">{developmentPartnerDetails?.location || <LineSpan />}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Mailing Address</h3>
              <p className="fs-7 text-justify">{developmentPartnerDetails?.email || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Type of Organization</h3>
              <p className="fs-7 text-justify">{organizationTypeString || <LineSpan />}</p>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <UpdateDevelopmentPartnerGeneralInformation
        showModal={showModal}
        handleClose={handleClose}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  )
}

export default DevelopmentPartnerGeneralInformation
