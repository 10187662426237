import {FC} from 'react'
import {useParams} from 'react-router-dom'
import EditButton from '../../shared/components/EditButton'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {createDevelopmentPartnersSelectors, useGetDevelopmentPartnersQuery} from '../DevelopmentPartnersApiSlice'
import LineSpan from '../../shared/components/LineSpan'
import {EditDevelopmentPartnerProgramDetails} from '../modals/EditDevelopmentPartnerProgramDetails'

type DevelopmentPartnerProgramDetailsProps = {
  handleClose: () => void
  showModal: boolean
  currentPage: number
  pageSize: number
}

const DevelopmentPartnerProgramDetails: FC<DevelopmentPartnerProgramDetailsProps> = ({
                                                                                       handleClose,
                                                                                       showModal,
                                                                                       currentPage,
                                                                                       pageSize,
                                                                                     }) => {
  const {id} = useParams<{id: string}>() as {id: string}
  useGetDevelopmentPartnersQuery({page: currentPage, pageSize: pageSize})
  const {selectById: selectFinancierById} = createDevelopmentPartnersSelectors({
    page: currentPage,
    pageSize,
  })
  const developmentPartnerDetails = useSelector((state: RootState) => selectFinancierById(state, id))

  return (
    <div className="position-relative">
      <div className="card card-flush mb-4">
        <div className="card-body py-5 px-6">
          <div className="row pb-2">
            <h3 className="fw-bolder text-dark fs-8 m-0">Funding Window Details</h3>
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Total Budget</h3>
              <p className="fs-7 text-justify">
                {developmentPartnerDetails?.totalBudget}
              </p>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Timeline for Disbursement</h3>
              <p className="fs-7 text-justify">
                {developmentPartnerDetails?.disbursementTimeline}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Expected KPIs</h3>
              <p className="fs-7 text-justify">
                {
                  developmentPartnerDetails?.goals && developmentPartnerDetails?.goals.length > 0
                    ? developmentPartnerDetails?.goals.map(goal => goal.target).join(', ')
                    : <LineSpan />
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <EditDevelopmentPartnerProgramDetails
        showModal={showModal}
        handleClose={handleClose}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  )
}

export default DevelopmentPartnerProgramDetails
