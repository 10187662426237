import React, {ReactNode} from 'react'
import Highcharts, {chart} from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {
  ChartCommonOptions,
} from './ChartCommonOptions'
import {useIntl} from 'react-intl'
import {PieChartOptions} from './PieChartOptions'
import {object} from 'yup'

interface ChartContainerProps {
  children: ReactNode;
}

interface DataItem {
  name: string;
  value: number;
}

interface YearOfIncorporation {
  age: number;
  value: number;
}

interface ApplicantsPerWeek {
  [key: string]: number; // Key-value structure for weeks
}

interface OwnersAgeRange {
  [key: string]: number; // Key-value structure for weeks
}

interface ChartsProps {
  sectorData: DataItem[];
  applicationsPerWeek: ApplicantsPerWeek;
  yearsOfIncorporation: YearOfIncorporation[];
  ownerAgeRange: OwnersAgeRange;
}

const ChartContainer: React.FC<ChartContainerProps> = ({children}) => (
  <div className="border-solid p-0 m-3">
    {children}
  </div>
)

const Charts: React.FC<ChartsProps> = ({sectorData, applicationsPerWeek, yearsOfIncorporation, ownerAgeRange}) => {
  const sectorCategories = sectorData.map((item) => item.name)
  const sectorValues = sectorData.map((item) => item.value)
  const weekLabels = Object.keys(applicationsPerWeek)
  const weekValues = Object.values(applicationsPerWeek)
  const renamedWeekLabels = weekLabels.map((_, index) => `WK ${index + 1}`)
  const predefinedColors = [
    '#FF0000', '#E60000', '#CC0000', '#B30000', '#990000', '#800000', '#660000', '#4D0000', '#330000', '#1A0000',
    '#FF3333', '#FF6666', '#FF9999', '#FFCCCC', '#E69999', '#CC6666', '#B34D4D', '#994040', '#802626', '#661A1A',
    '#FF1A1A', '#E61A1A', '#CC1A1A', '#B31A1A',
  ]
  const ageLabels = Object.keys(ownerAgeRange)
  const ageValues = Object.values(ownerAgeRange)
  const intl = useIntl()


  const SectorChartOptions = () => {
    const colors = predefinedColors.slice(0, sectorCategories.length)
    return ChartCommonOptions(
      intl.formatMessage({
        id: 'CHARTS.SECTOR.DISTRIBUTION.TITLE',
        defaultMessage: 'Distribution of Applicants by Sector',
      }),
      sectorCategories,
      sectorValues,
      colors,
    )
  }

  const ApplicantsPerWeekChartOptions = () => {
    const constantColor = predefinedColors[0]
    return ChartCommonOptions(
      intl.formatMessage({
        id: 'CHARTS.APPLICANTS.PER.WEEK.TITLE',
        defaultMessage: 'Number of Applicants per Week',
      }),
      renamedWeekLabels,
      weekValues,
      [constantColor],
      'line',
    )
  }

  const YearsChartOptions = () => {
    const yearCategories = [intl.formatMessage({
      id: 'CHARTS.YEARS.CATEGORY.BELOW1',
      defaultMessage: 'Below 1 year',
    }), intl.formatMessage({
      id: 'CHARTS.YEARS.CATEGORY.TO5',
      defaultMessage: '2-5 years',
    }), intl.formatMessage({
      id: 'CHARTS.YEARS.CATEGORY.TO10',
      defaultMessage: '5-10 years',
    }), intl.formatMessage({
      id: 'CHARTS.YEARS.CATEGORY.ABOVE10',
      defaultMessage: 'Above 10 years',
    })]
    const yearDistribution: {[key: string]: number} = {
      'Below 1 year': 0,
      '2-5 years': 0,
      '5-10 years': 0,
      'Above 10 years': 0,
    }
    yearsOfIncorporation.forEach((item) => {
      if (item.age < 1) {
        yearDistribution['Below 1 year'] += item.value
      } else if (item.age >= 1 && item.age <= 5) {
        yearDistribution['2-5 years'] += item.value
      } else if (item.age >= 6 && item.age <= 10) {
        yearDistribution['5-10 years'] += item.value
      } else {
        yearDistribution['Above 10 years'] += item.value
      }
    })
    const yearValues = yearCategories.map((category) => yearDistribution[category])
    const colors = yearCategories.slice(0, renamedWeekLabels.length)
    return ChartCommonOptions(
      intl.formatMessage({
        id: 'CHARTS.YEARS.OF.OPERATION.TITLE',
        defaultMessage: 'Distribution of Applicants by Years of Operation',
      }),
      yearCategories,
      yearValues,
      colors,
    )
  }

  const OwnerAgeRangeOptions = () => {
    const yearCategories = [intl.formatMessage({
      id: 'CHARTS.AGE.CATEGORY.UNDER18',
      defaultMessage: 'Below 18 years',
    }), intl.formatMessage({
      id: 'CHARTS.AGE.CATEGORY.18TO25',
      defaultMessage: '18-25 years',
    }), intl.formatMessage({
      id: 'CHARTS.AGE.CATEGORY.25TO34',
      defaultMessage: '25-34 years',
    }), intl.formatMessage({
      id: 'CHARTS.AGE.CATEGORY.ABOVE35',
      defaultMessage: 'Above 35 years',
    })]
    const yearDistribution: {[key: string]: number} = {
      'Below 18 years': 0,
      '18-25 years': 0,
      '25-34 years': 0,
      'Above 35 years': 0,
    }
    ageLabels.forEach((item) => {
      if (item == 'Under 18') {
        yearDistribution['Below 18 years'] = ownerAgeRange[item]
      } else if (item == '18 - 24') {
        yearDistribution['18-25 years'] = ownerAgeRange[item]
      } else if (item == '25 - 34') {
        yearDistribution['25-34 years'] = ownerAgeRange[item]
      } else {
        yearDistribution['Above 35 years'] += ownerAgeRange[item]
      }
    })
    const yearValues = yearCategories.map((category) => yearDistribution[category])
    const colors = yearCategories.slice(0, ageLabels.length)
    return ChartCommonOptions(
      intl.formatMessage({
        id: 'CHARTS.OWNER.AGE.DISTRIBUTION.TITLE',
        defaultMessage: 'Distribution of Applicants by Owners Age',
      }),
      yearCategories,
      yearValues,
      colors,
    )
  }

  // const InvestmentChartOptions = () => {
  //   const predefinedCategories = ['Working capital', 'Capital Expenditure', 'Both']
  //   const investmentMap: {[key: string]: number} = {
  //     'Working capital': 0,
  //     'Capital Expenditure': 0,
  //     'Both': 0,
  //   }
  //   const predefinedColors = ['#FF5733', '#33FF57', '#3357FF']
  //   investmentData.forEach((item) => {
  //     if (investmentMap.hasOwnProperty(item.name)) {
  //       investmentMap[item.name] = item.value
  //     }
  //   })
  //   const pieChartData = predefinedCategories.map((category, index) => ({
  //     name: category,
  //     y: investmentMap[category],
  //     color: predefinedColors[index],
  //   }))
  //   return PieChartOptions(
  //     'Distribution of Applicants by Investment Ask',
  //     pieChartData,
  //   )
  // }


  return (
    <div className="charts-grid ps-0">
      <ChartContainer>
        <HighchartsReact highcharts={Highcharts} options={ApplicantsPerWeekChartOptions()} />
      </ChartContainer>
      <ChartContainer>
        <HighchartsReact highcharts={Highcharts} options={SectorChartOptions()} />
      </ChartContainer>
      <ChartContainer>
        <HighchartsReact highcharts={Highcharts} options={YearsChartOptions()} />
      </ChartContainer>
      <ChartContainer>
        <HighchartsReact highcharts={Highcharts} options={OwnerAgeRangeOptions()} />
      </ChartContainer>
    </div>
  )
}

export default Charts
