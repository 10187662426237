import React, {useState, useEffect} from 'react'
import {useDeleteCompanyMutation, useGetCompanyDetailsQuery} from '../CompaniesApiSlice'
import {useNavigate, useParams} from 'react-router-dom'
import CardBadge from '../../shared/components/CardBadge'
import DetailsHeaderNavigationBar from '../../shared/components/DetailsHeaderNavigationBar'
import {useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'
import CardInitials from '../../shared/components/CardInitials'
import CustomSkeleton from '../../shared/components/CustomSkeleton'
import Button from '../../shared/components/Button'
import {DeleteCompany} from '../modals/DeleteCompany'
import {DeletePopUp} from '../../shared/components/DeletePopUp'
import EditButton from '../../shared/components/EditButton'
import {EditCompanyLogo} from '../modals/EditCompanyLogo'
import {useAuth} from 'react-oidc-context'
import {useIsGlobalAdmin, useIsSuperUser} from '../../../../_theme/utils/authUtil'
import {getInitials} from '../../../../utils/stringHelpers'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import {DeleteModal} from '../../shared/components/DeleteModal'
import {FetchBaseQueryError} from '@reduxjs/toolkit/query'
import IdenticonAvatar from '../../shared/components/IdenticonAvatar'

interface CompanyHeaderProps {
  selectedTab: string
  setSelectedTab: (tab: string) => void
  currentPage: number
  pageSize: number
}

const CompanyHeader = ({
  selectedTab,
  setSelectedTab,
  currentPage,
  pageSize,
}: CompanyHeaderProps) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const navigate = useNavigate()
  const [deleteCompany, {isLoading}] = useDeleteCompanyMutation()
  const {data: companyState, error} = useGetCompanyDetailsQuery(id)
  const companyDetails = companyState?.entities[id]

  const {data: documentsState, isLoading: gettingLogo} = useGetDocumentsQuery(
    companyDetails?.logo ? [companyDetails.logo] : [],
    {
      skip: !companyDetails?.logo,
    }
  )
  let logo
  if (companyDetails) {
    logo = documentsState?.entities[companyDetails.logo]
  }
  const companyInitials = getInitials(companyDetails?.name, 3)

  const navigationTabs = [
    {name: 'Company Operations', link: '#company-operations'},
    {name: 'Financials', link: '#financials'},
    {name: 'Contact Information', link: '#contact-information'},
    {name: 'Documents', link: '#documents'},
    // {name: 'Deals', link: '#deals'},
  ]
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditLogoModal, setShowEditLogoModal] = useState(false)

  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const handleCloseDeleteOptionsModal = () => {
    if (showModal) setShowModal(false)
  }
  const handleEditLogoModal = () => {
    setShowEditLogoModal(!showEditLogoModal)
  }

  const handleDelete = async () => {
    await deleteCompany(id).unwrap()
    navigate('/companies?page=1&pageSize=6')
  }
  const isGlobalAdmin = useIsGlobalAdmin()
  const isSuperUser = useIsSuperUser()

  useEffect(() => {
    if (error) {
      const errorCode = (error as FetchBaseQueryError)?.status?.toString()
      navigate(`/error/${errorCode}`)
    }
  }, [error, navigate])

  return (
    <div className='card card-flush shadow-sm' onClick={handleCloseDeleteOptionsModal}>
      <div className='card-body py-6 d-flex flex-column gap-5 pb-0 px-6'>
        <div className='d-flex justify-content-between pb-3 '>
          <div className='d-flex gap-5 align-items-start'>
            <div className='position-relative'>
              {gettingLogo ? (
                <CustomSkeleton height={120} width={120} className='rounded' />
              ) : !logo ? (
                <IdenticonAvatar identifier={companyInitials} size={120} />
              ) : (
                <img
                  src={`data:${logo.fileType};base64,${logo.fileContent}`}
                  alt={logo.fileName}
                  height={120}
                  width={120}
                  className='rounded'
                />
              )}
              <EditButton handleClose={handleEditLogoModal} customClass='logo-edit-button' />
            </div>
            <div className='pt-5 d-flex flex-column gap-2'>
              <span className='fw-bolder text-dark fs-5'>{companyDetails?.name}</span>
              <span>{companyDetails?.code.toUpperCase() ?? ''}</span>
              <div className='d-flex gap-2 flex-wrap'>
                {companyDetails?.sectors?.map((sector) => (
                  <CardBadge text={(sector?.name ?? '').toUpperCase()} />
                ))}
                {companyDetails?.financingTypes?.map((financingType, index) => (
                  <CardBadge key={index} text={financingType.name} />
                ))}
              </div>
            </div>
          </div>
          {(isGlobalAdmin || isSuperUser) && (
            <div className='d-flex flex-column gap-0 fit-content'>
              <OptionsPopup
                handleDelete={handleDelete}
                handleDeleteModal={handleDeleteModal}
                isHeaderToggle={true}
                includeDeleteOption={true}
              />
            </div>
          )}
        </div>

        <div className='d-flex gap-5'>
          {navigationTabs.map((tab, index) => (
            <DetailsHeaderNavigationBar
              key={index}
              tab={tab}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          ))}
        </div>
      </div>
      <EditCompanyLogo
        showEditLogoModal={showEditLogoModal}
        handleEditLogoModal={handleEditLogoModal}
        currentPage={currentPage}
        pageSize={pageSize}
      />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        isLoading={isLoading}
        handleDelete={handleDelete}
        item={'Company'}
        handleClose={handleDeleteModal}
      />
    </div>
  )
}

export default CompanyHeader
