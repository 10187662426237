import {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import Search from '../../shared/components/Search'

type props = {
  setSearchTerm: (searchTerm: string) => void
  handleCreateModal: () => void
  title: string
  useModal: boolean
}

const SettingDetailsHeader: FC<props> = ({setSearchTerm, handleCreateModal, title, useModal}) => {
  const navigate = useNavigate()

  const handleButtonClick = () => {
    if (useModal) {
      handleCreateModal()
    } else {
      navigate('/settings/add')
    }
  }

  return (
    <div className='d-flex align-items-center justify-content-between'>
      <div>
        <span className='fw-bold fs-4'>{title}</span>
      </div>
      <div className='d-flex align-items-center gap-3'>
        <Search setSearchTerm={setSearchTerm} />
        <button
          type='button'
          className='btn btn-danger d-flex align-items-center justify-content-center btn-sm'
          onClick={handleButtonClick}
        >
          <span className='ms-1'>Add New {title.slice(0, -1)}</span>
        </button>
      </div>
    </div>
  )
}

export default SettingDetailsHeader
