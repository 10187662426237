import {Field} from 'formik'
import {countries} from '../../../../utils/countries'
import React from 'react'
import {MultiSelect} from '../../shared/components/MultiSelect'

const CreateFinancierGeneralInformation = ({values, errors, touched, setFileObject, setFieldValue}: any) => {
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    const file = event.target?.files ? event.target.files[0] : null
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        const base64Content = base64String.split(',')[1]
        const fileObj = {
          fileName: file.name,
          fileType: file.type,
          fileContent: base64Content,
          dateUploaded: new Date().toISOString(),
          fileSize: file.size,
        }
        setFileObject(fileObj)
        setFieldValue('generalInformation.logo', file)
      }
      reader.readAsDataURL(file)
    } else {
      setFieldValue('generalInformation.logo', null)
    }
  }
  const mappedCountries = countries.map((country) => ({
    id: country.value,
    name: country.label,
  }))
  const financierType = [
    {id: 'angel', name: 'Angel Investor'},
    {id: 'venture', name: 'Venture Capital'},
    {id: 'private', name: 'Private Equity'},
    {id: 'development', name: 'Other'},
  ]
  return (
    <div className="current" data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Financier Name`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.name"
              placeholder=""
            />

            {touched?.generalInformation?.name && (
              <div className="text-danger mt-2">{errors?.generalInformation?.name}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Country</span>
            </label>
            <MultiSelect
              options={mappedCountries}
              selectedValues={values.generalInformation.country}
              setOption={(selectedOptions) =>
                setFieldValue('generalInformation.country', selectedOptions)
              }
              size="lg"
            />

            {touched?.generalInformation?.country && (
              <div className="text-danger mt-2">{errors?.generalInformation?.country}</div>
            )}
          </div>
        </div>
        <div className="fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Mailing Address</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.mailingAddress"
            />
            {touched?.generalInformation?.mailingAddress && (
              <div className="text-danger mt-2">{errors?.generalInformation?.mailingAddress}</div>
            )}
          </div>

          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Office Address</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.officeAddress"
            />
            {touched?.generalInformation?.officeAddress && (
              <div className="text-danger mt-2">{errors?.generalInformation?.officeAddress}</div>
            )}
          </div>
        </div>
        <div className=" fv-row row p-0">
          <div className="col-lg-6">
            {/*About the company */}
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>{`About Financier`}</span>
            </label>

            <Field
              as="textarea"
              className="form-control form-control-lg py-lg-5 about-textarea"
              name="generalInformation.about"
              placeholder=""
            />
            {touched?.generalInformation?.about && (
              <div className="text-danger mt-2">{errors?.generalInformation?.about}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Category of Financier</span>
            </label>
            <MultiSelect
              options={financierType}
              selectedValues={values.generalInformation.financierType}
              setOption={(selectedOptions) =>
                setFieldValue('generalInformation.financierType', selectedOptions)
              }
              size="lg"
            />

            {touched?.generalInformation?.financierType && (
              <div className="text-danger mt-2">{errors?.generalInformation?.financierType}</div>
            )}
          </div>
        </div>

        {/* <div className="fv-row row p-0">
          <div className="col-lg-6 offset-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Financier Logo</span>
            </label>
            <input
              type="file"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.logo"
              placeholder=""
              style={{width: '100%'}}
              onChange={(event) => handleFileChange(event, setFieldValue)}
              accept="image/*"
            />
            {touched?.generalInformation?.logo && (
              <div className="text-danger mt-2">{errors?.generalInformation?.logo}</div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default CreateFinancierGeneralInformation
