import {createSelector, createEntityAdapter, EntityState} from '@reduxjs/toolkit'
import {
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
  FetchArgs,
  BaseQueryFn,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import {RootState} from '../../../data/redux/Store'
import {AUTH_TOKEN_KEY, env} from '../../../data/constants'
import {IPaginationMetadata} from '../shared/interfaces'
import {INameIdPair} from '../shared/interfaces'
import {
  ICompanyContact,
  ICompanyDetails,
  ICompanyDocumentFolders,
  ICompanyFinancingAsks,
  ISearchCompanyDocument,
} from './interfaces'
import {ICompanyDocumentMetaData} from '../documents'
import {ISearchFolder} from './interfaces/ISearchFolder'
import { ISearchEntities } from '../shared/interfaces/ISearchEntities'
import {IRole} from './interfaces/IRole'

const CompaniesAdapter = createEntityAdapter<ICompanyDetails>({
  selectId: (company) => company.id,
})
const SectorsAdapter = createEntityAdapter<INameIdPair>({
  selectId: (sector) => sector.id,
})
const FinancingTypesAdapter = createEntityAdapter<INameIdPair>({
  selectId: (financingType) => financingType.id,
})
const CompanyFinancialsAdapter = createEntityAdapter<ICompanyFinancingAsks>({
  selectId: (companyFinancials) => companyFinancials.id,
})
const CompanyContactsAdapter = createEntityAdapter<ICompanyContact>({
  selectId: (companyContacts) => companyContacts.id,
})
const CompanyDocumentsAdapter = createEntityAdapter<ICompanyDocumentMetaData>({
  selectId: (companyDocuments) => companyDocuments.id,
})
const DocumentFoldersAdapter = createEntityAdapter<ICompanyDocumentFolders>({
  selectId: (companyDocumentFolders) => companyDocumentFolders.id,
})

const RolesAdapter=createEntityAdapter<IRole>({selectId: (roles) => roles.id,})

const companiesInitialState = CompaniesAdapter.getInitialState()
const companyDocumentsInitialState = CompanyDocumentsAdapter.getInitialState()
const DocumentFoldersInitialState = DocumentFoldersAdapter.getInitialState()
const companyFinancialsInitialState = CompanyFinancialsAdapter.getInitialState()
const companyContactsInitialState = CompanyContactsAdapter.getInitialState()
const sectorsInitialState = SectorsAdapter.getInitialState()
const financingTypesInitialState = FinancingTypesAdapter.getInitialState()
const rolesInitialState = RolesAdapter.getInitialState()

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = fetchBaseQuery({
  baseUrl: env.Company,
  validateStatus: (response, result) => {
    if (result && result.isError) return false
    if (response.status >= 200 && response.status <= 300) return true
    if (response.status >= 400 && response.status <= 500) return false
    return false
  },
  prepareHeaders: (headers, {getState}) => {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY)
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
})

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions)

  if (result.error) {
    const {status} = result.error

    throw new Error(`Error status: ${status}`)
  }

  return result
}

export const CompaniesApiSlice = createApi({
  reducerPath: 'companiesApi',
  baseQuery: baseQuery,
  tagTypes: [
    'Company',
    'CompanyDetails',
    'CompanyProduct',
    'CompanyFinancials',
    'CompanyContact',
    'Sectors',
    'FinancingTypes',
    'CompanyDocument',
    'CompanyDocumentFolder',
    'Role'
  ],
  endpoints: (builder) => ({
    getCompanies: builder.query<
      EntityState<ICompanyDetails> & {paginationMetadata: IPaginationMetadata},
      ISearchEntities
    >({
      query: (searchParams) => {
        const params = new URLSearchParams()
        if (searchParams.page) params.append('Page', searchParams.page.toString())
        if (searchParams.pageSize) params.append('PageSize', searchParams.pageSize.toString())
        if (searchParams.searchTerm) params.append('SearchTerm', searchParams.searchTerm)
        if (searchParams.minInvestmentAsk)
          params.append('minInvestmentAsk', searchParams.minInvestmentAsk.toString())
        if (searchParams.maxInvestmentAsk)
          params.append('maxInvestmentAsk', searchParams.maxInvestmentAsk.toString())
        if (searchParams.investmentAskCurrency)
          params.append('investmentAskCurrency', searchParams.investmentAskCurrency)
        if (searchParams.countries) {
          searchParams.countries.forEach((country) => params.append('Countries', country))
        }
        if (searchParams.minYearsInOperation)
          params.append('minYearsInOperation', searchParams.minYearsInOperation.toString())
        if (searchParams.maxYearsInOperation)
          params.append('maxYearsInOperation', searchParams.maxYearsInOperation.toString())
        if (searchParams.sectors) {
          searchParams.sectors.forEach((sector) => params.append('Sectors', sector))
        }
        if (searchParams.financingTypes) {
          searchParams.financingTypes.forEach((type) => params.append('FinancingTypes', type))
        }
        if (searchParams.onboardingStatus) {
          params.append('onboardingStatus', searchParams.onboardingStatus)
        }

        return {
          url: '/companies',
          params: params,
        }
      },
      transformResponse: (response: ICompanyDetails[], meta: FetchBaseQueryMeta) => {
        const entities = CompaniesAdapter.setAll(companiesInitialState, response)
        const paginationMetadata = meta.response?.headers.get('X.Pagination')
        const parsedPaginationMetadata = paginationMetadata ? JSON.parse(paginationMetadata) : null
        return {
          ...entities,
          paginationMetadata: {
            totalCount: parsedPaginationMetadata?.TotalCount,
            page: parsedPaginationMetadata?.Page,
            pageSize: parsedPaginationMetadata?.PageSize,
            hasNextPage: parsedPaginationMetadata?.HasNextPage,
            hasPreviousPage: parsedPaginationMetadata?.HasPreviousPage,
          },
        }
      },
      providesTags: (result: EntityState<ICompanyDetails> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'Company', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'Company' as const, id})),
          ]
        } else return [{type: 'Company', id: 'LIST'}]
      },
    }),
    getCompanyDetails: builder.query<EntityState<ICompanyDetails>, string>({
      query: (id) => `/companies/${id}`,
      transformResponse: (res: any) => {
        return CompaniesAdapter.setOne(companiesInitialState, res)
      },
      providesTags: (result, _error, id) => [{type: 'CompanyDetails', id}],
    }),
    addNewCompany: builder.mutation({
      query: (initialCompanyData) => ({
        url: '/companies',
        method: 'POST',
        body: initialCompanyData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'Company', id: 'LIST'}],
    }),
    updateCompany: builder.mutation({
      query: (updatedCompanyData) => ({
        url: `/companies/${updatedCompanyData.id}`,
        method: 'PUT',
        body: updatedCompanyData,
      }),
      invalidatesTags: (_result, _error, arg) => [
        {type: 'Company', id: arg.id},
        {type: 'CompanyDetails', id: arg.id},
      ],
    }),
    deleteCompany: builder.mutation({
      query: (id) => ({
        url: `/companies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        {type: 'Company', id: arg.id},
        {type: 'CompanyDetails', id: arg.id},
      ],
    }),
    addNewCompanyFinancials: builder.mutation({
      query: (CompanyFinancial) => ({
        url: '/companies/financing/asks',
        method: 'POST',
        body: {
          ...CompanyFinancial,
        },
      }),
      invalidatesTags: [{type: 'CompanyFinancials', id: 'LIST'}],
    }),
    getCompanyFinancials: builder.query<EntityState<ICompanyFinancingAsks>, string>({
      query: (id) => ({
        url: `/companies/financing/asks/?companyId=${id}`,
      }),
      transformResponse: (res: any) => {
        return CompanyFinancialsAdapter.setAll(companyFinancialsInitialState, res)
      },
      providesTags: (result: EntityState<ICompanyFinancingAsks> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'CompanyFinancials', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'CompanyFinancials' as const, id})),
          ]
        } else return [{type: 'CompanyFinancials', id: 'LIST'}]
      },
    }),
    updateCompanyFinancials: builder.mutation({
      query: (updatedCompanyFinancialsData) => ({
        url: `/companies/financing/asks/${updatedCompanyFinancialsData.id}`,
        method: 'PUT',
        body: updatedCompanyFinancialsData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'CompanyFinancials', id: arg.id}],
      async onQueryStarted({id, ...rest}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
          CompaniesApiSlice.util.updateQueryData('getCompanyFinancials', id, (draft) => {
            let companyFinancials = draft.entities[id]
            if (companyFinancials) {
              Object.assign(companyFinancials, rest)
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
      },
    }),
    getCompanyContacts: builder.query<EntityState<ICompanyContact>, string>({
      query: (id) => ({
        url: `/companies/contacts/?companyId=${id}`,
      }),
      transformResponse: (res: any) => {
        const transformedResponse = res.map((contact: any) => ({
          companyContactPersonAddress: contact.address,
          companyContactPersonName: contact.name,
          companyContactPersonEmail: contact.email,
          companyContactPersonTelephone: contact.telephone,
          companyContactPersonPicture: contact.picture,
          companyId: contact.companyId,
          id: contact.id,
        }))
        return CompanyContactsAdapter.setAll(companyContactsInitialState, transformedResponse)
      },
      providesTags: (result: EntityState<ICompanyContact> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'CompanyContact', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'CompanyContact' as const, id})),
          ]
        } else return [{type: 'CompanyContact', id: 'LIST'}]
      },
    }),
    updateCompanyContact: builder.mutation({
      query: (updatedCompanyContactData) => ({
        url: `/companies/contacts/${updatedCompanyContactData.id}`,
        method: 'PUT',
        body: updatedCompanyContactData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'CompanyContact', id: arg.id}],
      async onQueryStarted({id, ...rest}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
          CompaniesApiSlice.util.updateQueryData('getCompanyContacts', id, (draft) => {
            let companyContact = draft.entities[id]
            if (companyContact) {
              Object.assign(companyContact, rest)
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
      },
    }),
    getSectors: builder.query<EntityState<INameIdPair>, void>({
      query: () => ({
        url: `/sectors`,
      }),
      transformResponse: (res: any) => {
        return SectorsAdapter.setAll(sectorsInitialState, res)
      },
      providesTags: (result: EntityState<INameIdPair> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'Sectors', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'Sectors' as const, id})),
          ]
        } else return [{type: 'Sectors', id: 'LIST'}]
      },
    }),
    addNewSector: builder.mutation({
      query: (sectorName) => ({
        url: '/sectors',
        method: 'POST',
        body: sectorName,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'Sectors', id: 'LIST'}],
    }),
    updateSector: builder.mutation({
      query: (sectorData) => ({
        url: `/sectors/${sectorData.id}`,
        method: 'PUT',
        body: sectorData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'Sectors', id: arg.id}],
    }),
    deleteSector: builder.mutation({
      query: (id) => ({
        url: `/sectors/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'Sectors', id: arg.id}],
    }),
    getFinancingTypes: builder.query<EntityState<INameIdPair>, void>({
      query: () => ({
        url: `/financing/types`,
      }),
      transformResponse: (res: any) => {
        return FinancingTypesAdapter.setAll(financingTypesInitialState, res)
      },
      providesTags: (result: EntityState<INameIdPair> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'FinancingTypes', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'FinancingTypes' as const, id})),
          ]
        } else return [{type: 'FinancingTypes', id: 'LIST'}]
      },
    }),
    addNewFinancingType: builder.mutation({
      query: (financingTypeName) => ({
        url: '/financing/types',
        method: 'POST',
        body: financingTypeName,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'FinancingTypes', id: 'LIST'}],
    }),
    updateFinancingType: builder.mutation({
      query: (financingTypeData) => ({
        url: `/financing/types/${financingTypeData.id}`,
        method: 'PUT',
        body: financingTypeData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'FinancingTypes', id: arg.id}],
    }),
    deleteFinancingType: builder.mutation({
      query: (id) => ({
        url: `/financing/types/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'FinancingTypes', id: arg.id}],
    }),
    addNewCompanyDocument: builder.mutation({
      query: (documentMetaData) => ({
        url: '/documents',
        method: 'POST',
        body: documentMetaData,
      }),
      invalidatesTags: (_result, _error) => [
        {type: 'CompanyDocument', id: 'LIST'},
        {type: 'CompanyDocumentFolder', id: 'LIST'},
      ],
    }),
    getCompanyDocuments: builder.query<
      EntityState<ICompanyDocumentMetaData>,
      ISearchCompanyDocument
    >({
      query: ({companyId, folderId, searchTerm}) => ({
        url: `/documents`,
        params: {folderId, companyId, searchTerm},
      }),
      transformResponse: (res: any) => {
        return CompanyDocumentsAdapter.setAll(companyDocumentsInitialState, res)
      },
      providesTags: (result: EntityState<ICompanyDocumentMetaData> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'CompanyDocument', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'CompanyDocument' as const, id})),
          ]
        } else return [{type: 'CompanyDocument', id: 'LIST'}]
      },
    }),
    deleteCompanyDocument: builder.mutation({
      query: ({id}) => ({
        url: `/documents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        {type: 'CompanyDocument', id: arg.id},
        {type: 'CompanyDocumentFolder', id: arg.folderId},
      ],
    }),
    getDocumentFolders: builder.query<EntityState<ICompanyDocumentFolders>, ISearchFolder>({
      query: ({id, companyId, searchTerm, includeOnlyParentFolders, parentFolderId}) => ({
        url: `/folders/`,
        params: {id, companyId, searchTerm, parentFolderId, includeOnlyParentFolders},
      }),
      transformResponse: (res: any) => {
        return DocumentFoldersAdapter.setAll(DocumentFoldersInitialState, res)
      },
      providesTags: (result: EntityState<ICompanyDocumentFolders> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'CompanyDocumentFolder', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'CompanyDocumentFolder' as const, id})),
          ]
        } else return [{type: 'CompanyDocumentFolder', id: 'LIST'}]
      },
    }),
    addNewDocumentFolder: builder.mutation({
      query: (documentFolderData) => ({
        url: '/folders',
        method: 'POST',
        body: documentFolderData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'CompanyDocumentFolder', id: 'LIST'}],
    }),
    updateDocumentFolder: builder.mutation({
      query: (documentFolderData) => ({
        url: `/folders/${documentFolderData.id}`,
        method: 'PUT',
        body: documentFolderData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'CompanyDocumentFolder', id: 'LIST'}],
    }),
    deleteDocumentFolder: builder.mutation({
      query: (id) => ({
        url: `/folders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'CompanyDocumentFolder', id: arg.id}],
    }),
    checkNameAvailability: builder.mutation({
      query: ({name}) => ({
        url: '/check-company-name',
        body: {name},
        method: 'POST',
      }),}),
      getRoles: builder.query<EntityState<IRole>, void>({
        query: (name?) => ({
          url: `/roles`,
          body:name,
        }),
        transformResponse: (res: any) => {
          return RolesAdapter.setAll(rolesInitialState, res)
        },
        providesTags: (result: EntityState<IRole> | undefined) => {
          if (result?.ids) {
            return [
              {type: 'Role', id: 'LIST'},
              ...result.ids.map((id) => ({type: 'Role' as const, id})),
            ]
          } else return [{type: 'Role', id: 'LIST'}]
        },

      }),
    triggerRoles: builder.query<EntityState<IRole>, string>({
      query: (name) => ({
        url: `/roles`,
        params: { name }, // Send the name as a query parameter
      }),
      transformResponse: (res: { data: IRole[] }) => {
        return RolesAdapter.setAll(rolesInitialState, res.data); // Extract actual role list
      },
    }),

      addNewRole: builder.mutation({
        query: (roleName) => ({
          url: '/roles',
          method: 'POST',
          body: roleName,
        }),
        invalidatesTags: (_result, _error, arg) => [{type: 'Role', id: 'LIST'}],
      }),
      updateRole: builder.mutation({
        query: (roleData) => ({
          url: `/roles/${roleData.id}`,
          method: 'PUT',
          body: roleData,
        }),
        invalidatesTags: (_result, _error, arg) => [{type: 'Role', id: arg.id}],
      }),
      deleteRole: builder.mutation({
        query: (id) => ({
          url: `/roles/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_result, _error, arg) => [{type: 'Role', id: arg.id}],
      }),

  }),
})

export const {
  // Companies
  useGetCompaniesQuery,
  useGetCompanyDetailsQuery,
  useAddNewCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useCheckNameAvailabilityMutation,
  // Company Contacts
  useUpdateCompanyContactMutation,
  useGetCompanyContactsQuery,
  // Company Financials
  useGetCompanyFinancialsQuery,
  useUpdateCompanyFinancialsMutation,
  useAddNewCompanyFinancialsMutation,
  // Company Documents
  useAddNewCompanyDocumentMutation,
  useGetCompanyDocumentsQuery,
  useDeleteCompanyDocumentMutation,
  // Company Document Folders
  useAddNewDocumentFolderMutation,
  useUpdateDocumentFolderMutation,
  useGetDocumentFoldersQuery,
  useDeleteDocumentFolderMutation,
  // Company Sectors
  useGetSectorsQuery,
  useAddNewSectorMutation,
  useUpdateSectorMutation,
  useDeleteSectorMutation,
  // Financing Types
  useGetFinancingTypesQuery,
  useAddNewFinancingTypeMutation,
  useUpdateFinancingTypeMutation,
  useDeleteFinancingTypeMutation,
  //Roles
  useGetRolesQuery,
  useLazyTriggerRolesQuery,
  useAddNewRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = CompaniesApiSlice

// Companies selector
export const selectCompaniesResult = (state: RootState, searchParams: ISearchEntities) =>
  CompaniesApiSlice.endpoints.getCompanies.select(searchParams)(state)

export const selectCompaniesData = (searchParams: ISearchEntities) =>
  createSelector(
    (state: RootState) => selectCompaniesResult(state, searchParams),
    (companiesResult) => companiesResult?.data ?? companiesInitialState
  )

export const createCompanySelectors = (searchParams: ISearchEntities) => {
  const selectData = selectCompaniesData(searchParams)
  return CompaniesAdapter.getSelectors((state: RootState) => selectData(state))
}

// Custom Document Folders selectors
export const selectDocumentResult = (searchObject: ISearchFolder) =>
  CompaniesApiSlice.endpoints.getDocumentFolders.select(searchObject)

// creates memoized selector
const selectFoldersData = createSelector(
  [selectDocumentResult],
  (DocumentSelector) => (state: RootState) => {
    const result = DocumentSelector(state)
    return result.data ?? DocumentFoldersInitialState // Accessing the 'data' property here
  }
)

// Get selectors from adapter and use the custom selector to access the state
export const {selectById: selectFolderById, selectAll: selectAllFolders} =
  DocumentFoldersAdapter.getSelectors((state: RootState) => selectFoldersData(state)(state))

//Financier sectors selectors

export const selectSectorsResult = CompaniesApiSlice.endpoints.getSectors.select()

const selectSectorsData = createSelector(selectSectorsResult, (SectorsResult) => SectorsResult.data)

export const selectRolesResult =CompaniesApiSlice.endpoints.getRoles.select()

const selectRolesData = createSelector(selectRolesResult, (RolesResult) => RolesResult.data)


export const {
  selectAll: selectAllSectors,
  selectById: selectSectorById,
  selectIds: selectSectorIds,
} = SectorsAdapter.getSelectors(
  (state: RootState) => selectSectorsData(state) ?? sectorsInitialState
)

export const {
  selectAll: selectAllRoles,
  selectById: selectRoleById,
  selectIds: selectRoleIds,
} = RolesAdapter.getSelectors(
  (state: RootState) => selectRolesData(state) ?? rolesInitialState
)