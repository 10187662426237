import {createEntityAdapter, createSelector, EntityState} from '@reduxjs/toolkit'
import {IUser} from '../settings/interfaces'
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import {AUTH_TOKEN_KEY, env} from '../../../data/constants'
import {IUserDetails} from '../settings/interfaces/IUserDetails'
import {IPaginationMetadata, ISearchEntities} from '../shared/interfaces'
import {RootState} from '../../../data/redux/Store'

const UsersAdapter = createEntityAdapter<IUser>({
  selectId: (user) => user.id,
})

const userIntialState = UsersAdapter.getInitialState()

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = fetchBaseQuery({
  baseUrl: env.Users,
  validateStatus: (response, result) => {
    if (result && result.isError) return false
    if (response.status >= 200 && response.status <= 300) return true
    if (response.status >= 400 && response.status <= 500) return false
    return false
  },
  prepareHeaders: (headers, {getState}) => {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY)
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
})

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions)

  if (result.error) {
    const {status} = result.error

    throw new Error(`Error status: ${status}`)
  }

  return result
}

export const AuthApiSlice = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  tagTypes: ['User', 'UserDetails'],
  endpoints: (builder) => ({
    getUsers: builder.query<
      EntityState<IUserDetails> & {paginationMetadata: IPaginationMetadata},
      ISearchEntities
    >({
      query: (searchParams: ISearchEntities) => {
        const params = new URLSearchParams()
        params.append('page', (searchParams.page || 1).toString())
        params.append('pageSize', (searchParams.pageSize || 100).toString())
        params.append('client', 'Black')

        return {
          url: '/users',
          params: params,
        }
      },
      transformResponse: (
        response: {data: IUserDetails[]; paginationMetadata: any},
        meta: FetchBaseQueryMeta,
      ) => {
        const entities = UsersAdapter.setAll(userIntialState, response.data)
        return {
          ...entities,
          paginationMetadata: {
            totalCount: response.paginationMetadata.totalItems,
            page: response.paginationMetadata.currentPage,
            pageSize: response.paginationMetadata.pageSize,
            hasNextPage: response.paginationMetadata.hasNextPage,
            hasPreviousPage: response.paginationMetadata.hasPreviousPage,
            totalPages: response.paginationMetadata.totalPages,
          },
        }
      },
      providesTags: (result: EntityState<IUserDetails> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'User', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'User' as const, id})),
          ]
        } else return [{type: 'User', id: 'LIST'}]
      },
    }),
    getUserDetails: builder.query<EntityState<IUserDetails>, string>({
      query: (id) => `/users/${id}`,
      transformResponse: (res: any) => {
        return UsersAdapter.setOne(userIntialState, res)
      },
      providesTags: (result, _error, id) => [{type: 'UserDetails', id}],
    }),
    addNewUser: builder.mutation({
      query: (intialUserData) => ({
        url: '/users',
        method: 'POST',
        body: intialUserData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'User', id: 'LIST'}],
    }),
    updateUser: builder.mutation({
      query: (updatedUserData) => ({
        url: `/users/${updatedUserData.id}`,
        method: 'PUT',
        body: updatedUserData,
      }),
      invalidatesTags: (_result, _error, arg) => [
        {type: 'User', id: arg.id},
        {type: 'UserDetails', id: arg.id},
      ],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        {type: 'User', id: arg.id},
        {type: 'UserDetails', id: arg.id},
      ],
    }),
  }),
})

export const {
  useGetUsersQuery,
  useGetUserDetailsQuery,
  useAddNewUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = AuthApiSlice

//Auth Selectors

export const selectUsersResult = (state: RootState, searchParams: ISearchEntities) =>
  AuthApiSlice.endpoints.getUsers.select(searchParams)(state)

export const selectUsersData = (searchParams: ISearchEntities) =>
  createSelector(
    (state: RootState) => selectUsersResult(state, searchParams),
    (usersResult) => usersResult?.data ?? userIntialState,
  )
export const createUserSelectors = (searchParams: ISearchEntities) => {
  const selectData = selectUsersData(searchParams)
  return UsersAdapter.getSelectors((state: RootState) => selectData(state))
}
