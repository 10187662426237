import React, {FC, Suspense, useState} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {FallbackView} from '../../_theme/layout/components'
import {MasterLayout} from '../../_theme/layout/MasterLayout'
import {Overview} from '../features/overview/pages'
import {Companies, CompanyDetails, CreateCompany} from '../features/companies/pages'
import {Financiers, CreateFinancier, FinancierDetails} from '../features/financiers/pages'
import {
  CreateServiceProvider,
  ServiceProviderDetails,
  ServiceProviders,
} from '../features/serviceProviders/pages'
import Settings from '../features/settings/pages/Settings'
import {ErrorsPage} from '../features/errors/ErrorsPage'
import {
  CreateDevelopmentPartner,
  DevelopmentPartnerDetails,
  DevelopmentPartners,
} from '../features/developmentPartners/pages'

import {CreateUser} from '../features/settings/pages/CreateUser'

const PrivateRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/companies?page=1&pageSize=6" />} />
        <Route path="/overview" element={<Overview />} />
        <Route index element={<Navigate to="/companies" />} />
        <Route path="companies" element={<Outlet />}>
          <Route index element={<Companies />} />
          <Route path="add" element={<CreateCompany />} />
          <Route path=":id" element={<CompanyDetails />} />
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Route>
        {/* Other routes */}
        <Route path="/financiers" element={<Financiers />} />
        <Route path="/financiers/add" element={<CreateFinancier />} />
        <Route path="/financiers/:id" element={<FinancierDetails />} />
        <Route path="/development/partners" element={<DevelopmentPartners />} />
        <Route path="/development/partners/add" element={<CreateDevelopmentPartner />} />
        <Route path="/development/partners/:id" element={<DevelopmentPartnerDetails />} />
        <Route path="/services/providers" element={<ServiceProviders />} />
        <Route path="/services/providers/add" element={<CreateServiceProvider />} />
        <Route path="/services/providers/:id" element={<ServiceProviderDetails />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/*" element={<Settings />}>
          <Route path="add" element={<CreateUser />} />
        </Route>
        {/* <Route path='/' element={<Navigate to='/companies' />} /> */}
        {/* Catch-all for invalid routes */}

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
      <Route path="/error/:errorCode" element={<ErrorsPage />} />
    </Routes>
  )
}

export {PrivateRoutes}
