import React, {useEffect, useState} from 'react'
import SettingDetailsBody from './SettingDetailsBody'

import {
  useAddNewRoleMutation,
  useDeleteRoleMutation,
  useGetRolesQuery,
  useLazyTriggerRolesQuery,
  useUpdateRoleMutation,
} from '../../companies/CompaniesApiSlice'

import Loading from '../../shared/components/Loading'
import {INameIdPair} from '../../shared/interfaces'
import {AddSettingItem} from '../Modals/AddSettingItem'
import {UpdateSettingItem} from '../Modals/UpdateSettingItem'
import {DeleteModal} from '../../shared/components/DeleteModal'
import {IRole} from '../../companies/interfaces/IRole'
import {boolean, string} from 'yup'
import SettingDetailsHeader from '../shared/SettingDetailsHeader'

const RolesSettings = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [debouncedValue, setDebouncedValue] = useState('')
  const [isCheckingName, setIsCheckingName] = useState(false)
  const [nameExists, setNameExists] = useState<boolean | null>(null) // true = exists, false = unique
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedRole, setSelectedRole] = useState<IRole | null>(null)
  const [triggerRoles, {data: rolesList, isFetching}] = useLazyTriggerRolesQuery()
  const {data: rolesState, isLoading} = useGetRolesQuery()
  const [addRole, {isLoading: isCreatingRole}] = useAddNewRoleMutation()
  const [updateRole, {isLoading: isUpdatingRole}] = useUpdateRoleMutation()
  const [deleteRole, {isLoading: isDeletingRole}] = useDeleteRoleMutation()
  const companyRoles = Object.values(rolesState?.entities.undefined || {}).filter(
    (role): role is IRole => Boolean(role),
  )

  const filteredCompanyRoles = companyRoles.filter((role) =>
    role.name?.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue)
      handleCheckName(inputValue)  // Trigger the check after delay
    }, 500) // 500ms debounce delay

    return () => {
      clearTimeout(handler)  // Cleanup timeout if the user types again before 500ms
    }
  }, [inputValue])

  const handleCheckName = async (name: string) => {
    if (!name.trim()) return

    try {
      const response = await triggerRoles(name) // Fetch latest roles

      console.log(response)
      if (!response.data) return

      setIsCheckingName(true) // Show "Checking..." message
      setNameExists(null) // Reset previous results
      setErrorMessage(null) // Clear old errors

      const rolesEntities = response.data.entities // Access EntityState entities

      // Convert entities to an array and check for a matching name
      const exists = Object.values(rolesEntities).some(
        (role) => role && role.name && role.name.toLowerCase() === name.toLowerCase(),
      )

      setNameExists(exists) // Set exists flag
    } catch (error) {
      setErrorMessage('Error checking role name. Please try again.')
    } finally {
      setIsCheckingName(false) // Hide "Checking..." message
    }
  }

  const handleCreateModal = () => {
    setNameExists(null)  // Reset name validation message when opening/closing modal
    setErrorMessage(null)
    setShowCreateModal(!showCreateModal)
  }

  const handleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal)
  }
  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const handleSave = async (name: string, description?: string) => {
    if (nameExists === true) {
      setErrorMessage('Role name already exists.') // Optional: show a custom error message
      return // Prevent save if the name exists
    }
    try {
      await addRole({
        name,
        description,
      }).unwrap()
      setNameExists(null)
      setErrorMessage(null)

      handleCreateModal()
    } catch (error) {
    }
  }
  const handleUpdate = async (name: string, description?: string) => {
    try {
      await updateRole({
        name,
        id: selectedRole?.id,
        description,
      }).unwrap()
      setSelectedRole(null)
      handleUpdateModal()
    } catch (error) {
    }
  }
  const handleDelete = async () => {
    try {
      if (selectedRole) {
        await deleteRole(selectedRole.id).unwrap()
      }
      setSelectedRole(null)
      handleDeleteModal()
    } catch (error) {
    }
  }
  return (
    <div className="card card-flush card-bordered card-60vh p-5">
      <div className="mb-5">
        <SettingDetailsHeader
          setSearchTerm={setSearchTerm}
          handleCreateModal={handleCreateModal}
          title={'Roles'}
          useModal={true}
        />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <SettingDetailsBody
          items={filteredCompanyRoles}
          setSelectedItem={setSelectedRole}
          handleUpdateModal={handleUpdateModal}
          handleDeleteModal={handleDeleteModal}
        />
      )}
      <AddSettingItem
        showCreateModal={showCreateModal}
        handleCreateModal={handleCreateModal}
        handleSave={handleSave}
        isLoading={isCreatingRole}
        handleCheckName={handleCheckName}
        section={'Role'}
        isCheckingName={isCheckingName}
        nameExists={nameExists}
        errorMessage={errorMessage}
        setInputValue={setInputValue}

      />
      <UpdateSettingItem
        showUpdateModal={showUpdateModal}
        handleUpdateModal={handleUpdateModal}
        handleUpdate={handleUpdate}
        isLoading={isUpdatingRole}
        section={'Role'}
        item={selectedRole}
      />
      <DeleteModal
        item={'Role'}
        showDeleteModal={showDeleteModal}
        handleClose={handleDeleteModal}
        isLoading={isDeletingRole}
        handleDelete={handleDelete}
      />
    </div>
  )
}

export default RolesSettings
