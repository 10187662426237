import {FC, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import {SettingsSideMenu} from '../components/SettingsSideMenu'
import SectorsSettings from '../components/SectorsSettings'
import FinancingTypesSettings from '../components/FinancingTypesSettings'
import {UserSettings} from '../components/UserSettings'
import RolesSettings from '../components/RolesSettings'

const Settings: FC = () => {
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState('Sectors')

  // Check if the user is in a nested settings route (e.g., "/settings/add")
  const isNestedRoute = location.pathname !== '/settings'

  return (
    <>
      <Toolbar page="Settings" />
      <div className="row px-4">
        <div className="col-2">
          <SettingsSideMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </div>
        <div className="col-10">
          {/* Show tab content only on the main settings page */}
          {!isNestedRoute ? (
            <>
              {selectedTab === 'Sectors' && <SectorsSettings />}
              {selectedTab === 'Financing Types' && <FinancingTypesSettings />}
              {selectedTab === 'Roles' &&
                <RolesSettings />
              }
              {selectedTab === 'Users' && <UserSettings />}
            </>
          ) : (
            <Outlet /> // Render nested routes like CreateUser
          )}
        </div>
      </div>
    </>
  )
}

export default Settings
