import Button from '../../shared/components/Button'
import {Link, useNavigate, useParams} from 'react-router-dom'
import DetailsHeaderNavigationBar from '../../shared/components/DetailsHeaderNavigationBar'
import React, {FC, useEffect, useState} from 'react'
import {
  createServiceProviderSelectors,
  useDeleteServiceProviderMutation,
  useGetServiceProvidersQuery,
} from '../ServiceProvidersApiSlice'
import {getInitials} from '../../../../utils/stringHelpers'
import CardBadge from '../../shared/components/CardBadge'
import {AssignProject} from '../modals/AssignProject'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'
import CustomSkeleton from '../../shared/components/CustomSkeleton'
import EditButton from '../../shared/components/EditButton'
import {useIsGlobalAdmin, useIsSuperUser} from '../../../../_theme/utils/authUtil'
import {EditServiceProviderLogo} from '../modals/EditServiceProviderLogo'
import {DeleteModal} from '../../shared/components/DeleteModal'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import IdenticonAvatar from '../../shared/components/IdenticonAvatar'

interface HeaderProps {
  selectedTab: string
  setSelectedTab: (tab: string) => void
  pageSize: number
  currentPage: number
  searchTerm: string
}

const ServiceProviderHeader: FC<HeaderProps> = ({
  selectedTab,
  setSelectedTab,
  pageSize,
  currentPage,
  searchTerm,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [showEditOptionsModal, setShowEditOptionsModal] = useState(false)
  const [showEditLogoModal, setShowEditLogoModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteServiceProvider, {isLoading}] = useDeleteServiceProviderMutation()



  const navigationTabs = [
    {name: 'Business Operations'},
    {name: 'Contact Information'},
    {name: 'Documents'},
    {name: 'Projects'},
  ]

  const {id} = useParams<{id: string}>() as {id: string}
  const navigate = useNavigate()
  useGetServiceProvidersQuery({page: currentPage, pageSize: pageSize, searchTerm})
  const {selectById: selectServiceProviderById} = createServiceProviderSelectors(
    {
      page: currentPage,
      pageSize,
      searchTerm,
    }  
  )
  const serviceProviderDetails = useSelector((state: RootState) =>
    selectServiceProviderById(state, id || '')
  )
  const {data: documentsState, isLoading: gettingLogo} = useGetDocumentsQuery(
    serviceProviderDetails?.logo ? [serviceProviderDetails.logo] : [],
    {
      skip: !serviceProviderDetails?.logo,
    }
  )
  let logo
  if (serviceProviderDetails) {
    logo = documentsState?.entities[serviceProviderDetails.logo]
  }

  const handleClose = () => {
    setShowModal(!showModal)
  }

  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const handleCloseEditOptionsModal = () => {
    if (showEditOptionsModal) setShowEditOptionsModal(false)
  }

  const handleEditLogoModal = () => {
    setShowEditLogoModal(!showEditLogoModal)
  }
  const handleDelete = async () => {
    await deleteServiceProvider(id).unwrap()
    navigate('/services/providers')
  }

  const serviceProviderInitials = getInitials(serviceProviderDetails?.name, 3)

  const isGlobalAdmin = useIsGlobalAdmin()
  const isSuperUser = useIsSuperUser()

  return (
    <div className='card card-flush shadow-sm' onClick={handleCloseEditOptionsModal}>
      <div className='card-body py-5 gap-5 pb-0'>
        <div className='d-flex justify-content-between pb-12'>
          <div className='d-flex gap-5'>
            <div className='position-relative'>
              {gettingLogo ? (
                <CustomSkeleton height={120} width={120} className='rounded' />
              ) : !logo ? (
                <IdenticonAvatar identifier={serviceProviderInitials} size={120} />
              ) : (
                <img
                  src={`data:${logo.fileType};base64,${logo.fileContent}`}
                  alt={logo.fileName}
                  height={120}
                  width={120}
                  className='rounded'
                />
              )}
              <EditButton handleClose={handleEditLogoModal} customClass='logo-edit-button' />
            </div>
            <div className='pt-5 d-flex flex-column gap-2'>
              <span className='fw-bolder text-dark fs-4'>{serviceProviderDetails?.name ?? ''}</span>
              <span>{serviceProviderDetails?.code}</span>
              <div className='d-flex gap-2 flex-wrap'>
                {serviceProviderDetails?.serviceProviderSectors.map((sector) => (
                  <CardBadge key={sector.id} text={sector.sectorName} />
                ))}
              </div>
            </div>
          </div>
          <div className='d-flex flex-row gap-0 justify-content-center align-items-start '>
            <div className='pt-2'>
              <button className='btn btn-sm m-lg-2 btn-outline btn-outline-danger btn-active-light-danger'>
                {serviceProviderDetails?.numberOfCompletedProjects ?? 0} Completed Projects
              </button>
              <Button
                displayText='Assign Project'
                buttonClass={'danger'}
                isDisabled={true}
                id='kt_assign_project'
                onClick={handleClose}
              />
            </div>
            {(isGlobalAdmin || isSuperUser) && (
               <OptionsPopup
               handleDelete={handleDelete}
               handleDeleteModal={handleDeleteModal}
               isHeaderToggle={true}
               includeDeleteOption={true}
             />
            )}
          </div>
        </div>

        <div className='d-flex gap-5'>
          {navigationTabs.map((tab, index) => (
            <DetailsHeaderNavigationBar
              key={index}
              tab={tab}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          ))}
        </div>
        <EditServiceProviderLogo
          showEditLogoModal={showEditLogoModal}
          handleEditLogoModal={handleEditLogoModal}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </div>
      <AssignProject showModal={showModal} handleClose={handleClose} />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        isLoading={isLoading}
        handleDelete={handleDelete}
        item={'Service Provider'}
        handleClose={handleDeleteModal}
      />
    </div>
  )
}

export {ServiceProviderHeader}
