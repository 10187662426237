import {FC, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {getInitials, stripHTML} from '../../../../utils/stringHelpers'
import {timeAgo} from '../../../../utils/dateHelpers'
import {createCompanySelectors, useGetCompanyDetailsQuery, useGetCompanyFinancialsQuery} from '../CompaniesApiSlice'
import CardBadge from '../../shared/components/CardBadge'
import CardDetail from '../../shared/components/CardDetail'
import ProgressBar from '../../shared/components/ProgressBar'
import {INameIdPair} from '../../shared/interfaces'
import {ICompanyFinancingAsks, IListPageFilters} from '../interfaces'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import CustomSkeleton from '../../shared/components/CustomSkeleton'
import IdenticonAvatar from '../../shared/components/IdenticonAvatar'
import {useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'

type CompanyCardProps = {
  id: string
  page: number
  pageSize: number
  searchTerm: string
  filters: IListPageFilters
  includeOnboardingStage?: boolean
  showCompanyCode?: boolean
  includeFinancingAsk?: boolean
  linkToCompanies?: boolean
  showCardIcons?: boolean
  isPipelineReady?: boolean
  isBorderWhite?: boolean
}

export const CompanyCard: FC<CompanyCardProps> = ({
                                                    id,
                                                    page,
                                                    pageSize,
                                                    searchTerm,
                                                    filters,
                                                    linkToCompanies = true,
                                                    includeOnboardingStage = true,
                                                    showCompanyCode = false,
                                                    includeFinancingAsk = false,
                                                    showCardIcons = false,
                                                    isPipelineReady = false,
                                                    isBorderWhite = false,
                                                  }) => {
  const {selectById: selectCompanyById} = createCompanySelectors({
    page,
    pageSize,
    searchTerm,
    ...filters,
    ...(isPipelineReady ? {onboardingStatus: 'full'} : {}),
  })
  const {
    data: companyState,
    isLoading: companyDetailsLoading,
    error,
  } = useGetCompanyDetailsQuery(id)
  const {data: companyFinancialsState, isLoading: companyFinancialsLoading} =
    useGetCompanyFinancialsQuery(id)
  const [companyFinancial] = Object.values(companyFinancialsState?.entities ?? {}) as ICompanyFinancingAsks[]
  const company = companyState?.entities[id]
  const checklistValues = company?.onboardingStatus.split(',').map(Number)
  const completedSteps = checklistValues?.filter((value) => value === 1).length
  const intl = useIntl()
  const navigate = useNavigate()
  const summary = stripHTML(company?.summary ?? '') as string
  const companyInitials = getInitials(company?.name, 3)
  const [isExpanded, setIsExpanded] = useState(false)
  const {data: documentsState, isLoading: gettingLogo} = useGetDocumentsQuery(
    company?.logo ? [company.logo] : [],
    {
      skip: !company?.logo,
    },
  )


  let logo
  if (company) {
    logo = documentsState?.entities[company.logo]
  }
  const handleNavigate = () => {
    navigate(`/companies/${company?.id}`, {
      state: {currentPage: page, pageSize},
    })
  }


  const currencies: any = {
    0: 'USD',
    1: 'UGX',
    2: 'EUR',
  }

  const countryString = company?.country ? company.country.split(',')[0] : '-'
  const regionString = company?.region ? company.region.split(',')[0] : '-'

  return (
    <div
      className="col-md-6 col-lg-4 col-sm-12 col-xl-4 min-height-200px pb-6">
      {!isPipelineReady ? (
        <Link to
                ={
          company?.id
            ?
            `/companies/${company?.id}`
            :
            '#'}
              style={{ pointerEvents: company?.id ? 'auto' : 'none' }}
        >
        <div
            className="card border border-2 border-gray-300 mb-1 h-100 d-flex flex-column justify-content-between"
            style={{cursor: 'pointer'}}
          >
            <div className="d-flex border-0 pt-2 px-5 justify-content-between align-items-center">
              {gettingLogo ? (
                <CustomSkeleton height={50} width={75} className="rounded" />
              ) : logo ? (
                <img
                  src={`data:${logo.fileType};base64,${logo.fileContent}`}
                  alt={logo.fileName}
                  height={50}
                  width={75}
                  style={{objectFit: 'contain'}}
                />
              ) : (
                <IdenticonAvatar identifier={companyInitials} size={50} />
              )}

              <div className="d-flex py-4 text-truncate" style={{
                maxWidth: '100%',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
                <CardBadge text={(company?.sectors?.[0]?.name ?? '').toUpperCase()} />
              </div>
            </div>
            <div className="px-5 py-2">
              <span
                className="fw-bolder text-dark fs-6 text-truncate"
                style={{
                  maxWidth: '100%',
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {showCompanyCode ? company?.code.toUpperCase() ?? '' : company?.name ?? ''}
              </span>
            </div>
            <Row className={`px-5 py-2`}>
              <Col>
                <CardDetail
                  IsInvestmentView={true}
                  title="Years of Operation"
                  showYearOfOperationIcon={showCardIcons}
                  value={company?.yearOfIncorporation ? timeAgo(company?.yearOfIncorporation) : '-'}
                />
              </Col>
            </Row>
            <Row className={`px-5 mb-2`}>
              <Col>
                <CardDetail
                  IsInvestmentView={true}
                  showLocationIcon={showCardIcons}
                  title="Location"
                  value={`${company?.country ? company?.country : '-'}, ${company?.region ? company?.region : '-'}`}
                />
              </Col>
            </Row>
            <Row className={`px-5 mb-2`}>
              <Col>
                <ProgressBar
                  value={completedSteps || 0}
                  total={6}
                  title="Onboarding Stage"
                  bold={true}
                />
              </Col>
            </Row>

            <Row className={`ps-7 ${includeFinancingAsk ? 'h-60px' : 'h-30px'}`}>
              {(company?.financingTypes ?? []).map((type: INameIdPair, index: number) => (
                <Col key={index} className="mb-2 flex-grow-0 px-1">
                  <CardBadge text={includeFinancingAsk ? `${type.name}` : type.name} />
                </Col>
              ))}
            </Row>
          </div>
        </Link>
      ) : (
        <div
          className={`card border ${isBorderWhite ? 'border-1 border-white' : 'border-2 border-gray-300'} mb-1 h-100 d-flex flex-column justify-content-between`}
          style={{cursor: 'default', backgroundColor: '#1E1E2D', color: 'white'}}
        >
          <Row className="py-4 px-5">
            <Col>
             <span
               className="fw-bolder fs-1 text-truncate"
               style={{
                 maxWidth: '100%', display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
               }}
             >
                {showCompanyCode ? company?.code.toUpperCase() ?? '' : company?.name ?? ''}
              </span>
            </Col>
            <Col className="text-truncate text-end" style={{
              maxWidth: '100%',
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
              <CardBadge text={(company?.sectors?.[0]?.name ?? '').toUpperCase()} />
            </Col>
          </Row>
          <Row className={`px-5 pb-3`}>
            <Col className="pe-2 text-start">
              <CardDetail
                IsInvestmentView={true}
                title={intl.formatMessage({id: 'CARD.LABEL.YEARSOFOPERATION', defaultMessage: 'Years of Operation'})}
                showYearOfOperationIcon={true}
                value={company?.yearOfIncorporation ? timeAgo(company?.yearOfIncorporation) : '-'}
              />
            </Col>
            <Col className="ps-2 text-end">
              <CardDetail
                IsInvestmentView={true}
                showLocationIcon={true}
                title={intl.formatMessage({id: 'CARD.LABEL.LOCATION', defaultMessage: 'Location'})}
                value={`${countryString}, ${regionString}`}
              />
            </Col>
          </Row>

          <Row className="px-5">
            <Col>
              <div style={{borderBottom: '1px solid', margin: '10px 0', color: '#D9D9D9'}}></div>
            </Col>
          </Row>

          <Row className={`px-5 mb-3`}>
            <Col>
              <span className="text-white fw-bold fs-9">About Company</span>
              <div
                className={`text-truncate-lines fw-normal  ${isExpanded ? '' : 'text-truncate-lines--collapsed'}`}
                style={{
                  WebkitLineClamp: isExpanded ? 'unset' : 4,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {summary}
              </div>
              {summary.length > 200 && (
                <a
                  href="#"
                  className="text-primary text-decoration-none"
                  onClick={(e) => {
                    e.preventDefault()
                    setIsExpanded(!isExpanded)
                  }}
                >
                  {isExpanded ? 'Read Less' : 'Read More'}
                </a>
              )}
            </Col>
          </Row>

          <Row className={`px-5 mb-3`}>
            <Col>
              <span className="text-white fw-bold fs-9">Ticket Size</span>
              <p
                className="fs-4">{currencies[companyFinancial?.currency ?? 0]} {companyFinancial?.ask.toLocaleString()}</p>
            </Col>
          </Row>

          <Row className={`ps-7 ${includeFinancingAsk ? 'h-40px' : 'h-40px'}`}>
            {(company?.financingTypes ?? []).map((type: INameIdPair, index: number) => (
              <Col key={index} className="mb-0 flex-grow-0 px-1">
                <CardBadge text={includeFinancingAsk ? `${type.name}` : type.name} />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  )
}