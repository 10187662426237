import {useState} from 'react'
import SettingDetailsHeader from '../shared/SettingDetailsHeader'
import SettingDetailsBody from './SettingDetailsBody'
import {
  useAddNewFinancingTypeMutation,
  useDeleteFinancingTypeMutation,
  useGetFinancingTypesQuery,
  useUpdateFinancingTypeMutation,
} from '../../companies/CompaniesApiSlice'
import {INameIdPair} from '../../shared/interfaces'
import Loading from '../../shared/components/Loading'
import {AddSettingItem} from '../Modals/AddSettingItem'
import {UpdateSettingItem} from '../Modals/UpdateSettingItem'
import {DeleteModal} from '../../shared/components/DeleteModal'


const FinancingTypesSettings = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedFinancingType, setSelectedFinancingType] = useState<INameIdPair | null>(null)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const {data: financingTypesState, isLoading} = useGetFinancingTypesQuery()
  const [addFinancingType, {isLoading: isCreatingFinancingType}] = useAddNewFinancingTypeMutation()
  const [updateFinancingType, {isLoading: isUpdatingFinancingType}] =
    useUpdateFinancingTypeMutation()
  const [deleteFinancingType, {isLoading: isDeletingFinancingType}] = useDeleteFinancingTypeMutation()

  const companyFinancingTypes = Object.values(financingTypesState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type),
  )
  const filteredFinancingTypes = companyFinancingTypes.filter((type) =>
    type.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )
  const handleCreateModal: () => void = () => {
    setShowCreateModal(!showCreateModal)
  }
  const handleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal)
  }
  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const handleSave = async (name: string) => {
    try {
      await addFinancingType({
        name,
      }).unwrap()
      handleCreateModal()
    } catch (error) {
    }
  }
  const handleUpdate = async (name: string) => {
    try {
      await updateFinancingType({
        name,
        id: selectedFinancingType?.id,
      }).unwrap()
      setSelectedFinancingType(null)
      handleUpdateModal()
    } catch (error) {
    }
  }
  const handleDelete = async () => {
    try {
      if (selectedFinancingType) {
        await deleteFinancingType(selectedFinancingType.id).unwrap()
      }
      setSelectedFinancingType(null)
      handleDeleteModal()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='card card-flush card-bordered card-60vh p-5'>
      <div className='mb-5'>
        <SettingDetailsHeader
          setSearchTerm={setSearchTerm}
          handleCreateModal={handleCreateModal}
          title={'Financing Types'}
          useModal={true}
        />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <SettingDetailsBody
          items={filteredFinancingTypes}
          setSelectedItem={setSelectedFinancingType}
          handleUpdateModal={handleUpdateModal}
          handleDeleteModal={handleDeleteModal}
        />
      )}
      <AddSettingItem
        showCreateModal={showCreateModal}
        handleCreateModal={handleCreateModal}
        handleSave={handleSave}
        isLoading={isCreatingFinancingType}
        section={'Financing Type'}
      />
      <UpdateSettingItem
        showUpdateModal={showUpdateModal}
        handleUpdateModal={handleUpdateModal}
        handleUpdate={handleUpdate}
        isLoading={isUpdatingFinancingType}
        section={'Financing Type'}
        item={selectedFinancingType}
      />
      <DeleteModal
        item={'Financing Type'}
        showDeleteModal={showDeleteModal}
        handleClose={handleDeleteModal}
        isLoading={isDeletingFinancingType}
        handleDelete={handleDelete}
      />
    </div>
  )
}

export default FinancingTypesSettings
