import React, {FC, useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_theme/helpers'
import {boolean, string} from 'yup'

type Props = {
  showCreateModal: boolean
  handleCreateModal: () => void
  handleSave: (name: string, description?: string) => void
  isLoading: boolean
  section: string
  handleCheckName?: (name: string) => Promise<void>
  isCheckingName?: boolean
  nameExists?: boolean | null
  errorMessage?: string | null
  setInputValue?: React.Dispatch<React.SetStateAction<string>>;
}
const InitialValues = {
  name: '',
  description: '',
}

export const AddSettingItem: React.FC<Props> = ({
  handleCreateModal,
  showCreateModal,
  isLoading,
  handleSave,
  section,
  handleCheckName,
  isCheckingName,
  nameExists,
  errorMessage,
  setInputValue

                                                }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`A ${section.toLowerCase()} name is required`),
    description: Yup.string().nullable(),
  })
  const [debouncedName, setDebouncedName] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (handleCheckName && debouncedName) {
        handleCheckName(debouncedName); // Check name availability
      }
    }, 500);

    return () => clearTimeout(timeoutId); // Cleanup timeout if the name changes again
  }, [debouncedName, handleCheckName]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const newName = e.target.value;
    setFieldValue('name', newName); // Update Formik's internal state immediately
    setDebouncedName(newName); // Update the debounced name after a delay
    if (setInputValue) {
      setInputValue(newName); // Optionally update an external state, if needed
    }
  };

  return (
    <>
      <Modal
        id='createSettingModal'
        aria-hidden={true}
        dialogClassName='h-auto me-5 w-350px'
        tabIndex={-1}
        show={showCreateModal}
        onHide={handleCreateModal}
        centered
      >
        <div className='modal-content' role='document'>
          <Formik
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSave(values.name, values.description)}
          >
            {({ errors, touched, isValid, values, setFieldValue })=> {
              // Sync Formik's value to inputValue state (if needed for debounce)

              return (
                <Form>
                  <div className='modal-header'>
                    <h5 className='modal-title ps-1' id='createFolderModalLabel'>
                      Add a New {section}
                    </h5>
                    <div
                      className='btn btn-sm btn-icon btn-active-color-danger'
                      data-bs-dismiss='modal'
                      onClick={handleCreateModal}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                  </div>
                  <div className='modal-body d-flex flex-column align-items-start justify-content-center'>
                    <label htmlFor='name' className='pb-2'>
                      {section} name
                    </label>
                    <Field
                      type='text'
                      name='name'
                      className='form-control'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleNameChange(e, setFieldValue); // Use the debounced handler
                      }}
                      value={values.name}
                    />
                    {/* Show status messages directly below the input field */}

                    {isCheckingName && <div className="text-warning mt-2">Checking name availability...</div>}

                    {nameExists === true && <div className="text-danger mt-2">Role name already exists</div>}

                    {nameExists === false && <div className="text-success mt-2">Role name is available</div>}

                    {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}

                    {touched?.name && <div className='text-danger mt-2'>{errors?.name}</div>}
                  </div>
                  {section === 'Role' && (
                    <div className='modal-body d-flex flex-column align-items-start justify-content-center'>
                      <label htmlFor='name' className='pb-2'>
                        Description
                      </label>
                      <Field as='textarea' name='description' className='form-control' />
                      {touched?.description && (
                        <div className='text-danger mt-2'>{errors?.description}</div>
                      )}
                    </div>
                  )}

                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-outline btn-sm btn-outline-danger btn-active-light-danger'
                      onClick={handleCreateModal}
                    >
                      Close
                    </button>
                    <button
                      type='submit'
                      className='btn btn-danger btn-sm'
                      disabled={!isValid || isLoading || nameExists === true}
                    >
                    <span className='indicator-label'>
                      {!isLoading ? (
                        'Create'
                      ) : (
                        <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                      )}
                    </span>
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}
