import { Link, useParams, useNavigate } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../_theme/helpers'
import clsx from 'clsx'
import { useAuth } from 'react-oidc-context' // Import useAuth
import { FC, useEffect, useState } from 'react' // Import useState
import '../../../../styles/custom.css'
import { OptionsPopup } from '../../shared/components/OptionsPopup'
import { useDispatch } from 'react-redux'
import { setCompanyFilters } from '../../../../data/redux/settingsReducer'
import { useLocation } from 'react-router'
import { useIsFinancier, useIsGrantProvider, useIsSuperUser } from '../../../../_theme/utils/authUtil'
import { useIntl } from 'react-intl'
import { Dropdown } from 'react-bootstrap'
import { useLang, Language, useUpdateLang } from '../../../../_theme/i18n/Blacki18n'

type MenuItem = {
  name: string
  image: string
  id?: string
}

type props = {
  isFinancierPage?: boolean
}

const FinancierPagesHeader: FC<props> = ({ isFinancierPage = true }) => {
  const toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
  const [isHovered, setIsHovered] = useState(false)
  const auth = useAuth()
  const dispatch = useDispatch()
  const user = auth.user
  const { financierId } = useParams<{ financierId: string }>() as { financierId: string }
  const location = useLocation()
  const currentLang = useLang()
  const updateLanguage = useUpdateLang()
  const getMenuNameFromPath = (path: string) => {
    const pathSegments = path.split('/')
    let staticWord = pathSegments[3]
    staticWord = staticWord ? `${staticWord.charAt(0).toUpperCase()}${staticWord.slice(1)}` : ''
    return staticWord
  }
  const [selectedItem, setSelectedItem] = useState(() => getMenuNameFromPath(location.pathname))
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  useEffect(() => {
    setSelectedItem(getMenuNameFromPath(location.pathname))
  }, [location.pathname])


  const isFinancier = useIsFinancier()
  const isGrantProvider = useIsGrantProvider()
  const isSuperUser = useIsSuperUser()
  const intl = useIntl()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const menuItems: MenuItem[] = [
    ...(isFinancier || isSuperUser ? [{
      name: intl.formatMessage({ id: 'MENU.MAIN.PIPELINE', defaultMessage: 'Pipeline' }),
      image: 'pipeline-menu-item-image',
      id: 'MENU.MAIN.PIPELINE',
    }] : []),
    ...(isGrantProvider || isSuperUser ? [{
      name: intl.formatMessage({ id: 'MENU.MAIN.CALLS', defaultMessage: 'Calls' }),
      image: 'calls-menu-item-image',
      id: 'MENU.MAIN.CALLS',
    }] : []),
  ]

  async function handleLogout() {
    try {
      localStorage.clear()
      await auth.signoutRedirect()
    } catch (error) {
      console.error('Error occurred during login: ', error)
    }
  }

  const handleMenuItemClick = (item: MenuItem) => {
    setSelectedItem(item.name)
    if (item.name === 'Pipeline') {
      dispatch(
        setCompanyFilters({
          countries: [],
          startYearOfOperation: '',
          endYearOfOperation: '',
          sectors: [],
          financingTypes: [],
          startInvestmentAsk: '',
          endInvestmentAsk: '',
          investmentAskCurrency: '',
        }),
      )
    }
  }

  const availableLanguages = [
    { code: 'en', label: formatMessage({ id: 'EN' }) },
    { code: 'fr', label: formatMessage({ id: 'FR' }) },
    { code: 'ar', label: formatMessage({ id: 'AR' }) },
  ]

  // --- Super User Dropdown Setup ---
  // Check if the current user is a Super User
  const roles = (auth?.user?.profile?.role as string[]) || []

  // State to track the selected super user view option
  const [superUserView, setSuperUserView] = useState<string>('all')

  useEffect(() => {
    const storedView = localStorage.getItem('superUserSelectedView')
    if (storedView) {
      setSuperUserView(storedView)
    }
  }, [])

  const handleSuperUserViewChange = (view: string) => {
    setSuperUserView(view)
    localStorage.setItem('superUserSelectedView', view)
    // Based on selection, trigger navigation to the corresponding route.
    // Adjust these paths as necessary for your application.
    if (view === 'financier') {
      // Example route for Financier view
      navigate(`/financier/${financierId}/pipeline`)
    } else if (view === 'investment-analyst') {
      // Example route for Investment Analyst view
      // navigate(`/companies?page=1&pageSize=6`)
      window.location.href = `/companies?page=1&pageSize=6`
    } else {
      // 'all' option, a default aggregated view or dashboard route
      console.log("All selected")
      // navigate(`/dashboard`)
    }
  }
  // --- End Super User Dropdown Setup ---

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <section className='fixed-top'>
      <div className='bg-white d-flex align-items-center justify-content-between px-2 px-md-6 py-3 py-md-1'>
        {/* Logo and hamburger section */}
        {isFinancierPage && (
          <div className='d-flex align-items-center gap-3'>
            <div className='d-md-none ps-2' onClick={toggleMobileMenu} style={{ cursor: 'pointer' }}>
              <i
                className={`fas ${isMobileMenuOpen ? 'fa-times' : 'fa-bars'}`}
                style={{ fontSize: '24px', color: '#000' }}
              ></i>
            </div>

            {isSuperUser ? (
              <Link
                to={`/pipeline`}
                onClick={() => setSelectedItem('Pipeline')}
              >
                <img
                  alt="Logo"
                  className="h-30px h-md-40px logo"
                  src={toAbsoluteUrl('/media/logos/logo-red-black.png')}
                />
              </Link>
            ) : (
              // Render an alternative element or nothing if not super user
              <Link
                to={`/financier/${financierId}/pipeline`}
                onClick={() => setSelectedItem('Pipeline')}
              >
                <img
                  alt='Logo'
                  className='h-30px h-md-40px logo'
                  src={toAbsoluteUrl('/media/logos/logo-red-black.png')}
                />
              </Link>
            )}


          </div>
        )}

        {/* Desktop menu */}
        {isFinancierPage && (
          <div className='navbar d-none d-md-block px-6'>
            <div className='d-flex justify-content-start align-items-center'>
              <ul className='navbar-nav d-flex flex-row mx-auto mb-2 mb-lg-0'>
                {menuItems.map((item) => (
                  <li className='financier-page nav-item me-3' key={item.name}>
                    {isSuperUser ? (
                      <Link
                      className={`nav-link fs-6 fw-medium text-dark py-1`}
                      to={`/${item.id === 'MENU.MAIN.CALLS'
                        ? 'calls'
                        : item.id === 'MENU.MAIN.PIPELINE'
                          ? 'pipeline'
                          : item.name.toLowerCase()
                        }`}
                    >
                      <button
                        key={item.id}
                        className={`btn btn-sm d-flex align-items-center justify-content-center text-hover-dark btn-active-secondary h-35px br-40px ${selectedItem === item.name ? 'bg-black text-white' : 'bg-white text-dark'
                          }`}
                        onClick={() => handleMenuItemClick(item)}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <img
                          src={toAbsoluteUrl(`/media/images/${item.image}.svg`)}
                          alt={item.name}
                          className={`h-17px w-20px ${selectedItem === item.name ? 'selected-financier-menu-tab' : ''
                            }`}
                          style={{
                            filter:
                              selectedItem === item.name
                                ? 'invert(100%) brightness(100%)'
                                : isHovered
                                  ? 'invert(0%) brightness(0%)'
                                  : 'invert(0%) brightness(0%)',
                          }}
                        />
                        <span className='ms-2'>{item.name}</span>
                      </button>
                    </Link>
                    ) : (
                      // Render an alternative element or nothing if not super user
                      <Link
                      className={`nav-link fs-6 fw-medium text-dark py-1`}
                      to={`/financier/${financierId}/${item.id === 'MENU.MAIN.CALLS'
                        ? 'calls'
                        : item.id === 'MENU.MAIN.PIPELINE'
                          ? 'pipeline'
                          : item.name.toLowerCase()
                        }`}
                    >
                      <button
                        key={item.id}
                        className={`btn btn-sm d-flex align-items-center justify-content-center text-hover-dark btn-active-secondary h-35px br-40px ${selectedItem === item.name ? 'bg-black text-white' : 'bg-white text-dark'
                          }`}
                        onClick={() => handleMenuItemClick(item)}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        <img
                          src={toAbsoluteUrl(`/media/images/${item.image}.svg`)}
                          alt={item.name}
                          className={`h-17px w-20px ${selectedItem === item.name ? 'selected-financier-menu-tab' : ''
                            }`}
                          style={{
                            filter:
                              selectedItem === item.name
                                ? 'invert(100%) brightness(100%)'
                                : isHovered
                                  ? 'invert(0%) brightness(0%)'
                                  : 'invert(0%) brightness(0%)',
                          }}
                        />
                        <span className='ms-2'>{item.name}</span>
                      </button>
                    </Link>
                    )}
                    
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {/* Desktop notifications and options */}
        {isFinancierPage && (
          <>
            <div className='d-none d-md-flex align-items-stretch flex-shrink-0'>
              {/* Render Super user dropdown and test switching*/}
              {isSuperUser && (
                        <div className='d-flex align-items-center'>
                        <Dropdown className="me-3">
                            <Dropdown.Toggle variant="outline-dark" id="superUserDropdown" className='hover-black-custom pe-0'>
                              {superUserView === 'all'
                                ? 'All Views'
                                : superUserView.charAt(0).toUpperCase() + superUserView.slice(1)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleSuperUserViewChange('financier')}>
                                Funders view
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleSuperUserViewChange('investment-analyst')}>
                                Investment Analyst
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
              {/* Notifications */}
              <div className='d-flex align-items-center'>
                <div className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}>
                  <span className='position-relative'>
                    <span className='fas fa-bell text-gray' style={{ fontSize: '24px' }}></span>
                    <span
                      className='position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-danger'
                      style={{ fontSize: '0.8em', padding: '0.25em 0.5em' }}
                    >
                      3
                    </span>
                  </span>
                </div>
              </div>

              {/* Language dropdown - show in all views */}
              <div className='d-flex align-items-center'>
                <Dropdown>
                  <Dropdown.Toggle
                    variant='outline-dark'
                    id='languageDropdown'
                    className='hover-black-custom pe-0'
                  >
                    <i className='text-dark bi bi-globe2 me-1'></i>
                    {currentLang.toUpperCase()}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {availableLanguages.map((lang) => (
                      <Dropdown.Item
                        key={lang.code}
                        onClick={() => updateLanguage(lang.code as Language)}
                      >
                        {lang.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Desktop options popup */}
              <OptionsPopup
                isFinancierHeader={true}
                handleLogout={handleLogout}
                includeFinancierHeaderOptions={true}
                isMobileView={false}
              />
            </div>

            {/* Mobile view - only language dropdown and options */}
            <div className='d-flex d-md-none align-items-center'>
              {/* Language dropdown */}
              <Dropdown>
                <Dropdown.Toggle
                  variant='outline-dark'
                  id='languageDropdown'
                  className='hover-black-custom pe-0'
                >
                  <i className='text-dark bi bi-globe2 me-1'></i>
                  {currentLang.toUpperCase()}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {availableLanguages.map((lang) => (
                    <Dropdown.Item
                      key={lang.code}
                      onClick={() => updateLanguage(lang.code as Language)}
                    >
                      {lang.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {/* Mobile options popup */}
              <OptionsPopup
                isFinancierHeader={true}
                handleLogout={handleLogout}
                includeFinancierHeaderOptions={true}
                isMobileView={true}
              />
            </div>
          </>
        )}

        {/* Mobile menu overlay */}
        {isFinancierPage && isMobileMenuOpen && (
          <div className='mobile-menu-container d-md-none'>
            <ul className='navbar-nav'>
              {/* Notifications menu item for mobile */}
              <li className='nav-item'>
                <Link className='nav-link' to='#'>
                  <div className='d-flex align-items-center'>
                    <span className='position-relative me-2'>
                      <span className='fas fa-bell text-gray'></span>
                      <span
                        className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'
                        style={{ fontSize: '0.8em', padding: '0.25em 0.5em' }}
                      >
                        3
                      </span>
                    </span>
                    <span>Notifications</span>
                  </div>
                </Link>
              </li>

              {/* Menu items */}
              {menuItems.map((item) => (
                <li className='nav-item' key={item.name}>
                  <Link
                    className='nav-link'
                    to={`/financier/${financierId}/${item.id === 'MENU.MAIN.CALLS'
                      ? 'calls'
                      : item.id === 'MENU.MAIN.PIPELINE'
                        ? 'pipeline'
                        : item.name.toLowerCase()
                      }`}
                    onClick={() => {
                      handleMenuItemClick(item)
                      setIsMobileMenuOpen(false)
                    }}
                  >
                    <div className='d-flex align-items-center'>
                      <img
                        src={toAbsoluteUrl(`/media/images/${item.image}.svg`)}
                        alt={item.name}
                        className='h-17px w-20px me-2'
                      />
                      <span>{item.name}</span>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  )
}

export default FinancierPagesHeader
