import React from 'react'
import {DeleteModal} from '../../shared/components/DeleteModal'
import {SidebarWrapper} from '../shared/SideWrapper'
import {useDeleteUserMutation} from '../../auth/AuthApiSlice'

type UserDetailsProps = {
  user: {
    id: string
    name: string
    email: string
    role: string
    phone: string
    section: string
  }
  show: boolean
  onClose: () => void
}

export const UserDetails = ({user, show, onClose}: UserDetailsProps) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const [deleteUser, {isLoading}] = useDeleteUserMutation()

  const handleDelete = async () => {
    try {
      await deleteUser(user.id).unwrap()
      setShowDeleteModal(false)
      onClose()
    } catch (error) {
      console.error('Failed to delete user:', error)
    }
  }

  return (
    <SidebarWrapper show={show} close={onClose} width={28}>
      <div className='container py-3'>
        {/* Profile Section */}
        <div className='d-flex align-items-center mb-3'>
          <div
            className='d-flex justify-content-center align-items-center rounded-circle bg-danger text-white'
            style={{width: '60px', height: '60px', flexShrink: 0}}
          >
            <i className='bi bi-person-fill fs-2 text-white'></i>
          </div>
          <div className='ms-3'>
            <h5 className='fw-bold mb-1'>{user.name}</h5>
            <small className='text-muted d-block'>{user.role}</small>
            <p className='mb-0 text-muted'>
              <i className='bi bi-telephone-fill me-1'></i> {user.phone}
            </p>
          </div>
        </div>

        {/* Action Buttons */}
        <div className='d-flex gap-2 mb-3'>
          <button className='btn btn-outline-danger'>Change Password</button>
          <button className='btn btn-danger btn-sm'>
            <i className='bi bi-pencil-fill me-1'></i> Edit
          </button>
          <button className='btn btn-danger btn-sm' onClick={() => setShowDeleteModal(true)}>
            <i className='bi bi-trash-fill'></i>
          </button>
        </div>

        {/* Additional Details Card */}
        <div className='card border-0 bg-light p-3'>
          <div className='row'>
            <div className='col-12 col-md-6 mb-2 mb-md-0'>
              <small className='text-muted'>Email</small>
              <p className='mb-0'>{user.email}</p>
            </div>
            <div className='col-12 col-md-6'>
              <small className='text-muted'>Section</small>
              <p className='mb-0'>{user.section}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
        isLoading={isLoading}
        item='User'
      />
    </SidebarWrapper>
  )
}
