import {FC, useEffect, useRef, useState} from 'react'
import 'flatpickr/dist/flatpickr.min.css'
import {Formik, Form} from 'formik'
import {Stepper, StepperComponent} from '../../shared/components/stepper'
import {CreatePartnerFormSteps} from '../../../../data/constants'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import {useNavigate} from 'react-router'
import {useAddNewDocumentMutation} from '../../documents/DocumentsApiSlice'
import {ICreateDocument} from '../../documents'
import {useGetFinancingTypesQuery} from '../../companies/CompaniesApiSlice'
import {FetchBaseQueryError} from '@reduxjs/toolkit/query'
import LineSpan from '../../shared/components/LineSpan'
import {countries} from '../../../../utils/countries'
import CreatePartnerGeneralInformation from '../components/CreatePartnerGeneralInformation'
import {CreatePartnerStrategicFocus} from '../components/CreatePartnerStrategicFocus'
import {CreatePartnerProgramDetails} from '../components/CreatePartnerProgramDetails'
import {ICreateDevelopmentPartner} from '../interfaces'
import {createDevelopmentPartnerSchema} from '../constants/CreateDevelopmentPartnerSchema'
import {useAddNewDevelopmentPartnerMutation} from '../DevelopmentPartnersApiSlice'

const CreateDevelopmentPartner: FC = () => {
  const inits: ICreateDevelopmentPartner = {
    generalInformation: {
      organisationName: '',
      primaryContact: {
        name: '',
        title: '',
        email: '',
        phone: '',
      },
      address: {
        physicalAddress: '',
        mailingAddress: '',
      },
      organisationType: 1,
    },
    strategicFocus: {
      prioritySectors: [],
      priorityRegionsOfOperation: [],
      priorityDistrictsOfOperation: [],
      areasOfSupport: 0,
    },
    programDetails: {
      fundingWindow: {
        totalBudget: 0,
        timelineForDisbursement: '',
      },
      expectedKPIsAndDeliverables: '',
      isOpenToPartnership: false,
    },
  }
  const navigate = useNavigate()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState<any>()
  const [initValues] = useState<ICreateDevelopmentPartner>(inits)
  const [submitting, setSubmitting] = useState(false)
  const [countryCode, setCountryCode] = useState<string>('+256')
  const [addNewDevelopmentPartner, {isLoading}] = useAddNewDevelopmentPartnerMutation()
  const [createDocument] = useAddNewDocumentMutation()
  const [fileObject, setFileObject] = useState<ICreateDocument | null>(null)
  const {data: financingTypesState} = useGetFinancingTypesQuery()

  const financingTypeSelection = Object.values(financingTypesState?.entities || {})
  const stepName: any = (step: number) => {
    const steps = ['generalInformation', 'strategicFocus', 'ProgramDetails', 'reviewAndSubmit']
    return steps[step - 1]
  }


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)

    if (stepper.current) {
      const step = stepName(stepper.current.currentStepIndex)

      setCurrentSchema(createDevelopmentPartnerSchema.pick([step]))
    }
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitting(false)

    const currentStepIndex = stepper.current.currentStepIndex
    if (currentStepIndex !== undefined && currentStepIndex > 0) {
      const step = stepName(currentStepIndex - 1)

      stepper.current.goPrev()

      setCurrentSchema(createDevelopmentPartnerSchema.pick([step]))
    }
  }

  const submitStep = async (values: ICreateDevelopmentPartner) => {
    let financierTypesObjects: {financier_id: string; financing_type_id: string}[] = []
    if (!stepper.current) {
      return
    }
    const step = stepName(stepper.current.currentStepIndex + 1)

    setCurrentSchema(createDevelopmentPartnerSchema.pick([step]))

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      setSubmitting(true)

      let logoId = null

      try {
        if (fileObject) {
          const documents = [fileObject]
          const payload = {files: documents}
          const response = await createDocument(payload).unwrap()
          logoId = response[0].id
          setFileObject(null)
        }

        const newPartner = await addNewDevelopmentPartner({
          name: values.generalInformation.organisationName,
          email: values.generalInformation.address.mailingAddress,
          location: values.generalInformation.address.physicalAddress,
          organizationType: values.generalInformation.organisationType,
          regions: values.strategicFocus.priorityRegionsOfOperation,
          districts: values.strategicFocus.priorityDistrictsOfOperation,
          areaOfSupport: values.strategicFocus.areasOfSupport,
          coFunding: values.programDetails.isOpenToPartnership,
          primaryPhoneNumber: values.generalInformation.primaryContact.phone,
          contactPersonTitle: values.generalInformation.primaryContact.title,
          contactPersonEmail: values.generalInformation.primaryContact.email,
          alternatePhoneNumber: values.generalInformation.primaryContact.phone,
          totalBudget: values.programDetails.fundingWindow.totalBudget.toString(),
          disbursementTimeline: new Date(values.programDetails.fundingWindow.timelineForDisbursement).toISOString(),
          sectors: values.strategicFocus.prioritySectors,
        }).unwrap()

        navigate('/development/partners')
      } catch (error) {
        const errorCode = (error as FetchBaseQueryError)?.status?.toString()
        navigate(`/error/${errorCode}`)
      }
    }
  }


  const formRef = useRef<any>()

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const areaOfSupportMap = {
    1: 'Grant Funding',
    2: 'Technical Assistance',
    3: 'Capacity Building',
    4: 'CoInvestment',
  }
  const organizationTypeMap = {
    1: 'Government',
    2: 'NGO',
    3: 'Foundation',
    4: 'Multilateral Organization',
    5: 'Other',
  }

  return (
    <>
      <Toolbar page="Add a New Development Partner"></Toolbar>
      <div className="container-fluid p-0">
        <div className="card">
          <div
            ref={stepperRef}
            className="row stepper stepper-pills stepper-row"
            id="kt_modal_create_service_provider_stepper"
          >
            <Formik
              innerRef={formRef}
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {({
                  values,
                  touched,
                  errors,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                  isValid,
                  dirty,
                }) => (
                <Form className="form w-100" noValidate id="kt_modal_create_form">
                  <div className="card-body d-flex flex-column px-lg-20 py-lg-15">
                    <h2 className="f-s ps-xl-4 ps-xxl-7 ps-md-3">Create a Development Partner</h2>
                    <div
                      className="stepper-nav d-flex justify-content-center align-items-center h-100px py-lg-0 px-xl-0 px-xxl-0">
                      <Stepper items={CreatePartnerFormSteps} />
                    </div>
                    <div className="flex-column-fluid py-lg-8 px-xl-4 px-md-4 px-xxl-8">
                      {/* General Information */}

                      <CreatePartnerGeneralInformation
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFileObject={setFileObject}
                        setFieldValue={setFieldValue}
                      />

                      {/*Business Operations */}

                      <CreatePartnerStrategicFocus
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />

                      {/* contact person */}

                      <CreatePartnerProgramDetails
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />

                      {/* submission */}
                      <div data-kt-stepper-element="content">
                        <div className="fv-row row mb-10 p-0">
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Name</h3>
                            <p className="fs-7 text-justify">{values.generalInformation?.organisationName ||
                              <LineSpan />}</p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Primary Contact Person Name </h3>
                            <p className="fs-7 text-justify">{values.generalInformation?.primaryContact.name ||
                              <LineSpan />}</p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Primary Contact Person Title </h3>
                            <p className="fs-7 text-justify">{values.generalInformation?.primaryContact.title ||
                              <LineSpan />}</p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Primary Contact Person Email</h3>
                            <p className="fs-7 text-justify">{values.generalInformation?.primaryContact.email ||
                              <LineSpan />}</p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Primary Contact Person Phone </h3>
                            <p className="fs-7 text-justify">{values.generalInformation?.primaryContact.phone ||
                              <LineSpan />}</p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Company Physical Address</h3>
                            <p className="fs-7 text-justify">{values.generalInformation?.address.physicalAddress ||
                              <LineSpan />}</p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Company Mailing Address</h3>
                            <p className="fs-7 text-justify">{values.generalInformation?.address.mailingAddress ||
                              <LineSpan />}</p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Type Of Organization</h3>
                            <p
                              className="fs-7 text-justify">{organizationTypeMap[values.generalInformation?.organisationType as keyof typeof organizationTypeMap] ||
                              <LineSpan />}</p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Priority Regions Of Operation</h3>
                            <p className="fs-7 text-justify">
                              {Array.isArray(values.strategicFocus?.priorityRegionsOfOperation)
                                ? values.strategicFocus.priorityRegionsOfOperation.map((country: any) => country.name).join(', ')
                                : <LineSpan />}
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Priority Sectors</h3>
                            <p className="fs-7 text-justify">{Array.isArray(values.strategicFocus?.prioritySectors)
                              ? values.strategicFocus.prioritySectors.map((sector: any) => sector.name).join(', ')
                              : <LineSpan />}</p>
                          </div>

                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Areas of Support</h3>
                            <p className="fs-7 text-justify">
                              {areaOfSupportMap[values.strategicFocus?.areasOfSupport as keyof typeof areaOfSupportMap] ||
                                <LineSpan />}</p>
                          </div>

                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Total Budget</h3>
                            <p className="fs-7 text-justify">{values.programDetails?.fundingWindow.totalBudget ||
                              <LineSpan />}</p>
                          </div>

                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Timeline for Disbursement</h3>
                            <p>
                              {values.programDetails?.fundingWindow.timelineForDisbursement || <LineSpan />}
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Expected KPIs</h3>
                            <p>
                              {values.programDetails?.expectedKPIsAndDeliverables || <LineSpan />}
                            </p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Open to co-funding or partnerships?</h3>
                            <p>
                              {values.programDetails?.isOpenToPartnership ? 'Yes' : 'No'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10 pt-0 px-xl-4 px-md-4 px-xxl-8">
                      <div className="col-lg-6">
                        <button
                          type="button"
                          data-kt-stepper-action="previous"
                          onClick={prevStep}
                          className="btn btn-lg btn-outline btn-outline-secondary"
                          style={{
                            color:
                              stepper?.current?.currentStepIndex &&
                              stepper.current.currentStepIndex > 0
                                ? 'black'
                                : 'gray',
                          }}
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-lg-6 text-md-end">
                        <button
                          type="submit"
                          className={`btn btn-lg ${
                            stepper?.current?.currentStepIndex ===
                            stepper?.current?.totatStepsNumber!
                              ? 'btn-danger'
                              : 'btn-outline btn-outline-secondary'
                          }`}
                          style={{
                            color:
                              stepper?.current?.currentStepIndex ===
                              stepper?.current?.totatStepsNumber!
                                ? 'white'
                                : isValid && dirty
                                  ? 'black'
                                  : 'gray',
                          }}
                        >
                          <span className="indicator-label">
                            {!submitting && (
                              <>
                                {stepper?.current?.currentStepIndex ===
                                stepper?.current?.totatStepsNumber!
                                  ? 'Submit'
                                  : 'Next'}
                              </>
                            )}
                            {submitting && (
                              <>
                                <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                              </>
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}
export {CreateDevelopmentPartner}
