import React from 'react'
import {Routes, Route, Navigate} from 'react-router'
import {
  Dashboard,
  FinancierPagesWrapper,
  Calls,
  Deals,
  Settings,
  Pipeline,
} from '../features/financiers/pages'
import CandidatesDetailPage from '../features/financiers/pages/CandidatesDetailPage'
import CallsDetailsPage from '../features/financiers/pages/CallsDetails'

const SuperUserRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<FinancierPagesWrapper />}>
        <Route index path="pipeline" element={<Pipeline />} />
        <Route path="calls" element={<Calls />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="calls/:callId" element={<CallsDetailsPage />} />
        <Route path="calls/:callId/applications/:stage/:applicationIndex" element={<CandidatesDetailPage />} />
        <Route path="deals" element={<Deals />} />
        <Route path="settings" element={<Settings />} />
      </Route>
      <Route path="/" element={<Navigate to={`/pipeline`} />} />
      <Route path="auth/*" element={<Navigate to={`/pipeline`} />} />
    </Routes>
  )
}

export default SuperUserRoutes