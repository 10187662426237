import * as Yup from 'yup'

export const createDevelopmentPartnerSchema =
  Yup.object({
    generalInformation: Yup.object().shape({
      organisationName: Yup.string().required('Organisation Name is required'),
      primaryContact: Yup.object({
        name: Yup.string().required('Contact Name is required'),
        title: Yup.string().required('Contact Title is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        phone: Yup.string().required('Phone number is required'),
      }).required(),
      address: Yup.object({
        physicalAddress: Yup.string().required('Physical Address is required'),
        mailingAddress: Yup.string().required('Mailing Address is required'),
      }).required(),
      organisationType: Yup.number().required('Organisation Type is required'),
    }),

    strategicFocus: Yup.object().shape({
      prioritySectors: Yup.array().min(1, 'At least one sector must be selected').required('Priority Sectors are required'),
      priorityRegionsOfOperation: Yup.array().min(1, 'Priority Regions are required').required('Priority Regions are required'),
      priorityDistrictsOfOperation: Yup.array().min(1, 'Priority Districts are required').required('Priority Districts are required'),
      areasOfSupport: Yup.number().required('Areas of Support are required'),
    }),

    programDetails: Yup.object().shape({
      fundingWindow: Yup.object({
        totalBudget: Yup.number()
          .required('Total Budget is required')
          .positive('Budget must be a positive number')
          .min(1, 'Budget must be greater than 0'),
        timelineForDisbursement: Yup.string().required('Timeline for Disbursement is required'),
      }).required(),
      expectedKPIsAndDeliverables: Yup.string().required('Expected KPIs and Deliverables are required'),
      isOpenToPartnership: Yup.boolean().required('Partnership decision is required'),
    }),
  })

