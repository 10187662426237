import React, {useMemo, useCallback} from 'react'
import {FormattedMessage} from 'react-intl'

interface Props {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  entriesCount: number;
  entriesPerPage: number;
  isPipeline?: boolean;
}

const Pagination: React.FC<Props> = ({currentPage, onPageChange, entriesCount, entriesPerPage, isPipeline = false}) => {
  const totalPages = Math.ceil(entriesCount / entriesPerPage)
  const handlePageChange = useCallback((page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page)
    }
  }, [onPageChange, totalPages])

  const startIndex = (currentPage - 1) * entriesPerPage + 1
  const endIndex = Math.min(startIndex + entriesPerPage - 1, entriesCount)

  const maxVisiblePages = 5

  const renderPageNumbers = useMemo(() => {
    const pageNumbers = []
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1)
    let endPage = startPage + maxVisiblePages - 1

    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(endPage - maxVisiblePages + 1, 1)
    }

    // Always show the first page
    if (startPage > 1) {
      pageNumbers.push(
        <button
          key={1}
          aria-label="Go to first page"
          className={`btn ${currentPage === 1 ? 'btn-danger btn-sm active' : 'btn-light'}`}
          onClick={() => handlePageChange(1)}
        >
          1
        </button>,
      )

      if (startPage > 2) {
        pageNumbers.push(<span key="left-ellipsis" className="btn">...</span>)
      }
    }

    // Display the range of pages
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          aria-label={`Go to page ${i}`}
          className={`btn ${currentPage === i ? 'btn-danger btn-sm active' : 'btn-light'}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>,
      )
    }

    // Always show the last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<span key="right-ellipsis" className="btn">...</span>)
      }

      pageNumbers.push(
        <button
          key={totalPages}
          aria-label="Go to last page"
          className={`btn ${currentPage === totalPages ? 'btn-danger btn-sm active' : 'btn-light'}`}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </button>,
      )
    }

    return pageNumbers
  }, [currentPage, totalPages, handlePageChange])

  return (
    isPipeline ?
      (<div className="d-flex justify-content-between align-items-center">
        <div className="fs-5 fw-bold mb-2 text-white">
          <FormattedMessage id={'PAGINATION.LABEL.SHOWING'} defaultMessage={'Showing'} /> {startIndex} <FormattedMessage
          id={'PAGINATION.LABEL.TO'} defaultMessage={'to'} />{endIndex} <FormattedMessage id={'PAGINATION.LABEL.OF'}
                                                                                          defaultMessage={'of'} /> {entriesCount}
          <FormattedMessage id={'PAGINATION.LABEL.ENTRIES'} defaultMessage={' entries'} />
        </div>
        <div>
          <button className="btn btn-link fs-2 me-2" onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}>
            <span className="fas fa-angle-left"></span>
          </button>
          {renderPageNumbers}
          <button className="btn btn-link fs-2 ms-2" onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}>
            <span className="fas fa-angle-right"></span>
          </button>
        </div>
      </div>) :
      (
        <div className="d-flex justify-content-between align-items-center">
          <div className="fs-5 fw-bold mb-2">
            Showing {startIndex} to {endIndex} of {entriesCount} entries
          </div>
          <div>
            <button className="btn btn-light" onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}>
              <span className="fas fa-angle-left"></span>
            </button>
            {renderPageNumbers}
            <button className="btn btn-light" onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}>
              <span className="fas fa-angle-right"></span>
            </button>
          </div>
        </div>
      )
  )
}

export default Pagination
