import {Field} from 'formik'
import {countries} from '../../../../utils/countries'
import React from 'react'
import {MultiSelect} from '../../shared/components/MultiSelect'

const CreatePartnerGeneralInformation = ({values, errors, touched, setFileObject, setFieldValue}: any) => {
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    const file = event.target?.files ? event.target.files[0] : null
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        const base64Content = base64String.split(',')[1]
        const fileObj = {
          fileName: file.name,
          fileType: file.type,
          fileContent: base64Content,
          dateUploaded: new Date().toISOString(),
          fileSize: file.size,
        }
        setFileObject(fileObj)
        setFieldValue('generalInformation.logo', file)
      }
      reader.readAsDataURL(file)
    } else {
      setFieldValue('generalInformation.logo', null)
    }
  }
  const mappedCountries = countries.map((country) => ({
    id: country.value,
    name: country.label,
  }))
  const financierType = [
    {id: 'finance', name: 'Financier'},
    {id: 'grant', name: 'Grant Provider'},
  ]
  return (
    <div className="current " data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Organization Name`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.organisationName"
              placeholder=""
            />

            {touched?.generalInformation?.organisationName && (
              <div className="text-danger mt-2">{errors?.generalInformation?.organisationName}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Primary Contact Person's Name`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.primaryContact.name"
              placeholder=""
            />

            {touched?.generalInformation?.primaryContact?.name && (
              <div className="text-danger mt-2">{errors?.generalInformation?.primaryContact?.name}</div>
            )}
          </div>
        </div>
        <div className=" fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Primary Contact Person's Title`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.primaryContact.title"
              placeholder=""
            />

            {touched?.generalInformation?.primaryContact?.title && (
              <div className="text-danger mt-2">{errors?.generalInformation?.primaryContact?.title}</div>
            )}
          </div>

          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Primary Contact Person's Email`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.primaryContact.email"
              placeholder=""
            />

            {touched?.generalInformation?.primaryContact?.email && (
              <div className="text-danger mt-2">{errors?.generalInformation?.primaryContact?.email}</div>
            )}
          </div>
        </div>
        <div className="fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Primary Contact Person's Phone`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.primaryContact.phone"
              placeholder=""
            />

            {touched?.generalInformation?.primaryContact?.phone && (
              <div className="text-danger mt-2">{errors?.generalInformation?.primaryContact?.phone}</div>
            )}
          </div>

          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Physical Address`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.address.physicalAddress"
              placeholder=""
            />

            {touched?.generalInformation?.address?.physicalAddress && (
              <div className="text-danger mt-2">{errors?.generalInformation?.address?.physicalAddress}</div>
            )}
          </div>
        </div>
        <div className="fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Mailing Address`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="generalInformation.address.mailingAddress"
              placeholder=""
            />

            {touched?.generalInformation?.address?.mailingAddress && (
              <div className="text-danger mt-2">{errors?.generalInformation?.address?.mailingAddress}</div>
            )}
          </div>

          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">{`Type Of Organization`}</span>
            </label>
            <Field
              as="select"
              className="form-select py-lg-5"
              name="generalInformation.organisationType"
              placeholder=""
              onChange={(e: any) => {
                setFieldValue('generalInformation.organisationType', Number(e.target.value))
              }}
              value={values.generalInformation?.organisationType || ''}
            >
              <option value="" disabled hidden>
                Select Organization Type
              </option>
              <option value="1">Government</option>
              <option value="2">NGO</option>
              <option value="3">Foundation</option>
              <option value="4">Multilateral Organization</option>
              <option value="5">Other (Specify)</option>
            </Field>

            {touched?.generalInformation?.organisationType && (
              <div className="text-danger mt-2">{errors?.generalInformation?.organisationType}</div>
            )}
          </div>

        </div>
      </div>
    </div>
  )
}

export default CreatePartnerGeneralInformation
