import React from 'react'
import {Outlet} from 'react-router-dom'
import FinancierPagesHeader from './FinancierPagesHeader'
import circularImage from '../../../../images/circular.png'

interface Props {
  imageUrl?: string
}

const FinancierPagesWrapper = ({imageUrl = ''}: Props) => {

  return (
    <div className="financier-pages-wrapper h-100vh">
      <FinancierPagesHeader />
      <div className="mt-54px px-8 flex-grow-1" style={{
        backgroundColor: '#28283D', backgroundImage: `url(${imageUrl}), url(${circularImage})`,
        backgroundSize: 'cover, 14% 36%',
        backgroundPosition: 'center, top right',
        backgroundRepeat: 'no-repeat, no-repeat',
        position: 'relative',
      }}>
        <Outlet />
      </div>
    </div>
  )
}

export {FinancierPagesWrapper}
