import {FC} from 'react'

type CardDetailProps = {
  title: string
  value: string
  showYearOfOperationIcon?: boolean
  showLocationIcon?: boolean
  IsCall?: boolean
  IsInvestmentView?: boolean
}

const CardDetail: FC<CardDetailProps> = ({
                                           title,
                                           value,
                                           showLocationIcon = false,
                                           showYearOfOperationIcon = false,
                                           IsCall = false,
                                           IsInvestmentView = false,
                                         }) => {
  return (
    <div
      className={` ${IsInvestmentView ? 'px-0' : 'border border-dashed rounded min-w-125px py-3 px-2'} ${IsCall ? 'border-gray-400' : 'border-gray-600'}`}>
      <div
        className={` ${IsCall ? 'fs-8' : 'fs-9'} ${IsInvestmentView ? 'fw-bold' : 'fw-bold text-gray-500'}`}>
        {showYearOfOperationIcon &&
          <i className={`fas fa-calendar-alt me-2 ${IsInvestmentView ? 'text-white' : ''}`}></i>}
        {showLocationIcon && <i className={`fas fa-map-marker-alt me-2 ${IsInvestmentView ? 'text-white' : ''}`}></i>}
        {title}
      </div>
      <div className={`${IsInvestmentView ? 'fs-4 text-truncate' : 'fs-8 text-gray-800 fw-bold text-truncate'}`}
           style={{
             maxWidth: '150%',
             display: 'block',
             whiteSpace: 'nowrap',
             overflow: 'hidden',
             textOverflow: 'ellipsis',
           }}>{value}</div>
    </div>
  )
}

export default CardDetail
