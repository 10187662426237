import {IStepperItem} from '../app/features/shared/interfaces/IStepperItem'

export const AUTH_TOKEN_KEY = '__black__backoffice__token'
export const AUTH_USER_KEY = '__black__backoffice__user'
export const AUTH_ID_KEY = '__black__backoffice__id'

export const env = {
  Auth: process.env.REACT_APP_BLACK_AUTHORITY,
  ClientId: process.env.REACT_APP_BLACK_CLIENT_ID,
  RedirectUri: process.env.REACT_APP_BLACK_REDIRECT_URI,
  PostLogoutRedirectUri: process.env.REACT_APP_BLACK_POST_LOGOUT_REDIRECT_URI,
  Nin: process.env.REACT_APP_Nin,
  Company: process.env.REACT_APP_COMPANY_API_URL,
  ServiceProviders: process.env.REACT_APP_SERVICE_PROVIDERS_API_URL,
  Financiers: process.env.REACT_APP_FINANCIERS_API_URL,
  Documents: process.env.REACT_APP_DOCUMENTS_API_URL,
  Users: process.env.REACT_APP_BLACK_AUTHORITY_URI,
  DevelopmentPartners: process.env.REACT_APP_DEVELOPMENT_PARTNERS_API_URL,
}

export const remoteRoutes = {
  webSockets: env.Nin + '/hubs/workflowHub',
  authServer: env.Auth,
}


export const ServiceProviderCreateFormSteps: Array<IStepperItem> = [
  {
    step: 1,
    title: 'General Information',
    description: 'Service Provider Details',
    isCurrent: true,
  },
  {
    step: 2,
    title: 'Business Operations',
    description: 'Service Provider Location and Contact Info',
    isCurrent: false,
  },
  {
    step: 3,
    title: 'Contact Information',
    description: 'Service Provider Financials',
    isCurrent: false,
  },
  {
    step: 4,
    title: 'Review and Submit',
    description: 'Service Provider Contact Person Details',
    isCurrent: false,
  },
]
export const CreateFinancierFormSteps: Array<IStepperItem> = [
  {
    step: 1,
    title: 'General Information',
    description: 'Financier Details',
    isCurrent: true,
  },

  {
    step: 2,
    title: 'Financial Information',
    description: 'Financial Information',
    isCurrent: false,
  },
  {
    step: 3,
    title: 'Contact Person',
    description: 'Financier Contact Person Details',
    isCurrent: false,
  },

  {
    step: 4,
    title: 'Other Details',
    description: 'Financier Other Details',
    isCurrent: false,
  },

  {
    step: 5,
    title: 'Review and Submit',
    description: 'Financier Review and Submit',
    isCurrent: false,
  },
]

export const CreatePartnerFormSteps: Array<IStepperItem> = [
  {
    step: 1,
    title: 'General Information',
    description: 'Financier Details',
    isCurrent: true,
  },

  {
    step: 2,
    title: 'Strategic Focus',
    description: 'Financial Information',
    isCurrent: false,


  },
  {
    step: 3,
    title: 'Program Details',
    description: 'Financier Contact Person Details',
    isCurrent: false,
  },

  {
    step: 4,
    title: 'Review & Submit',
    description: 'Financier Review and Submit',
    isCurrent: false,
  },
]

