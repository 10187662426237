import React, {useState} from 'react'
import {IUser} from '../interfaces'
import SettingDetailsHeader from '../shared/SettingDetailsHeader'
import Pagination from '../../shared/components/Pagination'
import {UserDetails} from '../Modals/UserDetails'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useGetUsersQuery} from '../../auth/AuthApiSlice'
import useAuthToken from '../../../../hooks/useAuthToken'

export const UserSettings = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
  const [showDetails, setShowDetails] = useState(false)
  const [searchParams] = useSearchParams()
  const routePage = searchParams.get('page') || '1'
  const [currentPage, setCurrentPage] = useState<number>(parseInt(routePage))
  const navigate = useNavigate()
  const entriesPerPage = 9

  const isTokenAvailable = useAuthToken()

  const {data, error, isLoading, isError} = useGetUsersQuery(
    {page: currentPage, pageSize: entriesPerPage},
    {skip: !isTokenAvailable},
  )

  const users = data?.entities ? (Object.values(data.entities) as IUser[]) : []
  const filteredUsers = users.filter(user =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  )
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    navigate(`?page=${page}&pageSize=${entriesPerPage}`)
  }
  const handleUserClick = (user: IUser) => {
    setSelectedUser(user)
    setShowDetails(true)
  }
  const closeDetails = () => {
    setShowDetails(false)
    setSelectedUser(null)
  }
  const handleCreateModal = () => navigate('/settings/add')

  if (isError) {
    const errorMessage =
      'status' in error
        ? `Error code: ${error.status}`
        : error?.message || 'An unknown error occurred'
    return (
      <div className="d-flex justify-content-center align-items-center" style={{height: '60vh'}}>
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      </div>
    )
  }

  return (
    <div className="card card-flush card-bordered card-80vh p-5">
      <div className="mb-5">
        <SettingDetailsHeader
          setSearchTerm={setSearchTerm}
          handleCreateModal={handleCreateModal}
          title="Users"
          useModal={false}
        />
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{height: '60vh'}}>
          <div className="spinner-border" role="status"></div>
        </div>
      ) : (
        <div className="row">
          {filteredUsers.map((user) => (
            <div
              key={user.id}
              className="col-md-4 col-lg-4 col-xxl-4"
              onClick={() => handleUserClick(user)}
              style={{cursor: 'pointer'}}
            >
              <div className="card border border-2 border-gray-300 mb-5">
                <div className="card-body d-flex flex-center flex-column py-2 px-5">
                  <div className="symbol symbol-65px symbol-circle mb-5">
                    <span className="symbol-label bg-danger text-inverse-primary fw-bold">
                      <i className="bi bi-person fs-1 text-inverse-primary"></i>
                    </span>
                  </div>
                  <span className="fs-4 text-gray-800 fw-bold mb-0 fs-5">
                    {user.firstName} {user.lastName}
                  </span>
                  <div className="fw-semibold text-gray-500 fs-7">{user.role ?? 'N/A'}</div>
                  <div className="fw-semibold text-gray-500 mb-6 fs-9">{user.email}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {data?.paginationMetadata && users.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(
            data.paginationMetadata.totalCount / data.paginationMetadata.pageSize,
          )}
          onPageChange={handlePageChange}
          entriesCount={data.paginationMetadata.totalCount}
          entriesPerPage={data.paginationMetadata.pageSize}
        />
      )}

      {selectedUser && (
        <UserDetails
          user={{
            id: selectedUser.id,
            name: `${selectedUser.firstName} ${selectedUser.lastName}`,
            email: selectedUser.email ?? '',
            role: selectedUser.role ?? 'N/A',
            phone: selectedUser.phoneNumber,
            section: selectedUser.section ?? 'N/A',
          }}
          show={showDetails}
          onClose={closeDetails}
        />
      )}
    </div>
  )
}
