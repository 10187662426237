import React, {FC, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useAddNewUserMutation} from '../../auth/AuthApiSlice'
import {useGetFinanciersQuery} from '../../financiers/FinanciersApiSlice'
import {useGetCompaniesQuery, useGetRolesQuery} from '../../companies/CompaniesApiSlice'
import {useGetServiceProvidersQuery} from '../../serviceProviders/ServiceProvidersApiSlice'
import {IServiceProviderDetails} from '../../serviceProviders/interfaces'
import {ICompanyDetails} from '../../companies/interfaces'
import {IFinancier} from '../../financiers/interfaces'
import {useNavigate} from 'react-router-dom'
import {IRole} from '../../companies/interfaces/IRole'
import {useGetDevelopmentPartnersQuery} from '../../developmentPartners/DevelopmentPartnersApiSlice'
import {IDevelopmentPartnerDetails} from '../../developmentPartners/interfaces/IDevelopmentPartnerDetails'

interface AdditionalClaims {
  role?: string
  financier_id?: string
  company_id?: string
  serviceProvider_id?: string
  developmentPartner_id?: string
}

const createUserSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
  additionalClaims: Yup.object()
    .shape({
      role: Yup.string().optional(),
      financier_id: Yup.string().optional(),
      company_id: Yup.string().optional(),
      serviceProvider_id: Yup.string().optional(),
    })
    .optional(),
  isSelfOnboarding: Yup.boolean(),
})


const sections = ['Financier', 'Service Provider', 'Company', 'Development Partner']

export const CreateUser: FC = () => {
  const [addNewUser, {isLoading, error}] = useAddNewUserMutation()
  const [selectedSection, setSelectedSection] = useState('')
  const [selectedRole, setSelectedRole] = useState('')
  const {data: financiers} = useGetFinanciersQuery({
    page: 1,
    pageSize: 100,
  })
  const {data: companies} = useGetCompaniesQuery({
    page: 1,
    pageSize: 100,
  })
  const {data: serviceProviders} = useGetServiceProvidersQuery({
    page: 1,
    pageSize: 100,
  })
  const {data: developmentPartners} = useGetDevelopmentPartnersQuery({
    page: 1,
    pageSize: 100,
  })
  const navigate = useNavigate()
  const {data: rolesState} = useGetRolesQuery()

  const roles = Object.values(rolesState?.entities.undefined || {}).filter((role): role is IRole =>
    Boolean(role),
  )

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      additionalClaims: {} as AdditionalClaims,
      isSelfOnboarding: false,
    },
    validationSchema: createUserSchema,
    onSubmit: async (values, {resetForm}) => {
      try {
        const transformedValues = {
          ...values,
          additionalClaims: [...Object.entries(values.additionalClaims).map(([key, value]) => ({
            key,
            value: value as string,
          })),
            {
              key: 'client',
              value: 'Black',
            }],
        }

        await addNewUser(transformedValues).unwrap()
        resetForm()
        navigate('/settings')
      } catch (err) {
      }
    },
  })

  const handleSectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const section = e.target.value
    setSelectedSection(section)
    formik.setFieldValue('section', section)
    formik.setFieldValue('additionalClaims', {})
  }

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const role = e.target.value
    setSelectedRole(role)
    formik.setFieldValue('role', role)
    formik.setFieldValue('additionalClaims', {role: role})
  }

  const handleDynamicSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value
    let claimKey = ''

    // Set the correct claim key based on the section
    switch (selectedSection) {
      case 'Service Provider':
        claimKey = 'serviceProvider_id'
        break
      case 'Company':
        claimKey = 'company_id'
        break
      case 'Financier':
        claimKey = 'financier_id'
        break
      case 'Development Partner':
        claimKey = 'developmentPartner_id'
        break
      default:
        claimKey = ''
    }

    if (claimKey) {
      formik.setFieldValue('additionalClaims', {
        ...formik.values.additionalClaims,
        role: selectedRole,
        [claimKey]: selectedValue,
      })
    }
  }

  const handleCancel = () => {
    navigate('/settings')
  }

  return (
    <div className="card card-flush card-bordered card-80vh p-3">
      <div className="container mt-5">
        <div className="row">
          <div className="col mb-3">
            <h2>Create a User</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="row fv mb-4">
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...formik.getFieldProps('firstName')}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="text-danger">{formik.errors.firstName}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...formik.getFieldProps('lastName')}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className="text-danger">{formik.errors.lastName}</div>
                  )}
                </div>
              </div>

              <div className="row fv mb-4">
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...formik.getFieldProps('phoneNumber')}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <div className="text-danger">{formik.errors.phoneNumber}</div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input type="email" className="form-control" {...formik.getFieldProps('email')} />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-danger">{formik.errors.email}</div>
                  )}
                </div>
              </div>

              <div className="row fv mb-4">
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Role <span className="text-danger">*</span>
                  </label>
                  <select className="form-select" onChange={handleRoleChange}>
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.name}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Section <span className="text-danger">*</span>
                  </label>
                  <select className="form-select" onChange={handleSectionChange}>
                    <option value="">Select Section</option>
                    {sections.map((section) => (
                      <option key={section} value={section}>
                        {section}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Conditional Dropdown based on Selected Section */}
              {selectedSection === 'Financier' && (
                <div className="row fv mb-4">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Select Financier</label>
                    <select
                      className="form-control"
                      onChange={handleDynamicSelect}
                      value={formik.values.additionalClaims?.financier_id || ''}
                    >
                      <option value="">Select Financier</option>
                      {financiers?.entities?.entities &&
                        Object.values(financiers.entities.entities)
                          .filter(
                            (financier): financier is IFinancier =>
                              financier !== undefined && financier !== null,
                          )
                          .map((financier) => (
                            <option key={financier.id} value={financier.id}>
                              {financier.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              )}

              {selectedSection === 'Service Provider' && (
                <div className="row fv mb-4">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Select Service Provider</label>
                    <select
                      className="form-control"
                      onChange={handleDynamicSelect}
                      value={formik.values.additionalClaims?.serviceProvider_id || ''}
                    >
                      <option value="">Select Service Provider</option>
                      {serviceProviders?.entities &&
                        Object.values(serviceProviders.entities)
                          .filter(
                            (provider): provider is IServiceProviderDetails =>
                              provider !== undefined && provider !== null,
                          )
                          .map((provider) => (
                            <option key={provider.id} value={provider.id}>
                              {provider.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              )}

              {selectedSection === 'Company' && (
                <div className="row fv mb-4">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Select Company</label>
                    <select
                      className="form-control"
                      onChange={handleDynamicSelect}
                      value={formik.values.additionalClaims?.company_id || ''}
                    >
                      <option value="">Select Company</option>
                      {companies?.entities &&
                        Object.values(companies.entities)
                          .filter(
                            (company): company is ICompanyDetails =>
                              company !== undefined && company !== null,
                          )
                          .map((company) => (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              )}

              {selectedSection === 'Development Partner' && (
                <div className="row fv mb-4">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Select Development Partner</label>
                    <select
                      className="form-control"
                      onChange={handleDynamicSelect}
                      value={formik.values.additionalClaims?.developmentPartner_id || ''}
                    >
                      <option value="">Select Development Partner</option>
                      {developmentPartners?.entities?.entities &&
                        Object.values(developmentPartners.entities.entities)
                          .filter(
                            (partner): partner is IDevelopmentPartnerDetails =>
                              partner !== undefined && partner !== null,
                          )
                          .map((partner) => (
                            <option key={partner.id} value={partner.id}>
                              {partner.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="row fv mb-4">
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="text-danger">{formik.errors.password}</div>
                  )}
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    {...formik.getFieldProps('confirmPassword')}
                  />
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className="text-danger">{formik.errors.confirmPassword}</div>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-danger" disabled={isLoading}>
                  {isLoading ? 'Adding New User...' : 'Add New User'}
                </button>
              </div>
              {error && <div className="text-danger mt-2">Error: {error.toString()}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
