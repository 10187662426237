import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_theme/helpers/components/KTSVG'
import 'flatpickr/dist/flatpickr.min.css'
import * as Yup from 'yup'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../data/redux/Store'
import {useSelector} from 'react-redux'
import {
  createDevelopmentPartnersSelectors,
  useGetDevelopmentPartnersQuery,
  useUpdateDevelopmentPartnerMutation,
} from '../DevelopmentPartnersApiSlice'
import {useGetCompanyDetailsQuery} from '../../companies/CompaniesApiSlice'

interface Props {
  showModal: boolean
  handleClose: () => void
  currentPage: number
  pageSize: number
}

export const UpdateDevelopmentPartnerGeneralInformation: FC<Props> = ({
                                                                        showModal,
                                                                        handleClose,
                                                                        currentPage,
                                                                        pageSize,
                                                                      }) => {
  const [updateDevelopmentPartner, {isLoading}] = useUpdateDevelopmentPartnerMutation()
  const {id} = useParams<{id: string}>() as {id: string}
  const {selectById: selectFinancierById} = createDevelopmentPartnersSelectors({
    page: currentPage,
    pageSize,
  })

  const developmentPartnersDetails = useSelector((state: RootState) => selectFinancierById(state, id))

  const initialValues = {
    name: developmentPartnersDetails?.name ?? '',
    contactPersonName: developmentPartnersDetails?.contactPersonName ?? '',
    contactPersonTitle: developmentPartnersDetails?.contactPersonTitle ?? '',
    contactPersonEmail: developmentPartnersDetails?.contactPersonEmail ?? '',
    primaryPhoneNumber: developmentPartnersDetails?.primaryPhoneNumber ?? '',
    location: developmentPartnersDetails?.location ?? '',
    email: developmentPartnersDetails?.email ?? '',
    organizationType: developmentPartnersDetails?.organizationType ?? '',
  }

  const handleSave = async (values: any) => {
    const updatedDevelopmentPartnerData = {
      ...developmentPartnersDetails,
      id: developmentPartnersDetails?.id,
      name: values.name,
      contactPersonName: values.contactPersonName,
      contactPersonTitle: values.contactPersonTitle,
      contactPersonEmail: values.contactPersonEmail,
      primaryPhoneNumber: values.primaryPhoneNumber,
      location: values.location,
      email: values.email,
      organizationType: values.organizationType,
    }


    try {
      await updateDevelopmentPartner(updatedDevelopmentPartnerData).unwrap()
      handleClose()
    } catch (error) {
      handleClose()
    }
  }

  const DevelopmentPartnerGeneralInformationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').label('Name'),
    contactPersonName: Yup.string().required('Contact Person Name is required').label('Contact Person Name'),
    contactPersonTitle: Yup.string().nullable().label('Contact Person Title'),
    contactPersonEmail: Yup.string().email('Invalid email').nullable().label('Contact Person Email'),
    primaryPhoneNumber: Yup.string().nullable().label('Primary Phone Number'),
    location: Yup.string().nullable().label('Location'),
    email: Yup.string().email('Invalid email').nullable().label('Email'),
    organizationType: Yup.string().nullable().label('Organization Type'),
  })

  return (
    <Modal
      id="kt_edit_business_operation"
      aria-hidden={true}
      dialogClassName="h-auto"
      show={showModal}
      onHide={handleClose}
    >
      <div className="modal-content w-700px" role="document">
        <Formik
          initialValues={initialValues}
          validationSchema={DevelopmentPartnerGeneralInformationSchema}
          onSubmit={handleSave}
        >
          {({values, errors, touched, isValid}) => (
            <Form>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title ps-1">Edit Development Partner General Information</h5>
                  <div
                    className="btn btn-sm btn-icon btn-active-color-danger"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                  </div>
                </div>
                <div className="modal-body px-9">
                  <div className="row fv mb-4">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="name"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark required"
                      >
                        Organisation Name
                      </label>
                      <Field
                        type="text"
                        name="name"
                        className="form-control form-control-sm"
                      />
                      {touched.name && <div className="text-danger mt-2">{errors.name}</div>}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="contactPersonName"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark required"
                      >
                        Primary Contact Person Name
                      </label>
                      <Field
                        type="text"
                        name="contactPersonName"
                        className="form-control form-control-sm"
                      />
                      {touched.contactPersonName && (
                        <div className="text-danger mt-2">{errors.contactPersonName}</div>
                      )}
                    </div>
                  </div>

                  <div className="row fv mb-4">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="contactPersonTitle"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                      >
                        Primary Contact Person Title
                      </label>
                      <Field
                        type="text"
                        name="contactPersonTitle"
                        className="form-control form-control-sm"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="contactPersonEmail"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                      >
                        Primary Contact Person Email
                      </label>
                      <Field
                        type="email"
                        name="contactPersonEmail"
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>

                  <div className="row fv mb-4">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="primaryPhoneNumber"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                      >
                        Primary Contact Person Phone
                      </label>
                      <Field
                        type="text"
                        name="primaryPhoneNumber"
                        className="form-control form-control-sm"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="location"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                      >
                        Physical Address
                      </label>
                      <Field
                        type="text"
                        name="location"
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>

                  <div className="row fv mb-4">
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="email"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                      >
                        Mailing Address
                      </label>
                      <Field
                        type="email"
                        name="email"
                        className="form-control form-control-sm"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="organizationType"
                        className="d-flex align-items-center fs-5 fw-bold mb-2 text-dark"
                      >
                        Type of Organization
                      </label>
                      <Field
                        as="select"
                        name="organizationType"
                        className="form-select form-select-sm"
                      >
                        <option value="">Select Organization Type</option>
                        <option value="1">Government</option>
                        <option value="2">NGO</option>
                        <option value="3">Foundation</option>
                        <option value="4">Multilateral Organization</option>
                        <option value="5">Other</option>
                      </Field>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary text-dark"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    disabled={!isValid || isLoading}
                  >
                    {!isLoading ? 'Save' :
                      <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
