import {useAuth} from 'react-oidc-context'

export function useIsGlobalAdmin(): boolean {
  const auth = useAuth()
  const user = auth.user
  const roles = user?.profile?.role
  return Array.isArray(roles)
    ? roles.includes('Global Administrator')
    : roles === 'Global Administrator'
}

export function useIsFinancier(): boolean {
  const auth = useAuth()
  const user = auth.user
  const roles = user?.profile?.role
  return Array.isArray(roles) ? roles.includes('Financier') : roles === 'Financier'
}

export function useIsGrantProvider(): boolean {
  const auth = useAuth()
  const user = auth.user
  const roles = user?.profile?.role
  return Array.isArray(roles) ? roles.includes('Grant Provider') : roles === 'Grant Provider'
}

export function useIsSuperUser(): boolean {
  const auth = useAuth()
  const user = auth.user
  const roles = user?.profile?.role
  return Array.isArray(roles) ? roles.includes('Super User') : roles === 'Super User'
}

export function useIsInvestmentTrainee(): boolean {
  const auth = useAuth()
  const user = auth.user
  const roles = user?.profile?.role
  return Array.isArray(roles) ? roles.includes('Investment Trainee') : roles === 'Investment Trainee'}

export function useIsInvestmentManager(): boolean {
  const auth = useAuth()
  const user = auth.user
  const roles = user?.profile?.role
  return Array.isArray(roles) ? roles.includes('Investment Manager') : roles === 'Investment Manager'}

export function useIsInvestmentAnalyst(): boolean {
  const auth = useAuth()
  const user = auth.user
  const roles = user?.profile?.role
  return Array.isArray(roles) ? roles.includes('Investment Analyst') : roles === 'Investment Analyst'}
