import {Field, FormikValues} from 'formik'
import {useGetFinancingTypesQuery, useGetSectorsQuery} from '../../companies/CompaniesApiSlice'
import React from 'react'
import {MultiSelect} from '../../shared/components/MultiSelect'
import {INameIdPair} from '../../shared/interfaces'
import {countries} from '../../../../utils/countries'

export const CreatePartnerStrategicFocus = ({
                                              values,
                                              errors,
                                              touched,
                                              handleChange,
                                              setFieldValue,
                                              setFieldTouched,
                                            }: FormikValues) => {
  const {data: sectorsState} = useGetSectorsQuery()

  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    Boolean,
  ) as INameIdPair[]

  const mappedCountries = countries.map((country) => ({
    id: country.value,
    name: country.label,
  }))

  return (
    <div data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span className="required">Sector</span>
            </label>

            <MultiSelect
              selectedValues={values.strategicFocus.prioritySectors}
              options={companySectorsList}
              setOption={(selectedOptions) => setFieldValue('strategicFocus.prioritySectors', selectedOptions)}
              size="lg"
            />
            {touched?.strategicFocus?.prioritySectors && (
              <div className="text-danger mt-2">{errors?.strategicFocus?.prioritySectors}</div>
            )}
          </div>

          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">Priority Regions Of Operation</span>
            </label>

            <MultiSelect
              options={mappedCountries}
              selectedValues={values.strategicFocus.priorityRegionsOfOperation}
              setOption={(selectedOptions) =>
                setFieldValue('strategicFocus.priorityRegionsOfOperation', selectedOptions)
              }
              size="lg"
            />

            {touched?.strategicFocus?.priorityRegionsOfOperation && (
              <div className="text-danger mt-2">{errors?.strategicFocus?.priorityRegionsOfOperation}</div>
            )}
          </div>

        </div>
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">Priority Districts Of Operation</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="strategicFocus.priorityDistrictsOfOperation"
              placeholder="Enter districts, separated by commas"
              value={values.strategicFocus?.priorityDistrictsOfOperation?.join(', ') || ''}
              onChange={(e: any) => {
                const value = e.target.value
                const districtsArray = value.split(',').map((item: any) => item.trim())
                setFieldValue('strategicFocus.priorityDistrictsOfOperation', districtsArray)
              }}
            />

            {touched?.strategicFocus?.priorityDistrictsOfOperation && (
              <div className="text-danger mt-2">{errors?.strategicFocus?.priorityDistrictsOfOperation}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span className="required">Area Of Support</span>
            </label>
            <Field
              as="select"
              className="form-select py-lg-5"
              name="strategicFocus.areasOfSupport"
              placeholder=""
              onChange={(e: any) => {
                setFieldValue('strategicFocus.areasOfSupport', Number(e.target.value))
              }}
              value={values.strategicFocus?.areasOfSupport || ''}
            >
              <option value="" disabled hidden>
                Select Area of Support
              </option>
              <option value="1">Grant Funding</option>
              <option value="2">Technical Assistance</option>
              <option value="3">Capacity Building</option>
              <option value="4">Co-investment</option>
            </Field>

            {touched?.strategicFocus?.areasOfSupport && (
              <div className="text-danger mt-2">{errors?.strategicFocus?.areasOfSupport}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
