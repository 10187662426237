import React, {FC} from 'react'
import {useParams} from 'react-router-dom'
import {createDevelopmentPartnersSelectors, useGetDevelopmentPartnersQuery} from '../DevelopmentPartnersApiSlice'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import LineSpan from '../../shared/components/LineSpan'

type PartnerProps = {
  currentPage: number
  pageSize: number
}

const DevelopmentPartnerEngagement: FC<PartnerProps> = ({
                                                          currentPage,
                                                          pageSize,
                                                        }) => {
  const {id} = useParams<{id: string}>() as {id: string}
  useGetDevelopmentPartnersQuery({page: currentPage, pageSize: pageSize})
  const {selectById: selectFinancierById} = createDevelopmentPartnersSelectors({
    page: currentPage,
    pageSize,
  })
  const developmentPartnerDetails = useSelector((state: RootState) => selectFinancierById(state, id))
  return (
    <div className="position-relative">
      <div className="card card-flush mb-4">
        <div className="card-body p-0 px-6 py-5">
          <div className="row">
            <div className="col-md-6">
              <h3 className="fs-8">Open to Co-Funding/Partnership?</h3>
              <p className="fs-6">{
                developmentPartnerDetails?.coFunding !== undefined
                  ? developmentPartnerDetails.coFunding
                    ? 'Yes'
                    : 'No'
                  : <LineSpan />
              }</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DevelopmentPartnerEngagement