import clsx from 'clsx'
import React, { FC, useState, useEffect } from 'react'
import { HeaderUserMenu } from '../index'
import { useLayout } from '../../core'
import { useAuth } from 'react-oidc-context' // Import useAuth
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const auth = useAuth()
  const user = auth.user

  const navigate = useNavigate()

  // --- Super User Dropdown Setup ---
  // Check if the current user is a Super User
  const roles = (auth?.user?.profile?.role as string[]) || []
  const isSuperUser = roles.includes('Super User')

  // State to track the selected super user view option
  const [superUserView, setSuperUserView] = useState<string>('investment-analyst')

  useEffect(() => {
    const storedView = localStorage.getItem('superUserSelectedView')
    if (storedView) {
      setSuperUserView(storedView)
    }
  }, [])

  const handleSuperUserViewChange = (view: string) => {
    setSuperUserView(view)
    localStorage.setItem('superUserSelectedView', view)
    if (view === 'financier') {
      window.location.href = `/pipeline`
    } else if (view === 'investment-analyst') {
      // Example route for Investment Analyst view
      navigate(`/companies`)
    } else {
      // 'all' option, a default aggregated view or dashboard route
      console.log("All selected")

    }
  }
  // --- End Super User Dropdown Setup ---

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Begin :: Alert/ Notification */}
      <div className={clsx('d-flex align-items-center')}>
        <div className={'d-flex align-items-center'}>
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <span className='position-relative'>
              <span className='fas fa-bell' style={{ fontSize: '24px', color: 'grey' }}></span>
              <span
                className='position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-danger'
                style={{ fontSize: '0.8em', padding: '0.25em 0.5em' }}
              >
                3
              </span>
            </span>
          </div>
        </div>
      </div>
      {/* Welcome::User */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/*dropdown component*/}
        {isSuperUser && (
          <div className='d-flex align-items-center'>
            <Dropdown className="me-3">
              <Dropdown.Toggle variant="outline-dark" id="superUserDropdown" className='hover-black-custom pe-0'>
                {superUserView === 'all'
                  ? 'All Views'
                  : superUserView.charAt(0).toUpperCase() + superUserView.slice(1)}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleSuperUserViewChange('financier')}>
                  Funders view
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSuperUserViewChange('investment-analyst')}>
                  Investment Analyst
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        <div className='mx-2'>
          <strong>Welcome</strong>, {user?.profile?.given_name}
        </div>{' '}

      </div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <span className='fas fa-user-circle' style={{ fontSize: '24px' }}></span>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  )
}

export { Topbar }

