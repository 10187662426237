import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router'
import CardBadge from '../../shared/components/CardBadge'
import { IFinancierCall } from '../interfaces'
import { printDateWithOrdinal, timeAgo } from '../../../../utils/dateHelpers'
import { stripHTML } from '../../../../utils/stringHelpers'
import { Col, Row } from 'react-bootstrap'
import CardDetail from '../../shared/components/CardDetail'
import { FormattedMessage, useIntl } from 'react-intl'
import { useIsSuperUser } from '../../../../_theme/utils/authUtil'

interface CallCardProps {
  call: IFinancierCall
}

const CallsCard: React.FC<CallCardProps> = ({ call }) => {
  const { financierId } = useParams<{ financierId: string }>()
  const maxLength = 200
  const plaintext = stripHTML(call?.description)
  const displayText =
    plaintext.length > maxLength ? `${plaintext.slice(0, maxLength)}...` : plaintext
  const callAmount = `${call?.callAmount ? call.callAmount.toLocaleString() : 'NOT SPECIFIED'}`
  const intl = useIntl()
  const isSuperUser = useIsSuperUser()

  return (
    <>
      {isSuperUser ? (
        <Link
        to={`/calls/${call?.id}`}
        className="text-decoration-none text-dark w-100"
      >
        <div className="card border-0 border-gray-300 mb-4 position-relative px-3 px-md-6 pt-4 pt-md-5">
          <span
            className="position-absolute top-0 end-0 bg-black text-white px-2 px-md-3 py-1 rounded-start-0 rounded-top fs-7 fs-md-custom">
            <FormattedMessage id={'CARD.LABEL.UPLOADED'} defaultMessage={'Uploaded'} /> {timeAgo(call?.dateUploaded)}
          </span>
          <div className="d-flex flex-column justify-content-between gap-3 gap-md-4">
            <h5 className="fw-bold fs-5 fs-md-4 pt-4 pt-md-5 mb-0">{call?.name ?? ''}</h5>
            <p className="text-dark custom-line-spacing fs-7 my-0 line-clamp-3">{displayText}</p>
            <Row className="px-0 gx-3">
              <Col xs={12} md={6} className="pe-md-2 mb-3 mb-md-0">
                <CardDetail
                  title={intl.formatMessage({id: 'CARD.LABEL.DEADLINE', defaultMessage: 'Deadline'})}
                  showYearOfOperationIcon={true}
                  value={call?.deadline ? printDateWithOrdinal(call?.deadline) : '-'}
                  IsCall={true}
                />
              </Col>
              <Col xs={12} md={6} className="ps-md-2">
                <CardDetail
                  title={intl.formatMessage({id: 'CARD.LABEL.LOCATION', defaultMessage: 'Location Scope'})}
                  showLocationIcon={true}
                  value={
                    call?.country?.length
                      ? call.country
                        .map((country) => {
                          return `${country.name}`
                        })
                        .join(', ')
                      : '-'
                  }
                  IsCall={true}
                />
              </Col>
            </Row>
  
            <div className="d-flex flex-wrap justify-content-start align-items-center gap-2 py-2 py-md-3">
              <CardBadge
                text={callAmount}
                isAmount={true}
              />
              {call?.sectors.map((sector: string, index: number) => (
                <CardBadge text={sector.toUpperCase()} key={index} />
              ))}
            </div>
          </div>
        </div>
      </Link>
      ) : (
        // Render an alternative element or nothing if not super user
        <Link
          to={`/financier/${financierId}/calls/${call?.id}`}
          className="text-decoration-none text-dark w-100"
        >
          <div className="card border-0 border-gray-300 mb-4 position-relative px-3 px-md-6 pt-4 pt-md-5">
            <span
              className="position-absolute top-0 end-0 bg-black text-white px-2 px-md-3 py-1 rounded-start-0 rounded-top fs-7 fs-md-custom">
              <FormattedMessage id={'CARD.LABEL.UPLOADED'} defaultMessage={'Uploaded'} /> {timeAgo(call?.dateUploaded)}
            </span>
            <div className="d-flex flex-column justify-content-between gap-3 gap-md-4">
              <h5 className="fw-bold fs-5 fs-md-4 pt-4 pt-md-5 mb-0">{call?.name ?? ''}</h5>
              <p className="text-dark custom-line-spacing fs-7 my-0 line-clamp-3">{displayText}</p>
              <Row className="px-0 gx-3">
                <Col xs={12} md={6} className="pe-md-2 mb-3 mb-md-0">
                  <CardDetail
                    title={intl.formatMessage({ id: 'CARD.LABEL.DEADLINE', defaultMessage: 'Deadline' })}
                    showYearOfOperationIcon={true}
                    value={call?.deadline ? printDateWithOrdinal(call?.deadline) : '-'}
                    IsCall={true}
                  />
                </Col>
                <Col xs={12} md={6} className="ps-md-2">
                  <CardDetail
                    title={intl.formatMessage({ id: 'CARD.LABEL.LOCATION', defaultMessage: 'Location Scope' })}
                    showLocationIcon={true}
                    value={
                      call?.country?.length
                        ? call.country
                          .map((country) => {
                            return `${country.name}`
                          })
                          .join(', ')
                        : '-'
                    }
                    IsCall={true}
                  />
                </Col>
              </Row>

              <div className="d-flex flex-wrap justify-content-start align-items-center gap-2 py-2 py-md-3">
                <CardBadge
                  text={callAmount}
                  isAmount={true}
                />
                {call?.sectors.map((sector: string, index: number) => (
                  <CardBadge text={sector.toUpperCase()} key={index} />
                ))}
              </div>
            </div>
          </div>
        </Link>
      )}
    </>

  )
}

export { CallsCard }
