import React, {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {getInitials} from '../../../../utils/stringHelpers'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {Col, Row} from 'react-bootstrap'
import CardDetail from '../../shared/components/CardDetail'
import {IListPageFilters} from '../../shared/interfaces/IListPageFilters'
import {ratings} from '../constants/Ratings'
import {createDevelopmentPartnersSelectors} from '../DevelopmentPartnersApiSlice'
import IdenticonAvatar from '../../shared/components/IdenticonAvatar'
import CardBadge from '../../shared/components/CardBadge'

type FinancierCardProps = {
  id: string
  page: number
  pageSize: number
  searchTerm: string
  filters: IListPageFilters
}

const areaOfSupportMap = {
  1: 'Grant Funding',
  2: 'Technical Assistance',
  3: 'Capacity Building',
  4: 'CoInvestment',
}

const organizationTypeMap = {
  1: 'Government',
  2: 'NGO',
  3: 'Foundation',
  4: 'Multilateral Organization',
  5: 'Other',
}

export const DevelopmentPartnerCard: FC<FinancierCardProps> = ({
                                                                 id,
                                                                 page,
                                                                 pageSize,
                                                                 searchTerm,
                                                                 filters,
                                                               }) => {
  const navigate = useNavigate()
  const {selectById: selectFinancierById} = createDevelopmentPartnersSelectors({
    page,
    pageSize,
    searchTerm,
    ...filters,
  })
  const developmentPartner = useSelector((state: RootState) => selectFinancierById(state, id))

  const developmentPartnerInitials = getInitials(developmentPartner?.name, 3)

  const handleNavigate = () => {
    navigate(`/development/partners/${developmentPartner?.id}`, {
      state: {currentPage: page, pageSize},
    })
  }

  const areaOfSupportString =
    developmentPartner?.areaOfSupport !== undefined
      ? areaOfSupportMap[developmentPartner.areaOfSupport as keyof typeof areaOfSupportMap]
      : 'Unknown'

  const organizationTypeString = developmentPartner?.organizationType !== undefined
    ? organizationTypeMap[developmentPartner.organizationType as keyof typeof organizationTypeMap]
    : 'Unknown'

  return (
    <div className="col-md-6 col-lg-4 col-sm-12">
      <div
        onClick={handleNavigate}
        className="card border border-2 border-gray-300 mb-5 d-flex flex-column justify-content-evenly min-height-160px"
      >
        <div className="d-flex border-0 pt-3 px-5 justify-content-between align-items-center">
          <IdenticonAvatar identifier={developmentPartnerInitials} size={50} />
          <div className="d-flex py-4">
            <CardBadge
              text={organizationTypeString}
            />
          </div>
        </div>
        <div className="px-5 py-2">
          <div className="d-flex justify-content-between align-items-center">
            <span
              className="fw-bolder text-dark fs-4 text-truncate"
              style={{
                maxWidth: '200%',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {developmentPartner?.name}
            </span>
          </div>
        </div>

        <Row className="px-5 pb-2">
          <Col className="pe-2">
            <CardDetail
              IsInvestmentView={true}
              title="Total Budget"
              value={`${developmentPartner?.totalBudget}`}
            />
          </Col>
        </Row>

        <div className="px-5 d-flex flex-column align-items-left h-50px">
          <span className="fs-9 fw-bold text-gray-500 w-50">Area of Support</span>
          <span className="text-gray-800 fs-7">
            <span className="fs-5 text-gray-800 fw-bold w-50">
              {areaOfSupportString}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}
