import {FormikValues, Field} from 'formik'

export const CreateContactInformation = ({touched, errors}: any) => {
  return (
    <div data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span className="required">Name of Contact Person</span>
            </label>

            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="contactPerson.name"
              placeholder=""
            />
            {touched?.contactPerson?.name && (
              <div className="text-danger mt-2">
                {errors?.contactPerson?.name}
              </div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Email Address</span>
            </label>

            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="contactPerson.email"
              placeholder=""
            />
            {touched?.contactPerson?.email && (
              <div className="text-danger mt-2">
                {errors?.contactPerson?.email}
              </div>
            )}
          </div>
        </div>
        <div className="fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Phone Number</span>
            </label>

            <div className="input-group mb-3 col-lg-6 ">
              <button
                className="btn btn-secondary dropdown-toggle rounded-end-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                +256{' '}
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Flag_of_Uganda.svg/1200px-Flag_of_Uganda.svg.png"
                  style={{width: '20px', height: '15px'}}
                  alt="arrow down"
                />
              </button>

              <Field
                type="text"
                className="form-control form-control-lg py-lg-5"
                name="contactPerson.phoneNumber"
                placeholder=""
              />
            </div>
            {touched?.contactPerson?.phoneNumber && (
              <div className="text-danger mt-2">
                {errors?.contactPerson?.phoneNumber}
              </div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Address Line</span>
            </label>

            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="contactPerson.address"
              placeholder=""
            />
            {touched?.contactPerson?.address && (
              <div className="text-danger mt-2">
                {errors?.contactPerson?.address}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
