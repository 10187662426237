type IProps = {
  width?: number
  title?: string
  children: any
  show?: boolean
  close?: () => void
  height?: number
  marginTop?: number
}

export const SidebarWrapper = ({
                                 children,
                                 title,
                                 show = false,
                                 width = 25,
                                 close,
                                 height = 100,
                                 marginTop = 0,
                               }: IProps) => {
  return (
    <div
      className={`details-pane w-${width} shadow-lg ${
        show ? 'visible' : 'hidden'
      } h-${height} mt-${marginTop}`}
    >
      <div className="d-flex w-100 justify-content-between">
        <h6>{title}</h6>
        <div>
          <button className="btn-close" onClick={close} aria-label="Close"></button>
        </div>
      </div>
      <div className="w-100 py-3">{children}</div>
    </div>
  )
}
