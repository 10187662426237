import {useParams} from 'react-router-dom'
import EditButton from '../../shared/components/EditButton'
import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import LineSpan from '../../shared/components/LineSpan'
import {
  createDevelopmentPartnersSelectors,
  useGetDevelopmentPartnersQuery,
} from '../DevelopmentPartnersApiSlice'
import {EditDevelopmentPartnerStrategicFocus} from '../modals/EditDevelopmentPartnerStrategicFocus'

type DevelopmentPartnerStrategicFocusProps = {
  handleClose: () => void
  showModal: boolean
  currentPage: number
  pageSize: number
}

const DevelopmentPartnerStrategicFocus: FC<DevelopmentPartnerStrategicFocusProps> = ({
                                                                                       handleClose,
                                                                                       showModal,
                                                                                       currentPage,
                                                                                       pageSize,
                                                                                     }) => {
  const {id} = useParams<{id: string}>() as {id: string}
  useGetDevelopmentPartnersQuery({page: currentPage, pageSize: pageSize})
  const {selectById: selectFinancierById} = createDevelopmentPartnersSelectors({
    page: currentPage,
    pageSize,
  })
  const developmentPartnerDetails = useSelector((state: RootState) => selectFinancierById(state, id))
  const areaOfSupportMap = {
    1: 'Grant Funding',
    2: 'Technical Assistance',
    3: 'Capacity Building',
    4: 'CoInvestment',
  }
  const areaOfSupportString =
    developmentPartnerDetails?.areaOfSupport !== undefined
      ? areaOfSupportMap[developmentPartnerDetails.areaOfSupport as keyof typeof areaOfSupportMap]
      : 'Unknown'
  return (
    <div className="position-relative">
      <div className="card card-flush mb-4">
        <div className="card-body p-0 px-6 py-5">

          <div className="row">
            <div className="col-md-6">
              <h3 className="fs-8">Sectors of Interest</h3>
              <p className="fs-6">{
                developmentPartnerDetails?.sectors && developmentPartnerDetails?.sectors.length > 0
                  ? developmentPartnerDetails?.sectors.map(sector => sector.name).join(', ')
                  : <LineSpan />
              }</p>
            </div>
            <div className="col-md-6">
              <h3 className="fs-8">Priority Regions of Operation</h3>
              <p className="fs-6">
                {developmentPartnerDetails?.regions && developmentPartnerDetails?.regions.length > 0
                  ? developmentPartnerDetails?.regions.join(', ')
                  : <LineSpan />}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h3 className="fs-8">Priority Districts of Operation</h3>
              {developmentPartnerDetails?.districts && developmentPartnerDetails?.districts.length > 0
                ? developmentPartnerDetails?.districts.join(', ')
                : <LineSpan />}
            </div>
            <div className="col-md-6">
              <h3 className="fs-8">Key Impact Metrics</h3>
              <p className="fs-6">{
                developmentPartnerDetails?.goals && developmentPartnerDetails?.goals.length > 0
                  ? developmentPartnerDetails?.goals.map(goal => goal.name).join(', ')
                  : <LineSpan />
              }</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h3 className="fs-8">Areas of Support</h3>
              <p className="fs-6">{areaOfSupportString || <LineSpan />}</p>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <EditDevelopmentPartnerStrategicFocus
        showModal={showModal}
        handleClose={handleClose}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  )
}

export default DevelopmentPartnerStrategicFocus
