import React from 'react'
import {Formik, Form, Field} from 'formik'
import {Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_theme/helpers'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {
  createDevelopmentPartnersSelectors,
  useGetDevelopmentPartnersQuery,
  useUpdateDevelopmentPartnerMutation,
} from '../DevelopmentPartnersApiSlice'
import {MultiSelect} from '../../shared/components/MultiSelect'
import {useGetSectorsQuery} from '../../companies/CompaniesApiSlice'
import {INameIdPair} from '../../shared/interfaces'

type Props = {
  showModal: boolean
  handleClose: () => void
  pageSize: number
  currentPage: number
}

export const EditDevelopmentPartnerStrategicFocus: React.FC<Props> = ({
                                                                        showModal,
                                                                        handleClose,
                                                                        pageSize,
                                                                        currentPage,
                                                                      }) => {
  const ContactInformationValidationSchema = Yup.object().shape({
    sectors: Yup.string().nullable(),
    regions: Yup.string().nullable(),
    districts: Yup.string().nullable(),
    goals: Yup.string().nullable(),
    areaOfSupport: Yup.string().nullable(),
  })

  const [updateDevelopmentPartner, {isLoading: isUpdateFinancierLoading}] = useUpdateDevelopmentPartnerMutation()
  const {id} = useParams<{id: string}>() as {id: string}
  useGetDevelopmentPartnersQuery({page: currentPage, pageSize: pageSize})
  const {selectById: selectFinancierById} = createDevelopmentPartnersSelectors({
    page: currentPage,
    pageSize,
  })
  const developmentPartnerDetails = useSelector((state: RootState) => selectFinancierById(state, id))

  const {data: sectorsState} = useGetSectorsQuery()

  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type),
  )

  const handleSave = async (values: any) => {
    const updatedDevelopmentPartnerDetails = {
      ...developmentPartnerDetails,
      sectors: values.sectors,
      regions: values.regions,
      districts: values.districts,
      goals: values.goals,
      areaOfSupport: values.areaOfSupport,
      id: developmentPartnerDetails?.id,
    }


    try {
      await Promise.all([
        updateDevelopmentPartner(updatedDevelopmentPartnerDetails).unwrap(),
      ])
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal
      id="kt_edit_strategic_focus"
      aria-hidden="true"
      dialogClassName="h-auto"
      show={showModal}
      onHide={handleClose}
    >
      <div className="modal-content w-700px" role="document">
        <Formik
          initialValues={{
            sectors: developmentPartnerDetails?.sectors,
            regions: developmentPartnerDetails?.regions?.join(', ') || '',
            districts: developmentPartnerDetails?.districts?.join(', ') || '',
            goals: developmentPartnerDetails?.goals?.map(goal => goal.name).join(', ') || '',
            areaOfSupport: developmentPartnerDetails?.areaOfSupport || '',
          }}
          validationSchema={ContactInformationValidationSchema}
          onSubmit={handleSave}
        >
          {({values, errors, touched, isValid, setFieldValue}) => (
            <Form>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title ps-1" id="editStrategicFocusModalLabel">
                    Edit Strategic Focus
                  </h5>
                  <div
                    className="btn btn-sm btn-icon btn-active-color-danger"
                    onClick={handleClose}
                  >
                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                  </div>
                </div>
                <div className="modal-body px-9">
                  <div className="row fv mb-4">
                    <div className="col-md-6">
                      <label htmlFor="sectors" className="form-label text-dark">
                        Sectors of Interest
                      </label>
                      <MultiSelect
                        selectedValues={values.sectors?.map(sector => ({id: sector.sectorId, name: sector.name})) || []}
                        options={companySectorsList}
                        setOption={(selectedOptions) => setFieldValue('sectors', selectedOptions)}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="regions" className="form-label text-dark">
                        Priority Regions of Operation
                      </label>
                      <Field
                        type="text"
                        name="regions"
                        className="form-control form-control-sm"
                        style={{color: '#616162', fontWeight: 300}}
                      />
                    </div>
                  </div>

                  <div className="row fv mb-4">
                    <div className="col-md-6">
                      <label htmlFor="districts" className="form-label text-dark">
                        Priority Districts of Operation
                      </label>
                      <Field
                        type="text"
                        name="districts"
                        className="form-control form-control-sm"
                        style={{color: '#616162', fontWeight: 300}}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="goals" className="form-label text-dark">
                        Key Impact Metrics
                      </label>
                      <Field
                        type="text"
                        name="goals"
                        className="form-control form-control-sm"
                        style={{color: '#616162', fontWeight: 300}}
                      />
                    </div>
                  </div>

                  <div className="row fv mb-4">
                    <div className="col-md-6">
                      <label htmlFor="areaOfSupport" className="form-label text-dark">
                        Areas of Support
                      </label>
                      <Field
                        type="text"
                        name="areaOfSupport"
                        className="form-control form-control-sm"
                        style={{color: '#616162', fontWeight: 300}}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer ">
                  <button
                    type="button"
                    className="btn btn-secondary text-dark"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger"
                    disabled={!isValid || isUpdateFinancierLoading}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
