import {Field, FormikValues} from 'formik'

export const CreateFinancierOthers = ({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        setFieldValue,
                                        setFieldTouched,
                                      }: FormikValues) => {
  return (
    <div data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span>{`Preferred mode of collaboration`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="others.modeCollaboration"
              placeholder=""
            />
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span>{`Specific impact goals`}</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="others.impactGoals"
              placeholder=""
            />
          </div>
        </div>
        <div className="fv-row row mb-4 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span>{`Are you open to investment?`}</span>
            </label>
            <div className="d-flex align-items-center">
              <label className="me-4">
                <Field
                  type="radio"
                  name="others.coInvestments"
                  value="yes"
                  className="form-check-input me-2"
                />
                Yes
              </label>
              <label>
                <Field
                  type="radio"
                  name="others.coInvestments"
                  value="no"
                  className="form-check-input me-2"
                />
                No
              </label>
            </div>
            {touched?.others?.coInvestments && (
              <div className="text-danger mt-2">{errors?.others?.coInvestments}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 fw-bold mb-2 f-s">
              <span>{`Are you aligned with ESG principles?`}</span>
            </label>
            <div className="d-flex align-items-center">
              <label className="me-4">
                <Field
                  type="radio"
                  name="others.esgPrinciples"
                  value="yes"
                  className="form-check-input me-2"
                />
                Yes
              </label>
              <label>
                <Field
                  type="radio"
                  name="others.esgPrinciples"
                  value="no"
                  className="form-check-input me-2"
                />
                No
              </label>
            </div>
            {touched?.others?.esgPrinciples && (
              <div className="text-danger mt-2">{errors?.others?.esgAligned}</div>
            )}
          </div>
          <div className="col-lg-6 pt-4">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Do you need additional support?</span>
            </label>
            <Field
              as="select"
              name="others.additionalSupport"
              className="form-control form-control-lg py-lg-5"
            >
              <option value="" disabled>
                Select a option
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Field>
            {touched?.financials?.additionalSupport && errors?.financials?.additionalSupport && (
              <div className="text-danger mt-2">{errors.financials.additionalSupport}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}